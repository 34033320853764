export default [{
        title: 'Dashboard',
        icon: { icon: 'tabler-home' },
        to: 'dashboard',
    },
    {
        title: 'Visits',
        icon: { icon: 'tabler-building-hospital' },
        to: '',
    },
    {
        title: 'Appointments',
        icon: { icon: 'tabler-calendar-clock' },
        to: 'appointments',
    },
    {
        title: 'Calendar',
        icon: { icon: 'tabler-calendar' },
        to: 'calendar',
    },
    {
        title: 'Bills',
        icon: {
            icon: 'tabler-receipt-2'
        },
        to: 'bills',
    },
    {
        title: 'Settings',
        icon: { icon: 'tabler-settings' },
        to: { name: 'settings-tab', params: { tab: 'general' } },
    },
]