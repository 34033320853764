<script setup>
import { emailValidator } from '@/@core/utils/validators';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import { useDoctor } from '@/composables/Tenant/useDoctor';
import { requiredValidator } from '@core/utils/validators.js';
import { ref } from "vue";

definePage({
  meta: {
    permission: 'write_doctor',
  },
});

const props = defineProps({
  isDrawerOpen: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits([
  'update:isDrawerOpen',
  'submit',
])

const refVForm = ref();
const { form } = useDoctor();

const onSubmit = async () => {
  const { valid: isValid } = await refVForm.value.validate();
  if (isValid) {
    emit('update:isDrawerOpen', false);
    emit('submit', form);
    refVForm.value.reset();
  }
}

const handleDrawerModelValueUpdate = val => {
  emit('update:isDrawerOpen', val)
}

</script>

<template>
  <VNavigationDrawer temporary location="end" :width="400" :model-value="props.isDrawerOpen" class="scrollable-content"
    @update:model-value="handleDrawerModelValueUpdate">
    <!-- 👉 Header -->
    <AppDrawerHeaderSection :title="$t('Add Doctor')" @cancel="$emit('update:isDrawerOpen', false)" />

    <PerfectScrollbar :options="{ wheelPropagation: false }">
      <VCard flat>
        <VCardText>
          <VForm ref="refVForm" @submit.prevent="onSubmit">
            <VRow>
              <VCol cols="12">
                <AppTextField v-model="form.first_name" :label="$t('First Name')" :rules="[requiredValidator]" />
              </VCol>

              <VCol cols="12">
                <AppTextField v-model="form.last_name" :label="$t('Last Name')" :rules="[requiredValidator]" />
              </VCol>

              <VCol cols="12">
                <AppTextField v-model="form.email" rows="10" :label="$t('Email')" :rules="[emailValidator]" />
              </VCol>

              <VCol cols="12">
                <AppTextField v-model="form.phone_number" :label="$t('Phone Number')" />
              </VCol>

              <VCol cols="12">
                <AppTextField v-model="form.designation" :label="$t('Designation')" :rules="[requiredValidator]" />
              </VCol>

              <VCol cols="12" class="text-center">
                <VBtn type="submit" class="me-3">
                  {{$t('Add')}}
                </VBtn>

                <VBtn color="secondary" variant="tonal" @click="$emit('update:isDrawerOpen', false)">
                  {{$t('Cancel')}}
                </VBtn>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </PerfectScrollbar>
  </VNavigationDrawer>
</template>
