<template>
    <Suspense>
        <template #default>
            <component :is="dynamicComponent" />
        </template>

        <template #fallback>
            <div class="loading-container">
                <v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
            </div>
        </template>
    </Suspense>
</template>

<script setup>
import { ref, defineAsyncComponent, onErrorCaptured } from 'vue';
import { useRouter } from 'vue-router';
import { $versionCode } from '@/utils/version.js';

definePage({
    meta: {
        isRoute: true,
        navActiveLink: "appointments",
        userTypes: ['doctor', 'user'],
        permission: 'write_appointment',
        feature: 'scheduling_planning',
    },
});

const router = useRouter();
const error = ref(null);

onErrorCaptured((e) => {
    error.value = e;
    return true;
});

const dynamicComponent = defineAsyncComponent(() => {
    if (error.value) {
        console.error('Error loading appointment form:', error.value);
        router.push({ name: 'error', params: { error: error.value.message || 'Unknown error' } });
        return Promise.reject(error.value);
    }

    return import(`@/pages/appointments/components/Forms/${$versionCode()}.vue`)
        .catch((e) => {
            console.error(`Failed to load component for version ${$versionCode()}:`, e);
            const versionError = new Error(`Unsupported version: ${$versionCode()}`);
            router.push({ name: 'error', params: { error: versionError.message } });
            return Promise.reject(versionError);
        });
});
</script>