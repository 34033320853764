import { setupLayouts } from 'virtual:generated-layouts'
// eslint-disable-next-line import/no-unresolved
import { createRouter, createWebHistory } from 'vue-router/auto'
import { setupGuards } from './guards'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

function recursiveLayouts(route) {
    if (route.children) {
        for (let i = 0; i < route.children.length; i++)
            route.children[i] = recursiveLayouts(route.children[i])

        return route
    }

    return setupLayouts([route])[0]
}

const router = createRouter({
    history: createWebHistory(
        import.meta.env.VITE_BASE_URL || '/'),
    scrollBehavior(to) {
        if (to.hash)
            return { el: to.hash, behavior: 'smooth', top: 60 }

        return { top: 0 }
    },
    extendRoutes: pages => [
        ...[
            ...pages,
        ].map(route => recursiveLayouts(route)),
    ],
})

router.beforeEach((to, from, next) => {
    NProgress.start()
    next()
})

router.afterEach(() => {
    NProgress.done()
})

setupGuards(router)
export { router }
export default function(app) {
    app.use(router)
}