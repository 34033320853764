<script setup>
import { useService } from "@/composables/Tenant/useService";
import { ref, watchEffect } from 'vue';
import { requiredValidator } from '@core/utils/validators.js';
import { useRoute } from "vue-router";

const props = defineProps({
    id: {
        type: Number,
        required: false,
    },
});

definePage({
    meta: {
        navActiveLink: 'services',
        permission: 'write_service',
    },
});

const {
    form,
    serviceData,
    fetchService,
    saveService,
    isLoading,
    errors,
} = useService();

const route = useRoute();
const serviceId = ref(props.id);

const setService = async () => {
    if (serviceId.value) {
        await fetchService(serviceId.value).then(() => {
            for (const key in serviceData.value) {
                if (serviceData.value.hasOwnProperty(key) && form.hasOwnProperty(key)) {
                    form[key] = serviceData.value[key];
                }
            }
            form.isLoading = false;
        });
    } else {
        form.isLoading = false;
    }
};

watchEffect(setService);

const onSubmit = async () => {
    if (!refVForm.value) return;
    const { valid: isValid } = await refVForm.value.validate();
    if (isValid) {
        await saveService();
    }
};

const refVForm = ref();

const sanitizeBasePrice = (event) => {
    let value = event.target.value.replace(/[^\d.]/g, '');
    const parts = value.split('.');
    if (parts.length > 2) {
        parts.pop();
    }
    if (parts[0].length > 8) {
        parts[0] = parts[0].slice(0, 8);
    }
    if (parts[1] && parts[1].length > 2) {
        parts[1] = parts[1].slice(0, 2);
    }
    form.base_price = parts.join('.');
};
</script>

<template>
    <AppCardActions :title="props.id ? $t('Edit Service') : $t('Create Service')" no-actions
        v-model:loading="form.isLoading">
        <VCardText>
            <VForm ref="refVForm">
                <VRow>
                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis" for="name">{{ $t('Name')
                                    }}</label>
                            </VCol>
                            <VCol cols="12" md="9">
                                <AppTextField id="name" v-model="form.name" persistent-placeholder
                                    :rules="[requiredValidator]" :error-messages="errors.name" />
                            </VCol>
                        </VRow>
                    </VCol>

                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis" for="base_price">
                                    {{ $t('Base Price') }}</label>
                            </VCol>
                            <VCol cols="12" md="9">
                                <AppTextField id="base_price" v-model="form.base_price" persistent-placeholder
                                    :rules="[requiredValidator]" :error-messages="errors.base_price"
                                    @input="sanitizeBasePrice" type="text" inputmode="decimal" />
                            </VCol>
                        </VRow>
                    </VCol>

                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis" for="description">
                                    {{ $t('Description') }}</label>
                            </VCol>
                            <VCol cols="12" md="9">
                                <AppTextarea id="description" v-model="form.description"
                                    :error-messages="errors.description" auto-grow />
                            </VCol>
                        </VRow>
                    </VCol>

                    <VCol offset-md="1" cols="12" class="d-flex gap-4 justify-center">
                        <VBtn :loading="isLoading" @click="onSubmit">
                            {{ $t('Save') }}
                        </VBtn>
                        <VBtn color="secondary" type="reset" variant="tonal">
                            {{ $t('Reset') }}
                        </VBtn>
                    </VCol>
                </VRow>
            </VForm>
        </VCardText>
    </AppCardActions>
</template>