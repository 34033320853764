import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import '@styles/nprogress-custom.css';

NProgress.configure({
    minimum: 0.2,
    easing: 'linear',
    speed: 300,
    trickle: true,
    trickleSpeed: 100,
    showSpinner: false,
});