<script setup>
import { useDiagnostic } from "@/composables/Tenant/useDiagnostic";
import { requiredValidator } from '@core/utils/validators.js';
import { ref, watchEffect } from "vue";
import { useRoute } from "vue-router";

const props = defineProps({
    id: {
        type: Number,
        required: false,
    },
});

definePage({
    meta: {
        navActiveLink: 'diagnostics',
        permission: 'write_diagnostic',
    },
});

const {
    form,
    diagnosticData,
    fetchDiagnostic,
    saveDiagnostic,
    isLoading,
    errors,
} = useDiagnostic();

const route = useRoute();
const diagnosticId = ref(props.id);

const setDiagnostic = async () => {
    if (diagnosticId.value) {
        await fetchDiagnostic(diagnosticId.value).then(() => {
            for (const key in diagnosticData.value) {
                if (diagnosticData.value.hasOwnProperty(key) && form.hasOwnProperty(key)) {
                    form[key] = diagnosticData.value[key];
                }
            }
        });
        form.isLoading = false;
    } else {
        form.isLoading = false;
    }
};

watchEffect(setDiagnostic);

const onSubmit = async () => {
    if (!refVForm.value) return;
    const { valid: isValid } = await refVForm.value.validate();
    if (isValid) {
        await saveDiagnostic();
    }
};

const refVForm = ref();

</script>

<template>
    <AppCardActions :title="props.id ? $t('Edit Diagnostic') : $t('Create Diagnostic')" no-actions
        v-model:loading="form.isLoading">
        <VCardText>
            <VForm ref="refVForm">
                <VRow>

                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis" for="name">{{$t('Name')}}</label>
                            </VCol>

                            <VCol cols="12" md="9">
                                <AppTextField id="name" v-model="form.name" persistent-placeholder
                                    :rules="[requiredValidator]" :error-messages="errors.name" />
                            </VCol>
                        </VRow>
                    </VCol>


                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis"
                                    for="history">{{$t('History')}}</label>
                            </VCol>

                            <VCol cols="12" md="9">
                                <AppTextarea auto-grow id="history" v-model="form.history" persistent-placeholder
                                    :error-messages="errors.history" />
                            </VCol>
                        </VRow>
                    </VCol>


                    <VCol offset-md="1" cols="12" class="d-flex gap-4 justify-center">
                        <VBtn :loading="isLoading" @click="onSubmit">
                            {{$t('Save')}}
                        </VBtn>
                        <VBtn color="secondary" type="reset" variant="tonal">
                            {{$t('Reset')}}
                        </VBtn>
                    </VCol>
                </VRow>
            </VForm>
        </VCardText>
    </AppCardActions>
</template>