<script setup>
import Form from "@/pages/admin/databases/components/DatabaseForm.vue";

definePage({
    meta: {
        isRoute: true,
        adminOnly: true,
        userTypes: ['admin'],
        navActiveLink: "admin-databases",
    },
});
</script>

<template>
    <Form />
</template>