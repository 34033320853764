<script setup>
import { useDate } from '@/composables/useDate';
import { ref, onMounted, onUnmounted, nextTick, computed } from 'vue';

definePage({
    meta: {
        isRoute: true,
        adminOnly: true,
        userTypes: ['admin'],
        navActiveLink: "admin-terminal",
    },
});

const { currentDate } = useDate();
const terminalOutput = ref([currentDate()]);
const currentInput = ref('');
const cursorPosition = ref(0);
const terminalEl = ref(null);
const isFullscreen = ref(false);
const isFocused = ref(false);

const handleKeyPress = (e) => {
    if (!isFocused.value) return;

    if (e.key === 'Enter') {
        processInput();
    } else if (e.key === 'Backspace') {
        if (cursorPosition.value > 0) {
            currentInput.value = currentInput.value.slice(0, cursorPosition.value - 1) + currentInput.value.slice(cursorPosition.value);
            cursorPosition.value--;
        }
    } else if (e.key === 'ArrowLeft') {
        if (cursorPosition.value > 0) cursorPosition.value--;
    } else if (e.key === 'ArrowRight') {
        if (cursorPosition.value < currentInput.value.length) cursorPosition.value++;
    } else if (e.key.length === 1) {
        currentInput.value = currentInput.value.slice(0, cursorPosition.value) + e.key + currentInput.value.slice(cursorPosition.value);
        cursorPosition.value++;
    }
    e.preventDefault();
};

const processInput = () => {
    if (currentInput.value.trim()) {
        terminalOutput.value.push(`$ ${currentInput.value}`);
        processCommand(currentInput.value.trim());
        currentInput.value = '';
        cursorPosition.value = 0;
        nextTick(() => {
            if (terminalEl.value) {
                terminalEl.value.scrollTop = terminalEl.value.scrollHeight;
            }
        });
    }
};

const processCommand = (command) => {
    if (command.toLowerCase() === 'help') {
        terminalOutput.value.push('Available commands: help, clear, echo <message>');
    } else if (command.toLowerCase() === 'clear') {
        terminalOutput.value = [];
    } else if (command.toLowerCase().startsWith('echo ')) {
        terminalOutput.value.push(command.slice(5));
    } else {
        terminalOutput.value.push(`Command not found: ${command}`);
    }
};

const toggleFullscreen = () => {
    isFullscreen.value = !isFullscreen.value;
    if (isFullscreen.value) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
};

const inputChars = computed(() => {
    return currentInput.value.split('').map(char => char === ' ' ? '\u00A0' : char);
});

const focusTerminal = () => {
    isFocused.value = true;
};

const blurTerminal = () => {
    isFocused.value = false;
};

onMounted(() => {
    window.addEventListener('keydown', handleKeyPress);
});

onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyPress);
});
</script>

<template>
    <div :class="['container', { 'fullscreen': isFullscreen }]">
        <div class="terminal_toolbar">
            <div class="buttons">
                <button class="btn close-btn"></button>
                <button class="btn resize-btn"></button>
                <button class="btn fs-btn" @click="toggleFullscreen"></button>
            </div>
            <p class="user">admin@MedSyn: ~</p>
        </div>
        <div class="terminal_body" ref="terminalEl" tabindex="0" @click="focusTerminal" @blur="blurTerminal">
            <div v-for="(line, index) in terminalOutput" :key="index" class="terminal_line">
                <template v-if="line.startsWith('$')">
                    <span class="terminal_user">admin@MedSyn:</span>
                    <span class="terminal_location">~</span>
                    <span class="terminal_bling">$</span>
                    <span class="terminal_command">{{ line.slice(2) }}</span>
                </template>
                <template v-else>
                    <span class="output_text">{{ line }}</span>
                </template>
            </div>
            <div class="terminal_line">
                <span class="terminal_user">admin@MedSyn:</span>
                <span class="terminal_location">~</span>
                <span class="terminal_bling">$</span>
                <span class="terminal_input">
                    <span v-if="isFocused && cursorPosition === 0" class="terminal_cursor"></span>
                    <span v-for="(char, index) in inputChars" :key="index" class="char-container">
                        <span class="char">{{ char }}</span>
                        <span v-if="isFocused && index === cursorPosition - 1" class="terminal_cursor"></span>
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>

<style scoped>
.container {
    width: 100%;
    height: 80vh;
    background: rgba(var(--v-theme-background), 0.7);
    font-family: Menlo, Consolas, monospace;
    font-size: 14px;
    color: #e6e6e6;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.container.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    border-radius: 0;
}

.terminal_toolbar {
    display: flex;
    height: 30px;
    align-items: center;
    padding: 0 8px;
    box-sizing: border-box;
    background: #212121;
    justify-content: space-between;
}

.buttons {
    display: flex;
    align-items: center;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-right: 5px;
    font-size: 8px;
    height: 12px;
    width: 12px;
    box-sizing: border-box;
    border: none;
    border-radius: 100%;
    text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 1px 0px #41403a, 0px 1px 1px 0px #474642;
}

.close-btn {
    background: #ff5f56;
}

.resize-btn {
    background: #ffbd2e;
}

.fs-btn {
    background: #27c93f;
}

.btn:hover {
    cursor: pointer;
}

.btn:focus {
    outline: none;
}

.user {
    color: #d5d0ce;
    font-size: 14px;
    line-height: 30px;
    margin: 0;
    flex-grow: 1;
    text-align: center;
}

.terminal_body {
    background: rgba(0, 0, 0, 0.9);
    flex-grow: 1;
    padding: 10px;
    font-size: 14px;
    line-height: 1.5em;
    overflow-y: auto;
    outline: none;
    cursor: text;
}

.terminal_line {
    display: flex;
    align-items: center;
}

.terminal_user {
    color: #1eff8e;
    margin-right: 4px;
}

.terminal_location {
    color: #4878c0;
    margin-right: 4px;
}

.terminal_bling {
    color: #dddddd;
    margin-right: 8px;
}

.terminal_input,
.terminal_command,
.output_text {
    color: #ffffff;
    word-break: break-all;
    letter-spacing: 0;
}

.terminal_input {
    position: relative;
    display: inline-flex;
    align-items: center;
    min-height: 1.5em;
}

.char-container {
    position: relative;
    display: inline-block;
}

.char {
    display: inline-block;
    white-space: pre;
}

.terminal_cursor {
    display: inline-block;
    width: 2px;
    height: 1.2em;
    background-color: #ffffff;
    animation: blink 1s step-end infinite;
    vertical-align: middle;
    margin: 0 -1px;
}

@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}
</style>