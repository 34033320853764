<script setup>
import Form from "@/pages/admin/versions/components/Form.vue";

definePage({
    meta: {
        isRoute: true,
        adminOnly: true,
        userTypes: ['admin'],
        navActiveLink: "admin-versions",
    },
});
</script>

<template>
    <Form />
</template>