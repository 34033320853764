<script setup>
import authV1BottomShape from '@images/svg/auth-v1-bottom-shape.svg?raw'
import authV1TopShape from '@images/svg/auth-v1-top-shape.svg?raw'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'
import { useReset } from "@/composables/Auth/useReset";
import { requiredValidator } from '@core/utils/validators.js';

definePage({
    meta: {
        isRoute: true,
        layout: 'blank',
        unauthenticatedOnly: true,
    },
})

const isNewPasswordVisible = ref(false);
const isNewConfirmationPasswordVisible = ref(false);


const { resetForm, loading, verifyToken, processing, changePassword, snackBar,success, errors } = useReset();

const refVForm = ref()

const onSubmit = () => {
    refVForm.value?.validate().then(({ valid: isValid }) => {
        if (isValid) {
            changePassword();
        }
    });
};

const route = useRoute();

onMounted(() =>{
    verifyToken(route.params)
})
</script>

<template>
    <div class="login-page-wrapper">
        <VAppBar>
            <template v-slot:prepend>
                <router-link :to="'/'" class="d-flex align-center">
                    <VNodeRenderer :nodes="themeConfig.app.logo" />
                    <VToolbarTitle class="text-h4 ml-2">
                        <span class="text-capitalize font-weight-bold">{{ themeConfig.app.title }}</span>
                    </VToolbarTitle>
                </router-link>
            </template>
        </VAppBar>

        <div class="auth-wrapper d-flex align-center justify-center">
            <VCard width="450" max-width="100%" class="login-card">
                <VCardTitle class="text-center py-4">
                    {{ $t('Reset Password') }}
                </VCardTitle>
                <VDivider />
                <VCardText>
                    <VForm ref="refVForm" @submit.prevent="onSubmit">
                        <VRow>

                            <VCol cols="12">
                                <AppTextField autocomplete="true" v-model="resetForm.password" :label="$t('Password')"
                                    placeholder="············"
                                    :rules="[requiredValidator, minLengthValidator(resetForm.password, 8)]"
                                    :type="isNewPasswordVisible ? 'text' : 'password'" :error-messages="errors.password"
                                    :append-inner-icon="isNewPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                                    @click:append-inner="isNewPasswordVisible = !isNewPasswordVisible" />
                            </VCol>

                            <VCol cols="12">
                                <AppTextField autocomplete="true" v-model="resetForm.password_confirmation"
                                    :label="$t('Password Confirmation')" placeholder="············" :rules="[requiredValidator,
                                        confirmedValidator(
                                            resetForm.password_confirmation,
                                            resetForm.password
                                        ),
                                    ]" :type="isNewConfirmationPasswordVisible ? 'text' : 'password'"
                                    :error-messages="errors.password_confirmation"
                                    :append-inner-icon="isNewConfirmationPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                                    @click:append-inner="isNewConfirmationPasswordVisible = !isNewConfirmationPasswordVisible" />
                            </VCol>

                            <VCol cols="12">
                                <VBtn v-show="!success" :loading="loading" block type="submit">
                                    {{ $t('Reset') }}
                                    <template #loader>
                                        <span class="button-loader">
                                            <VIcon size="30" icon="tabler-loader-2" />
                                        </span>
                                    </template>
                                </VBtn>
                                <VBtn v-show="success" :loading="loading" block type="submit" color="success"
                                    class="px-10">
                                    <VIcon icon="tabler-lock" size="25" />
                                </VBtn>
                            </VCol>
                        </VRow>
                    </VForm>
                </VCardText>
            </VCard>
        </div>
    </div>
    <VSnackbar v-model="snackBar.visible" :color="snackBar.color">
        <div class="text-center">{{ $t(snackBar.message) }}.</div>
    </VSnackbar>
</template>

<style lang="scss">
@use "@core-scss/template/pages/page-auth.scss";
</style>
