<script setup>
import { useVerify } from "@/composables/Auth/useVerify";

definePage({
    meta: {
        isRoute: true,
        layout: 'blank',
        unauthenticatedOnly: true,
    },
})

const { verifyEmail, verifyProcessing, snackBar } = useVerify();

const route = useRoute();

onMounted(() => {
    verifyEmail(route.params);
});
</script>

<template>
    <div v-if="!verifyProcessing" class="d-flex align-center justify-center text-center" style="height: 100vh;">
        <div v-if="snackBar.color === 'success'">
            <VIcon icon="tabler-mail-check" size="48" color="success" class="mb-2" />
            <h2 class="text-h5 font-weight-medium mb-2">{{ $t('Email Verified') }}</h2>
        </div>
        <div v-else-if="snackBar.color === 'error'">
            <VIcon icon="tabler-mail-off" size="48" color="error" class="mb-2" />
            <h2 class="text-h5 font-weight-medium mb-2">{{ $t('Verification Failed') }}</h2>
        </div>
    </div>

    <VDialog v-model="verifyProcessing" width="300">
        <VCard color="primary" width="300">
            <VCardText class="pt-3 text-center">
                {{ $t('Verification') }}
                <VProgressLinear indeterminate color="white" :height="8" class="mb-0 mt-4" />
            </VCardText>
        </VCard>
    </VDialog>
    <VSnackbar v-model="snackBar.visible" :color="snackBar.color">
        <div class="text-center">{{ $t(snackBar.message) }}.</div>
    </VSnackbar>
</template>

<style lang="scss">
@use "@core-scss/template/pages/page-auth.scss";
</style>