<script setup>
import Form from "@/pages/techniques/components/Form.vue";
import { useRoute } from "vue-router";

const route = useRoute();

definePage({
    meta: {
        isRoute: true,
        navActiveLink: "techniques",
        userTypes: ['doctor', 'user'],
        permission: 'write_technique',
        navActiveLink: "techniques",
    },
});
</script>

<template>
    <Form :id="Number(route.params.id)" />
</template>