<script setup>
import { ref, shallowRef, onMounted, defineAsyncComponent, computed } from 'vue';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { useRouter } from 'vue-router';
import { VProgressCircular } from 'vuetify/components';

const UserDoctorDashboard = defineAsyncComponent(() => import('./components/Dashboards/UserDoctorDashboard.vue'));
const PatientDashboard = defineAsyncComponent(() => import('./components/Dashboards/PatientDashboard.vue'));
const AdminDashboard = defineAsyncComponent(() => import('./components/Dashboards/AdminDashboard.vue'));

definePage({
    meta: {
        isRoute: true,
        navActiveLink: "dashboard",
    },
});

const router = useRouter();
const permissionsStore = usePermissionsStore();
const Dashboard = shallowRef(null);
const isLoading = ref(true);

const dashboardComponents = {
    'admin': AdminDashboard,
    'user': UserDoctorDashboard,
    'doctor': UserDoctorDashboard,
    'patient': PatientDashboard,
};

const selectedDashboard = computed(() => {
    const Component = dashboardComponents[permissionsStore.type];
    if (!Component) {
        throw new Error('Invalid user type');
    }
    return Component;
});

onMounted(() => {
    try {
        Dashboard.value = selectedDashboard.value;
    } catch (e) {
        if (e.message === 'Invalid user type') {
            router.push({ name: 'not-authorized' });
        } else {
            router.push({ name: 'error' });
        }
    } finally {
        isLoading.value = false;
    }
});

Object.values(dashboardComponents).forEach(component => {
    component.__asyncLoader();
});
</script>

<template>
    <div>
        <div v-if="isLoading" class="loading-container">
            <VProgressCircular indeterminate color="primary" :size="50" />
        </div>
        <Suspense v-else>
            <template #default>
                <component :is="Dashboard" />
            </template>
            <template #fallback>
                <div class="loading-container">
                    <VProgressCircular indeterminate color="primary" :size="50" />
                </div>
            </template>
        </Suspense>
    </div>
</template>