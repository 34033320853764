<script setup>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import { ref, watch } from 'vue';
import { requiredValidator } from '@core/utils/validators.js';

definePage({
  meta: {
    permission: 'write_technique',
  },
});

const props = defineProps({
  isDrawerOpen: {
    type: Boolean,
    required: true,
  },
  isLoading:{
    type: Boolean,
    required: false,
  },
  errors: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits([
  'update:isDrawerOpen',
  'submit',
]);

const refVForm = ref();
const name = ref('');
const operative_report = ref('');

const onSubmit = async () => {
  const { valid: isValid } = await refVForm.value.validate();
  if (isValid) {
    emit('submit', {
      name: name.value,
      operative_report: operative_report.value,
    });
  }
};

const handleDrawerModelValueUpdate = val => {
  emit('update:isDrawerOpen', val);
};

watch(() => props.isDrawerOpen, (newVal) => {
  if (!newVal && refVForm.value) {
    refVForm.value.reset();
  }
});

</script>



<template>
  <VNavigationDrawer temporary location="end" :width="400" :model-value="props.isDrawerOpen" class="scrollable-content"
    @update:model-value="handleDrawerModelValueUpdate">
    <AppDrawerHeaderSection :title="$t('Add Technique')" @cancel="$emit('update:isDrawerOpen', false)" />
    <PerfectScrollbar :options="{ wheelPropagation: false }">
      <VCard flat>
        <VCardText>
          <VForm ref="refVForm" @submit.prevent="onSubmit">
            <VRow>
              <VCol cols="12">
                <AppTextField v-model="name" :label="$t('Name')" :rules="[requiredValidator]"
                  :error-messages="errors.name" />
              </VCol>

              <VCol cols="12">
                <AppTextarea v-model="operative_report" rows="10" :label="$t('Operative Report')" />
              </VCol>

              <VCol cols="12" class="text-center">
                <VBtn :loading="isLoading" type="submit" class="me-3">
                  {{$t('Add')}}
                </VBtn>

                <VBtn color="secondary" variant="tonal" @click="$emit('update:isDrawerOpen', false)">
                  {{$t('Cancel')}}
                </VBtn>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </PerfectScrollbar>
  </VNavigationDrawer>
</template>
