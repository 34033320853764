<script setup>
import Form from "@/pages/medications/components/Form.vue";

definePage({
    meta: {
        isRoute: true,
        navActiveLink: "medications",
        userTypes: ['doctor', 'user'],
        permission: 'write_medication',
    },
});
</script>

<template>
    <Form />
</template>