import { defineStore } from "pinia";
import axios from "axios";
import user from "@/navigation/vertical/user";
import admin from "@/navigation/vertical/admin";
import patient from "@/navigation/vertical/patient";
import { usePermissionsStore } from "./usePermissionsStore";
import { useAccessControl } from "@/composables/useAccessControl";
import { useUserStore } from "@/stores/useUserStore";
import { encryptedStorage } from "../@core/composable/encryptedStorage";
import { watch } from "vue";

export const useNavigationStore = defineStore("navigation", {
    state: () => ({
        isReady: false,
        platformTab: "tab-feed",
        items: [],
        rawItems: [],
        refreshInterval: 10 * 60 * 1000,
        lastRefreshTime: null,
    }),

    getters: {
        isLoading: (state) => !(state.items.length > 0 && state.isReady),
    },

    actions: {
        async initialize() {
            try {
                const permissionsStore = usePermissionsStore();

                if (!permissionsStore.isReady) {
                    await new Promise((resolve) => {
                        const unwatch = watch(
                            () => permissionsStore.isReady,
                            (isReady) => {
                                if (isReady) {
                                    unwatch();
                                    resolve();
                                }
                            }
                        );
                    });
                }
                await this.fetch();
                this.setupPermissionsWatcher();
            } catch (error) {
                console.error("Error initializing navigation:", error);
                this.isReady = false;
                throw error;
            }
        },

        async fetch() {
            const permissionsStore = usePermissionsStore();
            if (permissionsStore.isAdmin) {
                this.rawItems = admin;
            } else if (permissionsStore.isPatient) {
                this.rawItems = patient;
            } else {
                try {
                    const res = await axios.get("/api/navigation");
                    this.updateRawItems(res.data);
                } catch (error) {
                    console.error("Error fetching navigation items:", error);
                    this.rawItems = user;
                }
            }

            this.reinitializeNavigation();
            this.isReady = true;
            this.lastRefreshTime = Date.now();
        },

        updateRawItems(navigationData) {
            const orderMap = {};
            if (navigationData.length) {
                navigationData.forEach((data) => {
                    orderMap[data.id] = data.order;
                });
            }

            this.rawItems = user.map((item) => ({
                ...item,
                order:
                    orderMap[item.id] !== undefined
                        ? orderMap[item.id]
                        : item.order,
            }));
        },

        reinitializeNavigation() {
            const sortedItems = this.sort(this.rawItems);
            this.items = this.filter(sortedItems);
        },

        filter(items) {
            const { canAccess } = useAccessControl();
            const userStore = useUserStore();

            const isOwner = canAccess.byOwnerStatus();

            const initialFiltered = items.filter((item) => {
                if (item.ownerOnly && !isOwner) {
                    return false;
                }

                if (
                    item.permission &&
                    !canAccess.byPermission(item.permission)
                ) {
                    return false;
                }

                if (item.feature && !canAccess.byFeature(item.feature)) {
                    return false;
                }

                if (item.userTypes) {
                    const allowedTypes = Array.isArray(item.userTypes)
                        ? item.userTypes
                        : [item.userTypes];
                    if (!allowedTypes.includes(userStore.type)) {
                        return false;
                    }
                }

                return true;
            });

            const finalFiltered = initialFiltered.filter(
                (item, index, array) => {
                    if (!item.heading) return true;
                    const nextItem = array[index + 1];
                    return nextItem && !nextItem.heading;
                }
            );

            return finalFiltered;
        },

        sort(items) {
            let groupedItems = [];
            let currentGroup = { id: null, heading: null, order: 0, items: [] };

            items.forEach((item) => {
                if (item.heading) {
                    if (currentGroup.heading) {
                        groupedItems.push(currentGroup);
                    }
                    currentGroup = {
                        id: item.id,
                        heading: item.heading,
                        order: item.order,
                        items: [],
                    };
                } else {
                    currentGroup.items.push(item);
                }
            });

            if (currentGroup.heading) {
                groupedItems.push(currentGroup);
            }

            groupedItems.sort((a, b) => a.order - b.order);

            groupedItems.forEach((group) => {
                group.items.sort((a, b) => a.order - b.order);
            });

            let sortedItems = [];
            groupedItems.forEach((group) => {
                sortedItems.push({
                    heading: group.heading,
                    order: group.order,
                    id: group.id,
                });
                sortedItems.push(...group.items);
            });

            return sortedItems;
        },

        async save(elements) {
            const permissionsStore = usePermissionsStore();

            if (permissionsStore.isAdmin) {
                console.warn("Admin cannot modify navigation items");
                return;
            }

            try {
                const flattenedNavigationData = elements.flatMap((group) => [
                    { id: group.id, order: group.order },
                    ...group.children.map((child) => ({
                        id: child.id,
                        order: child.order,
                    })),
                ]);

                await axios.put("/api/navigation", {
                    elements: flattenedNavigationData,
                });

                this.updateRawItems(flattenedNavigationData);
                this.reinitializeNavigation();
            } catch (error) {
                console.error("Error updating navigation items:", error);
            }
        },

        setupPermissionsWatcher() {
            const permissionsStore = usePermissionsStore();
            watch(
                () => permissionsStore.permissions,
                () => {
                    if (permissionsStore.isReady) {
                        this.reinitializeNavigation();
                    }
                },
                { deep: true }
            );
        },

        initializeSocket() {},

        reset() {
            this.$reset();
            localStorage.removeItem("navigation");
        },

        resetSocket() {},
    },

    persist: {
        storage: encryptedStorage,
    },
});