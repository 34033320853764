<script setup>
import Navbar from '@/components/front-pages/front-page-navbar.vue';
import Hero from '@/components/front-pages/landing-page/hero.vue';
import Specialties from '@/components/front-pages/landing-page/specialties.vue';
import Features from '@/components/front-pages/landing-page/features.vue';
import Technology from '@/components/front-pages/landing-page/technology.vue';
import Reviews from '@/components/front-pages/landing-page/reviews.vue';
import Pricing from '@/components/front-pages/landing-page/pricing.vue';
import Faq from '@/components/front-pages/landing-page/faq.vue';
import Contact from '@/components/front-pages/landing-page/contact.vue';
import Footer from '@/components/front-pages/front-page-footer.vue';
import { ref } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';

definePage({
    meta: {
        isRoute: true,
        public: true,
        layout: 'blank'
    }
})

const activeSectionId = ref('');
const refHome = ref();
const refSpecialties = ref();
const refFeatures = ref();
const refTechnology = ref();
const refReviews = ref();
const refPricing = ref();
const refFaq = ref();
const refContact = ref();

useIntersectionObserver([
    refHome,
    refSpecialties,
    refFeatures,
    refTechnology,
    refReviews,
    refPricing,
    refFaq,
    refContact,
], ([{ isIntersecting, target }]) => {
    if (isIntersecting) {
        activeSectionId.value = target.id;
    }
}, { threshold: 0.5 })
</script>

<template>
    <div class="landing-page-wrapper">
        <Navbar :active-id="activeSectionId" />

        <Hero ref="refHome" id="home" />
        <Specialties ref="refSpecialties"/>
        <Features ref="refFeatures" />
        <Technology ref="refTechnology"/>
        <!-- <Reviews ref="refReviews" /> -->
        <Pricing ref="refPricing" />
        <Faq ref="refFaq" />
        <Contact ref="refContact" />

        <Footer />
    </div>
</template>

<style lang="scss">
@media (max-width: 960px) and (min-width: 600px) {
    .landing-page-wrapper {
        .v-container {
            padding-inline: 2rem !important;
        }
    }
}
</style>