import { defineStore } from "pinia";
import axios from "axios";
import { encryptedStorage } from "../@core/composable/encryptedStorage";

export const useAppStore = defineStore("app", {
    state: () => ({
        name: "",
        code: "",
        isReady: false,
    }),
    actions: {
        async initialize() {
            try {
                const response = await axios.get("/api/version");
                this.name = response.data.name;
                this.code = response.data.code;
                this.isReady = true;
            } catch (error) {
                console.error("Error fetching app:", error);
                this.isReady = false;
                throw error;
            }
        },
        reset() {
            this.$reset();
            localStorage.removeItem("app");
        },
    },
    persist: {
        storage: encryptedStorage,
    },
});
