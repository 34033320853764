<script setup>
import { emailValidator } from '@/@core/utils/validators';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import { requiredValidator } from '@core/utils/validators.js';
import { ref,watch } from 'vue';

definePage({
  meta: {
    permission: 'write_doctor',
  },
});

const props = defineProps({
  isDrawerOpen: {
    type: Boolean,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: false,
  }
})

const emit = defineEmits([
  'update:isDrawerOpen',
  'submit',
])

const refVForm = ref();
const first_name = ref('');
const last_name = ref('');
const email = ref('');
const phone_number = ref('');

const onSubmit = async () => {
  const { valid: isValid } = await refVForm.value.validate();
  if (isValid) {
    emit('submit', {
      first_name: first_name.value,
      last_name: last_name.value,
      email: email.value,
      phone_number: phone_number.value,
    });
    refVForm.value.reset();
  }
}

const handleDrawerModelValueUpdate = val => {
  emit('update:isDrawerOpen', val)
}

watch(() => props.isDrawerOpen, (newVal) => {
  if (!newVal && refVForm.value) {
    refVForm.value.reset();
  }
});
</script>

<template>
  <VNavigationDrawer temporary location="end" :width="400" :model-value="props.isDrawerOpen" class="scrollable-content"
    @update:model-value="handleDrawerModelValueUpdate">
    <AppDrawerHeaderSection :title="$t('Add Anesthesiologist')" @cancel="$emit('update:isDrawerOpen', false)" />
    <PerfectScrollbar :options="{ wheelPropagation: false }">
      <VCard flat>
        <VCardText>
          <VForm ref="refVForm" @submit.prevent="onSubmit">
            <VRow>
              <VCol cols="12">
                <AppTextField v-model="first_name" :label="$t('First Name')" :rules="[requiredValidator]" />
              </VCol>

              <VCol cols="12">
                <AppTextField v-model="last_name" :label="$t('Last Name')" :rules="[requiredValidator]" />
              </VCol>

              <VCol cols="12">
                <AppTextField v-model="email" rows="10" :label="$t('Email')" :rules="[emailValidator]" />
              </VCol>

              <VCol cols="12">
                <AppTextField v-model="phone_number" :label="$t('Phone Number')" />
              </VCol>

              <VCol cols="12" class="text-center">
                <VBtn :loading="isLoading" type="submit" class="me-3">
                  {{$t('Add')}}
                </VBtn>

                <VBtn color="secondary" variant="tonal" @click="$emit('update:isDrawerOpen', false)">
                  {{$t('Cancel')}}
                </VBtn>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </PerfectScrollbar>
  </VNavigationDrawer>
</template>
