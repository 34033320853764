<script setup>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useInquiry } from "@/composables/Central/Admin/useInquiry";
import { useI18n } from 'vue-i18n';

definePage({
    meta: {
        isRoute: true,
        adminOnly: true,
        userTypes: ['admin'],
        navActiveLink: "admin-inquiries",
    },
});

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const { fetchInquiry, updateInquiry } = useInquiry();

const inquiry = ref(null);
const loading = ref(true);
const updateStatus = ref(false);

onMounted(async () => {
    try {
        const response = await fetchInquiry(route.params.id);
        inquiry.value = response;
    } catch (error) {
        console.error('Error fetching inquiry:', error);
    } finally {
        loading.value = false;
    }
});

const toggleStatus = async () => {
    updateStatus.value = true;
    try {
        const updatedInquiry = { ...inquiry.value, status: inquiry.value.status ? 0 : 1 };
        const response = await updateInquiry(inquiry.value.id, updatedInquiry);
        inquiry.value = response;
    } catch (error) {
        console.error('Error updating inquiry status:', error);
    } finally {
        updateStatus.value = false;
    }
};

const getStatusColor = (status) => status ? 'success' : 'error';
const getStatusText = (status) => status ? t('Completed') : t('Not Completed');

const goBack = () => router.go(-1);
</script>

<template>
    <div class="d-flex justify-center align-center" v-if="loading" style="height: 100vh;">
        <VProgressCircular indeterminate size="64" />
    </div>
    <VCard v-if="!loading && inquiry" class="ma-4 pa-4">
        <VCardTitle class="d-flex justify-space-between align-center">
            <span class="text-h6">{{ t('Inquiry Details') }}</span>
            <VBtn @click="goBack" icon="mdi-arrow-left" variant="text" />
        </VCardTitle>
        <VCardText>
            <VRow>
                <VCol cols="12" sm="6" class="mb-3">
                    <VLabel>{{ t('Name') }}</VLabel>
                    <div>{{ `${inquiry.first_name} ${inquiry.last_name}` }}</div>
                </VCol>
                <VCol cols="12" sm="6" class="mb-3">
                    <VLabel>{{ t('Email') }}</VLabel>
                    <div>{{ inquiry.email }}</div>
                </VCol>
                <VCol cols="12" sm="6" class="mb-3">
                    <VLabel>{{ t('Phone Number') }}</VLabel>
                    <div>{{ inquiry.phone_number }}</div>
                </VCol>
                <VCol cols="12" sm="6" class="mb-3 d-flex align-center">
                    <VLabel>{{ t('Status') }}</VLabel>
                    <VChip :color="getStatusColor(inquiry.status)" text-color="white" size="small"
                        class="text-capitalize ml-2">
                        {{ getStatusText(inquiry.status) }}
                    </VChip>
                    <VBtn @click="toggleStatus" :loading="updateStatus" size="small" class="ml-2">
                        {{ inquiry.status ? t('Mark as Not Completed') : t('Mark as Completed') }}
                    </VBtn>
                </VCol>
                <VCol cols="12">
                    <VLabel>{{ t('Message') }}</VLabel>
                    <VCard class="pa-3 mt-2" outlined>
                        <p>{{ inquiry.message }}</p>
                    </VCard>
                </VCol>
            </VRow>
        </VCardText>
    </VCard>
    <VAlert v-else type="error" class="ma-4">{{ t('Inquiry not found') }}</VAlert>
</template>
