<script setup>
import { useGenerateImageVariant } from '@core/composable/useGenerateImageVariant'
import misc404 from '@images/pages/404.png'
import miscMaskDark from '@images/pages/misc-mask-dark.png'
import miscMaskLight from '@images/pages/misc-mask-light.png'

const authThemeMask = useGenerateImageVariant(miscMaskLight, miscMaskDark)

definePage({
  alias: '/pages/misc/not-found/:error(.*)',
  meta: {
    isRoute: true,
    layout: 'blank',
    public: true,
  },
})
</script>

<template>
  <div class="misc-wrapper">
    <ErrorHeader status-code="404" :title="$t('Page Not Found')"
      :description="$t('We couldn\'t find the page you are looking for') + '.'" />

    <VBtn to="/" class="mb-12">
      {{$t('Home')}}
    </VBtn>

    <!-- 👉 Image -->
    <!-- <div class="misc-avatar w-100 text-center">
      <VImg
        :src="misc404"
        alt="Coming Soon"
        :max-width="200"
        class="mx-auto"
      />
    </div> -->

    <VImg :src="authThemeMask" class="misc-footer-img d-none d-md-block" />
  </div>
</template>

<style lang="scss">
@use "@core-scss/template/pages/misc.scss";
</style>
