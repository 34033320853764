<script setup>
import Form from "@/pages/roles/components/Form.vue";
import { useRoute } from "vue-router";

const route = useRoute();

definePage({
    meta: {
        isRoute: true,
        navActiveLink: "roles",
        ownerOnly: true,
        userTypes: ['doctor'],
        feature: 'user_management',
    },
});
</script>

<template>
    <Form :id="Number(route.params.id)" />
</template>