import { createNotivue } from 'notivue';
import 'notivue/notification.css';
import 'notivue/animations.css';
import 'notivue/notification-progress.css';
import '@styles/notivue-custom.css';

const notivue = createNotivue({
    startOnCreation: true,
    position: 'top-right',
    limit: 4,
    pauseOnHover: false,
    pauseOnTouch: false,
    pauseOnTabChange: false,
    enqueue: true,
    avoidDuplicates: false,
    notifications: {
        global: {
            duration: 30000
        }
    },
    animations: {
        enter: 'Notivue__enter',
        leave: 'Notivue__leave',
        clearAll: 'Notivue__clearAll'
    },
})

export default function(app) {
    app.use(notivue);
}