<script setup>
import { ref, computed, onMounted, watchEffect } from 'vue';
import { usePatient } from "@/composables/Tenant/usePatient";
import { useDiagnostic } from "@/composables/Tenant/useDiagnostic";
import { useTechnique } from "@/composables/Tenant/useTechnique";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import { useAccessControl } from "@/composables/useAccessControl";
import SpeechEhr from "../SpeechEhr.vue";
import { useRouter } from "vue-router";

definePage({
    meta: {
        permission: 'read_patient',
    },
});

const vuetifyDisplays = useDisplay();
const { canAccess } = useAccessControl();
const { t } = useI18n();
const router = useRouter();

const patients = ref([]);
const totalPatients = ref(0);
const searchQuery = ref("");
const itemsPerPage = ref(10);
const page = ref(1);
const sortBy = ref('id');
const orderBy = ref('desc');
const alert = ref();
const isLimitReached = ref(false);
const isSpeechEhrDialogVisible = ref(false);

const selectedGender = ref(null);
const selectedBloodGroup = ref(null);
const selectedDateOfBirth = ref(null);
const selectedDiagnostics = ref([]);
const selectedTechniques = ref([]);
const diagnosticItems = ref([]);
const techniqueItems = ref([]);

const genders = computed(() => ([
    { name: 'M', value: t('Male') },
    { name: 'F', value: t('Female') }
]));

const bloodGroups = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"];

const filtering = ref(false);

const { fetchPatients, fetchingPatients, softDestroyPatient } = usePatient();
const { fetchDiagnostics } = useDiagnostic();
const { fetchTechniques } = useTechnique();

const updateOptions = (options) => {
    page.value = options.page;
    sortBy.value = options.sortBy[0]?.key;
    orderBy.value = options.sortBy[0]?.order;
};

const refreshPatients = async () => {
    await fetchPatients({
        query: {
            q: searchQuery.value,
            itemsPerPage: itemsPerPage.value,
            page: page.value,
            sortBy: sortBy.value,
            orderBy: orderBy.value,
            isDeleted: false,
            gender: selectedGender.value,
            dateOfBirth: selectedDateOfBirth.value,
            bloodGroup: selectedBloodGroup.value,
            diagnostics: selectedDiagnostics.value,
            techniques: selectedTechniques.value,
        },
    })
        .then((response) => {
            fetchingPatients.value = false;
            patients.value = response.data.patients;
            totalPatients.value = response.data.totalPatients;
            alert.value = response.data.alert;
            isLimitReached.value = response.data.isLimitReached;
        })
        .catch((error) => {
            console.error(error);
        });
};

const handleDiagnosticSearch = async (query) => {
    try {
        const response = await fetchDiagnostics({
            query: {
                q: query,
                include: selectedDiagnostics.value
            }
        });
        diagnosticItems.value = response.data.diagnostics;
    } catch (error) {
        console.error('Error fetching diagnostics:', error);
        diagnosticItems.value = [];
    }
};

const handleTechniqueSearch = async (query) => {
    try {
        const response = await fetchTechniques({
            query: {
                q: query,
                include: selectedTechniques.value
            }
        });
        techniqueItems.value = response.data.techniques;
    } catch (error) {
        console.error('Error fetching techniques:', error);
        techniqueItems.value = [];
    }
};

const applyFilters = async () => {
    filtering.value = true;
    page.value = 1;
    try {
        await refreshPatients();
    } finally {
        filtering.value = false;
    }
};


const resetFilters = () => {
    selectedGender.value = null;
    selectedBloodGroup.value = null;
    selectedDateOfBirth.value = null;
    selectedDiagnostics.value = [];
    selectedTechniques.value = [];
    searchQuery.value = "";
    page.value = 1;
    refreshPatients();
};

const addNewPatient = () => {
    router.push({ name: "patients-add" });
};

const deletePatient = async (id) => {
    await softDestroyPatient(id).then(() => {
        refreshPatients();
        hideDeleteDialog();
    });
};

const editPatient = (id) => {
    router.push({ name: "patients-edit-id", params: { id: id } });
};

const viewPatient = (id) => {
    router.push({ name: "patients-id", params: { id: id } });
};

const isDeleteDialogVisible = ref(false);
const selectedId = ref(null);
const deleteMessage = computed(() => {
    const patient = patients.value.find(p => p.id === selectedId.value);
    return patient ? t('deletePatientMessage', { name: patient.fullname }) : '';
});

const showDeleteDialog = (id) => {
    selectedId.value = id;
    isDeleteDialogVisible.value = true;
};

const hideDeleteDialog = () => {
    isDeleteDialogVisible.value = false;
};

const toggleSpeechEhrDialog = () => {
    isSpeechEhrDialogVisible.value = !isSpeechEhrDialogVisible.value;
};

const headers = computed(() => {
    let baseHeaders = [];

    const mdLgHeaders = [
        { title: t("First Name"), value: "first_name", sortable: false, order: 2 },
        { title: t("Last Name"), value: "last_name", sortable: false, order: 3 },
        { title: t("Age"), value: "age", order: 4 },
        { title: t("Gender"), value: "gender", sortable: false, order: 5 },
    ];

    const smAndUpHeaders = [
        { title: "Nº", value: "pid", order: 1 },
        { title: t("Last Diagnostics"), value: "diagnostics", sortable: false, order: 7 },
        { title: t("Last Techniques"), value: "techniques", sortable: false, order: 8 },
    ];

    const smHeaders = [
        { title: t("Full Name"), value: "full_name", sortable: false, order: 6 },
    ];

    if (vuetifyDisplays.smAndUp.value) {
        baseHeaders = [...baseHeaders, ...smAndUpHeaders];
    }

    if (vuetifyDisplays.mdAndUp.value) {
        baseHeaders.push(...mdLgHeaders);
    }

    if (vuetifyDisplays.smAndDown.value) {
        baseHeaders.push(...smHeaders);
    }

    const actionsHeader = { title: t("Actions"), value: "actions", sortable: false, align: "end", order: 9 };

    if (canAccess.byPermission('read_patient') || canAccess.byPermission('write_patient') || canAccess.byPermission('delete_patient')) {
        baseHeaders.push(actionsHeader);
    }

    baseHeaders.sort((a, b) => a.order - b.order);

    return baseHeaders;
});

watchEffect(refreshPatients);

onMounted(async () => {
    await handleDiagnosticSearch('');
    await handleTechniqueSearch('');
});
</script>

<template>
    <section>
        <VRow>
            <VCol cols="12">
                <VAlert v-if="alert" :color="alert.type" variant="tonal" class="mb-2">
                    {{ alert.message }}
                </VAlert>
                <VCard :title="$t('Patient List')">
                    <VDivider />

                    <VExpansionPanels class="elevation-0">
                        <VExpansionPanel>
                            <VExpansionPanelTitle>
                                <div class="d-flex justify-center align-center w-100">
                                    <div class="d-flex flex-column align-center">
                                        <v-icon icon="tabler-filter" size="24" class="mb-2" />
                                        <span>{{ $t('Filters') }}</span>
                                    </div>
                                </div>
                            </VExpansionPanelTitle>
                            <VExpansionPanelText>
                                <VCardText>
                                    <VRow>
                                        <VCol cols="12" sm="4">
                                            <AppSelect v-model="selectedGender" :items="genders" item-title="value"
                                                item-value="name" :label="$t('Gender')" clearable />
                                        </VCol>
                                        <VCol cols="12" sm="4">
                                            <DatePicker v-model="selectedDateOfBirth" :label="$t('Birth Date')"
                                                :config="{ dateFormat: 'dd/MM/yyyy', firstDayOfWeek: 1 }" />
                                        </VCol>
                                        <VCol cols="12" sm="4">
                                            <AppSelect v-model="selectedBloodGroup" :items="bloodGroups"
                                                :label="$t('Blood Group')" clearable />
                                        </VCol>
                                    </VRow>
                                    <VRow>
                                        <VCol cols="12" sm="6">
                                            <AppSelectServer v-model="selectedDiagnostics" :items="diagnosticItems"
                                                :label="$t('Diagnostics')" item-title="name" item-value="id" multiple
                                                clearable chips :search="handleDiagnosticSearch" />
                                        </VCol>
                                        <VCol cols="12" sm="6">
                                            <AppSelectServer v-model="selectedTechniques" :items="techniqueItems"
                                                :label="$t('Techniques')" item-title="name" item-value="id" multiple
                                                clearable chips :search="handleTechniqueSearch" />
                                        </VCol>
                                    </VRow>

                                    <VRow class="d-flex justify-center">
                                        <VDivider class="my-5" />
                                        <VCol cols="12" sm="5" lg="3">
                                            <VBtn :loading="filtering" color="primary" variant="tonal"
                                                @click="applyFilters" block>
                                                {{ $t('Filter') }}
                                            </VBtn>
                                        </VCol>
                                        <VCol cols="12" sm="5" lg="3">
                                            <VBtn color="secondary" variant="tonal" @click="resetFilters" block>
                                                {{ $t('Reset') }}
                                            </VBtn>
                                        </VCol>
                                    </VRow>
                                </VCardText>
                            </VExpansionPanelText>
                        </VExpansionPanel>
                    </VExpansionPanels>

                    <VDivider />

                    <VCardText class="d-flex flex-wrap py-4 gap-4">
                        <div class="me-3 d-none d-sm-flex gap-3">
                            <AppSelect :model-value="itemsPerPage" :items="[{ value: 10, title: '10' },
                            { value: 25, title: '25' },
                            { value: 50, title: '50' },
                            { value: 100, title: '100' },
                            { value: -1, title: 'All' },]" style="inline-size: 6.25rem"
                                @update:model-value="itemsPerPage = parseInt($event, 10)" />
                        </div>

                        <VSpacer />

                        <div class="d-flex align-center flex-wrap gap-4">
                            <div style="inline-size: 10rem">
                                <AppTextField v-model="searchQuery" :placeholder="$t('Search')" density="compact" />
                            </div>

                            <VBtn class="d-none d-md-flex" variant="tonal" color="secondary"
                                prepend-icon="tabler-reload" @click="refreshPatients">
                                {{ $t('Refresh') }}
                            </VBtn>

                            <VBtn v-if="canAccess.byFeature('ai_tools')" size="38" color="gold"
                                @click="toggleSpeechEhrDialog">
                                <VIcon icon="tabler-sparkles" size="22" />
                            </VBtn>

                            <VBtn :size="$vuetify.display.smAndDown ? 38 : undefined" :disabled="isLimitReached"
                                v-if="canAccess.byPermission('write_patient')" @click="addNewPatient">
                                <VIcon :icon="!isLimitReached ? 'tabler-plus' : 'tabler-lock'" size="22" />
                                <span class="d-none d-md-flex ml-1">{{ $t('Add Patient') }}</span>
                            </VBtn>

                        </div>
                    </VCardText>

                    <VDivider />

                    <VDataTableServer v-model:items-per-page="itemsPerPage" loading-text="Loading"
                        :loading="fetchingPatients" v-model:page="page" :sticky="true" :items="patients"
                        :items-length="totalPatients" :headers="headers" class="text-no-wrap"
                        @update:options="updateOptions">
                        <template #item.pid="{ item }">
                            <span class="text-capitalize font-weight-medium">{{ item.pid }}</span>
                        </template>

                        <template #item.first_name="{ item }">
                            <div class="d-flex align-center gap-4">
                                <span :style="{ fontWeight: 650 }">{{ item.first_name || '-' }}</span>
                            </div>
                        </template>

                        <template #item.last_name="{ item }">
                            <div class="d-flex align-center gap-4">
                                <span :style="{ fontWeight: 650 }"> {{ item.last_name || '-' }}</span>
                            </div>
                        </template>

                        <template #item.full_name="{ item }">
                            <div class="d-flex align-center gap-4">
                                <RouterLink :to="{ name: 'patients-id', params: { id: item.id } }"
                                    class="font-weight-medium">
                                    {{ (item.first_name || '') + ' ' + (item.last_name || '') }}
                                </RouterLink>
                            </div>
                        </template>

                        <template #item.age="{ item }">
                            <span>{{ (item.date_of_birth && !(item.agey == 0 && item.agem == 0)) ?
                                (item.agey + ' Ans '
                                    +
                                    item.agem + ' Mois') : '-' }}</span>
                        </template>

                        <template #item.gender="{ item }">
                            <span>{{ item.gender || '-' }}</span>
                        </template>

                        <template #item.diagnostics="{ item }">
                            <p></p>
                            <div class="d-flex align-center" style="height: 100%;">
                                <div v-if="item.last_diagnostics.length > 0" class="d-flex flex-column justify-center">
                                    <span v-for="(diagnostic, index) in item.last_diagnostics" :key="index">
                                        <p class="text-truncate" style="max-width: 150px;">{{ diagnostic }}</p>
                                    </span>
                                </div>
                                <span v-else>-</span>
                            </div>
                        </template>

                        <template #item.techniques="{ item }">
                            <p></p>
                            <div class="d-flex align-center" style="height: 100%;">
                                <div v-if="item.last_techniques.length > 0" class="d-flex flex-column justify-center">
                                    <span v-for="(technique, index) in item.last_techniques" :key="index">
                                        <p class="text-truncate" style="max-width: 150px;">{{ technique }}</p>
                                    </span>
                                </div>
                                <span v-else>-</span>
                            </div>
                        </template>

                        <template #item.actions="{ item }">
                            <div class="align-end">
                                <IconBtn v-if="canAccess.byPermission('write_patient')" @click="editPatient(item.id)">
                                    <VIcon icon="tabler-edit" />
                                </IconBtn>

                                <IconBtn @click="viewPatient(item.id)" class="d-none d-md-inline">
                                    <VIcon icon="tabler-eye" />
                                </IconBtn>

                                <IconBtn v-if="canAccess.byPermission('delete_patient')"
                                    @click="showDeleteDialog(item.id)">
                                    <VIcon icon="tabler-trash" />
                                </IconBtn>
                            </div>
                        </template>
                    </VDataTableServer>
                </VCard>
            </VCol>
        </VRow>
    </section>
    <DeleteConfirmation :is-dialog-visible="isDeleteDialogVisible" :id="selectedId" :message="deleteMessage"
        :soft="true" @delete="deletePatient($event)" @cancel="hideDeleteDialog" />
    <SpeechEhr :is-dialog-visible="isSpeechEhrDialogVisible" @close="toggleSpeechEhrDialog" />
</template>

<style scoped>
::v-deep .v-expansion-panel__shadow {
    display: none;
}

.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>

<style lang="scss">
.text-capitalize {
    text-transform: capitalize;
}

.patient-list-name:not(:hover) {
    color: rgba(var(--v-theme-on-background), var(--v-medium-emphasis-opacity));
}
</style>