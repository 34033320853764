import { deepMerge } from '@antfu/utils'
import { useI18n } from 'vue-i18n'
import { createVuetify } from 'vuetify'
import { VBtn } from 'vuetify/components/VBtn'
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import defaults from './defaults'
import { icons } from './icons'
import { staticPrimaryColor, themes } from './theme'
import { getI18n } from '@/plugins/i18n/index'
import { resolveVuetifyTheme } from '@core/utils/vuetify'
// Styles
import '@core-scss/template/libs/vuetify/index.scss'
import 'vuetify/styles'

export default function(app) {
    const cookieThemeValues = {
        defaultTheme: resolveVuetifyTheme(),
        themes: {
            light: {
                colors: {
                    primary: staticPrimaryColor,
                },
            },
            dark: {
                colors: {
                    primary: staticPrimaryColor,
                },
            },
        },
    }

    const optionTheme = deepMerge({ themes }, cookieThemeValues)

    const vuetify = createVuetify({
        aliases: {
            IconBtn: VBtn,
        },
        defaults,
        icons,
        theme: optionTheme,
        locale: {
            adapter: createVueI18nAdapter({ i18n: getI18n(), useI18n }),
        },
    })

    app.use(vuetify)
}