<script setup>
import { ref, defineAsyncComponent, onErrorCaptured } from 'vue';
import { useRouter } from 'vue-router';
import {$versionCode} from '@/utils/version.js';

definePage({
    meta: {
        isRoute: true,
        userTypes: ['doctor', 'user'],
        permission: 'write_patient',
    },
});

const router = useRouter();
const error = ref(null);

onErrorCaptured((e) => {
    error.value = e;
    return true;
});

const dynamicComponent = defineAsyncComponent(() => {
    if (error.value) {
        router.push({ name: 'error', params: { error: error.value } });
        return;
    }

    return import(`@/pages/patients/components/Trash/${$versionCode()}.vue`);
});
</script>
<template>
    <Suspense>
        <!-- Main content -->
        <template #default>
            <component :is="dynamicComponent" />
        </template>

        <!-- Loading state -->
        <template #fallback>
            <div class="loading-container">
                <VProgressCircular indeterminate color="primary" :size="50"></VProgressCircular>
            </div>
        </template>
    </Suspense>
</template>