<script setup>
import Tutorials from '@/pages/documentation/components/Tutorials.vue'
import { ref } from 'vue';

definePage({
    meta: {
        isRoute: true,
        navActiveLink: "documentation",
    },
});

const searchQuery = ref('');
</script>

<template>
  <div>
    <VCard class="mb-6">
      <VCardText class="py-12 position-relative">
        <div class="d-flex flex-column gap-y-4 mx-auto"
          :class="$vuetify.display.mdAndUp ? 'w-50' : $vuetify.display.xs ? 'w-100' : 'w-75'">

          <h3 class="text-h3 text-center text-wrap mx-auto" :class="$vuetify.display.mdAndUp ? 'w-75' : 'w-100'">
            {{$t('docOverview')}}
          </h3>

          <p class="text-center text-wrap text-body-1 mx-auto mb-0">
            {{ $t('docMessage') }}
          </p>
          <div class="d-flex justify-center align-center gap-4 flex-wrap">
            <AppTextField v-model="searchQuery" placeholder="Search Documentation" density="compact"
              style="min-inline-size: 200px;" />
            <VBtn color="primary" density="comfortable" icon="tabler-search" class="rounded" />
          </div>
        </div>
      </VCardText>
    </VCard>

    <Tutorials :search-query="searchQuery" />

  </div>
</template>

<style lang="scss">
@import 'video.js/dist/video-js.css';

.illustration1 {
  position: absolute;
  inset-block-end: 0;
  inset-inline-end: 0;
}

.illustration2 {
  position: absolute;
  inset-block-start: 2rem;
  inset-inline-start: 2.5rem;
}
</style>
