export default [
    {
        id: 0,
        heading: "Community & Networking",
        order: 0,
    },
    {
        id: 1.1,
        title: "Platform",
        icon: { icon: "tabler-heartbeat" },
        to: "platform",
        feature: "community_networking",
        ownerOnly: true,
        order: 1,
    },
    { id: 1, heading: "Utilities", order: 1 },
    {
        id: 1.1,
        title: "Dashboard",
        icon: { icon: "tabler-home" },
        to: "dashboard",
        order: 1,
    },
    {
        id: 1.2,
        title: "Account",
        icon: { icon: "tabler-user" },
        to: { name: "account-tab", params: { tab: "general" } },
        order: 2,
    },
    {
        id: 1.3,
        title: "Settings",
        icon: { icon: "tabler-settings" },
        to: { name: "settings-tab", params: { tab: "shortcuts" } },
        ownerOnly: true,
        order: 3,
    },
    {
        id: 1.4,
        title: "Trash",
        icon: { icon: "tabler-trash" },
        children: [
            {
                title: "Patients",
                to: "patients-trash",
                permission: "read_patient",
            },
            {
                title: "Appointments",
                to: "appointments-trash",
                permission: "read_appointment",
            },
            {
                title: "Doctors",
                to: "doctors-trash",
                permission: "read_doctor",
            },
            {
                title: "Medications",
                to: "medications-trash",
                permission: "read_medication",
            },
            {
                title: "Diagnostics",
                to: "diagnostics-trash",
                permission: "read_diagnostic",
            },
            {
                title: "Techniques",
                to: "techniques-trash",
                permission: "read_technique",
            },
            {
                title: "Consents",
                to: "consents-trash",
                permission: "read_consent",
            },
            {
                title: "Services",
                to: "services-trash",
                permission: "read_service",
            },
            {
                title: "Bills",
                to: "bills-trash",
                permission: "read_bill",
            },
        ],
        ownerOnly: true,
        order: 4,
    },
    { id: 2, heading: "Healthcare Management", order: 2 },
    {
        id: 2.1,
        title: "Patients",
        icon: { icon: "fluent-patient-20-filled" },
        to: "patients",
        permission: "read_patient",
        order: 1,
    },
    { id: 3, heading: "Scheduling & Planning", order: 3 },
    {
        id: 3.1,
        title: "Calendar",
        icon: { icon: "tabler-calendar" },
        to: "calendar",
        permission: "read_calendar",
        order: 1,
    },
    {
        id: 3.2,
        title: "Appointments",
        icon: { icon: "tabler-calendar-clock" },
        to: "appointments",
        permission: "read_appointment",
        feature: "scheduling_planning",
        order: 2,
    },
    { id: 4, heading: "Medical Database", order: 4 },
    {
        id: 4.1,
        title: "Doctors",
        icon: { icon: "tabler-nurse" },
        to: "doctors",
        permission: "read_doctor",
        order: 5,
    },
    {
        id: 4.2,
        title: "Medications",
        icon: { icon: "tabler-vaccine-bottle" },
        to: "medications",
        permission: "read_medication",
        order: 2,
    },
    {
        id: 4.3,
        title: "Diagnostics",
        icon: { icon: "tabler-heart-rate-monitor" },
        to: "diagnostics",
        permission: "read_diagnostic",
        order: 3,
    },
    {
        id: 4.4,
        title: "Techniques",
        icon: { icon: "tabler-emergency-bed" },
        to: "techniques",
        permission: "read_technique",
        order: 4,
    },
    {
        id: 4.5,
        title: "Consents",
        icon: { icon: "tabler-license" },
        to: "consents",
        permission: "read_consent",
        order: 1,
    },
    { id: 5, heading: "User Management", ownerOnly: true, order: 5 },
    {
        id: 5.1,
        title: "Users",
        icon: { icon: "tabler-users-group" },
        to: "users",
        ownerOnly: true,
        feature: "user_management",
        order: 1,
    },
    {
        id: 5.2,
        title: "Roles",
        icon: { icon: "tabler-user-check" },
        to: "roles",
        ownerOnly: true,
        feature: "user_management",
        order: 2,
    },
    { id: 6, heading: "Financial Management", order: 6 },
    {
        id: 6.1,
        title: "Services",
        icon: { icon: "tabler-first-aid-kit" },
        to: "services",
        permission: "read_service",
        feature: "financial_management",
        order: 1,
    },
    {
        id: 6.2,
        title: "Bills",
        icon: { icon: "tabler-file-invoice" },
        to: "bills",
        permission: "read_bill",
        feature: "financial_management",
        order: 2,
    },
    { id: 7, heading: "Communication Tools", order: 7 },
    {
        id: 7.1,
        title: "Webmail",
        icon: { icon: "tabler-mail" },
        target: "_blank",
        ownerOnly: true,
        isWebmail: true,
        feature: "communication_tools",
        order: 1,
    },
    {
        id: 7.2,
        title: "Notifications",
        icon: { icon: "tabler-bell" },
        to: "notifications",
        order: 2,
    },
    { id: 8, heading: "Reporting Tools", order: 8 },
    {
        id: 8.1,
        title: "Statistics",
        icon: { icon: "tabler-chart-pie-2" },
        to: "statistics",
        ownerOnly: true,
        feature: "reporting_tools",
        order: 1,
    },
    {
        id: 8.2,
        title: "Activity",
        icon: { icon: "tabler-history" },
        to: "activity",
        ownerOnly: true,
        order: 2,
    },
    { id: 9, heading: "Tools", order: 9 },
    {
        id: 9.1,
        title: "Chatbot",
        icon: { icon: "tabler-message-chatbot" },
        to: "chatbot",
        ownerOnly: true,
        feature: "ai_tools",
        order: 1,
    },
    { id: 10, heading: "Documentation & Resources", order: 10 },
    {
        id: 10.1,
        title: "Documentation",
        icon: { icon: "tabler-file-text" },
        to: "documentation",
        order: 1,
    },
];
