<script setup lang="ts">
import type { NotivueItem } from 'notivue'

export interface UploadNotificationProps {
    isUploadNotifiation: boolean
    fileName: string
}

const notification = defineProps<{
    item: NotivueItem<UploadNotificationProps>
}>()

const isPromise = computed(() => notification.item.type === 'promise')
</script>

<template>
    <div class="upload-notification">
        <div class="upload-notification-header">
            <div class="upload-notification-title">
                <h3 :aria-live="item.ariaLive" :role="item.ariaRole">{{ item.message }}</h3>
                <button class="upload-notification-close" @click="item.clear" v-if="!isPromise">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.832 15.832">
                        <g id="x" transform="translate(-4.586 -4.586)">
                            <line x1="13.003" y2="13.003" transform="translate(6 6)" fill="none" stroke="#8e8e8e"
                                stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                            <line x2="13.003" y2="13.003" transform="translate(6 6)" fill="none" stroke="#8e8e8e"
                                stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                        </g>
                    </svg>
                </button>
            </div>
        </div>

        <div class="upload-notification-content">
            <div class="upload-notification-extension">
                {{ item.props.fileName.split('.').pop() }}
            </div>
            <p class="upload-notification-filename">{{ item.props.fileName }}</p>
        </div>

        <div class="Progress" v-if="isPromise">
            <div class="indeterminate-progress-bar">
                <div class="indeterminate-progress-bar__progress"></div>
            </div>
        </div>

        <div class="upload-notification-footer" v-else>
            <time class="time">1 minute ago</time>
            <p class="remaining"><strong>Remaining space:</strong> 302.1 GB</p>
        </div>
    </div>
</template>

<style scoped>
.upload-notification {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    width: 360px;
    max-width: 100%;
    gap: 10px;
}

.upload-notification-header,
.upload-notification-title,
.upload-notification-content,
.upload-notification-footer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.upload-notification-header {
    justify-content: flex-start;
}

.upload-notification-close {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 5px;
    margin: 0;
    outline: none;
    transition: opacity 100ms ease-out;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.upload-notification-close:hover {
    opacity: 0.5;
}

.upload-notification-close svg {
    width: 15px;
}

.upload-notification-title {
    justify-content: space-between;
    width: 100%;
}

.upload-notification-title h3 {
    letter-spacing: -0.025em;
    line-height: 1.25;
    color: #525252;
    margin: 0;
    font-size: 1rem;
}

.IconContainer {
    display: flex;
}

.IconContainer svg {
    width: 20px;
}

.upload-notification-extension {
    justify-content: center;
    border-radius: 5px;
    background-color: #e2f7e8;
    color: #00b300;
    font-weight: 600;
    font-size: 0.7rem;
    padding: 0.5em;
    line-height: 1;
    text-transform: uppercase;
}

.upload-notification-filename {
    color: #6a6a6a;
    font-size: 0.9rem;
    margin-bottom: 0;
}

.upload-notification-footer {
    line-height: 1.25;
    justify-content: space-between;
    font-size: 0.8rem;
    color: #525252;
}

.upload-notification-footer time {
    color: #a3a3a3;
}

.upload-notification-footer .remaining {
    margin-bottom: 0;
}

/* Forked from: https://csslayout.io/indeterminate-progress-bar/ */
.indeterminate-progress-bar {
    background-color: #e2efff;
    border-radius: 9999px;
    height: 0.3rem;
    position: relative;
    overflow: hidden;
}

.indeterminate-progress-bar__progress {
    background-color: #0076ff;
    border-radius: 9999px;
    position: absolute;
    bottom: 0;
    top: 0;
    width: 50%;
    animation: indeterminate-progress-bar 1.5s infinite;
}

@keyframes indeterminate-progress-bar {
    from {
        transform: translateX(-300px);
    }

    to {
        transform: translateX(400px);
    }
}
</style>