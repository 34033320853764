<script setup>
import { ref, watch, onMounted } from 'vue';
import { usePatient } from "@/composables/Tenant/usePatient";
import { useBill } from "@/composables/Tenant/useBill";
import { useService } from "@/composables/Tenant/useService";
import { computed } from 'vue';
import ClientForm from './ClientForm.vue';
import ServicesForm from './ServicesForm.vue';

const props = defineProps({
    bill_id: {
        type: Number,
        required: false,
    },
    patient_id: {
        type: Number,
        required: false,
    }
});

definePage({
    meta: {
        navActiveLink: 'bills',
        permission: 'write_bills',
    },
});

const { fetchPatients } = usePatient();
const { fetchServices } = useService();
const { form, saveBill, billData, fetchBill, errors, billServicesErrors, isLoading } = useBill();

const steps = [
    { title: 'Client', icon: 'tabler-user' },
    { title: 'Services', icon: 'tabler-list' }
];
const currentStep = ref(0);
const patientItems = ref();

const clientFormRef = ref(null);
const servicesFormRef = ref(null);

const fetchPatientItems = async (query = '') => {
    try {
        const response = await fetchPatients({
            query: {
                q: query,
                itemsPerPage: 10,
                include: props.patient_id ? [props.patient_id] : []
            }
        });
        patientItems.value = response.data.patients;
    } catch (error) {
        console.error('Error fetching patients:', error);
        patientItems.value = [];
    }
};

const setPatient = async (id) => {    
    const patient = patientItems.value.find(patient => patient.id === Number(id));
    if (patient) {
        form.patient_id = patient.id;
        form.full_name = patient.fullname;
        form.phone_number = patient.phone_number;
        form.email = patient.email;
        form.address = patient.address;
    }
};

watch(
    () => form.patient_id,
    async (newValue) => {
        if (newValue !== null) {
            form.isLoading = true;
            await setPatient(newValue);
            form.isLoading = false;
        }
    },
    { immediate: true }
);

const setBill = async () => {
    await fetchPatientItems();
    if (props.bill_id) {
        await fetchBill(props.bill_id).then(() => {
            for (const key in billData.value) {
                if (billData.value.hasOwnProperty(key) && form.hasOwnProperty(key)) {
                    form[key] = billData.value[key];
                }
            }
            for (let i = 0; i < form.bill_services.length; i++) {
                billServicesErrors.value.push({
                    service_id: undefined,
                    quantity: undefined,
                });
            }
            form.isLoading = false;
        });
    } else {
        if (props.patient_id) {
            form.patient_id = props.patient_id;
        }
        form.isLoading = false;
    }
};

watch(() => form.bill_services.length, (newLength, oldLength) => {
    if (newLength > oldLength) {
        billServicesErrors.value.push({
            service_id: undefined,
            quantity: undefined,
        });
    }
});

onMounted(() => {
    fetchPatientItems();
    setBill();
});

const onSubmit = async () => {
    await saveBill();
};

const handlePrevious = () => {
    if (currentStep.value > 0) {
        currentStep.value--;
    }
};

const restructuredErrors = computed(() => {
  const serviceErrors = [];
  for (const key in errors.value) {
    if (key.startsWith('bill_services.')) {
      const [, index, field] = key.split('.');
      if (!serviceErrors[index]) {
        serviceErrors[index] = {};
      }
      serviceErrors[index][field] = errors.value[key];
    }
  }
  return serviceErrors;
});

watch(errors, (newValue) => {
    if (newValue.full_name) {
        currentStep.value = 0;
    } else if (Object.keys(newValue).some(key => key.startsWith('bill_services.'))) {
        currentStep.value = 1;
    }
});
</script>

<template>
    <AppCardActions :disable-card-item="true" class="overflow-visible position-relative" no-actions
        :loading="form.isLoading">
        <div class="w-100 sticky-header">
            <div class="card-title d-flex align-center gap-2 flex-wrap bg-background pa-6">
                <VCardTitle class="text-center" :class="$vuetify.display.smAndDown ? 'w-100' : ''">
                    {{ props.bill_id ? $t('Edit Bill') : $t('Create Bill') }}
                </VCardTitle>
                <VSpacer />
                <div :class="$vuetify.display.smAndDown ? 'd-flex flex-column-reverse align-center w-100' : 'd-flex'">
                    <VBtn
                        :to="props.patient_id ? { name: 'patients-id', params: { id: props.patient_id } } : { name: 'bills' }"
                        variant="tonal" :class="$vuetify.display.smAndDown ? 'mt-2 w-100' : 'me-5'" :width="150"
                        :max-width="200">
                        {{ $t('Back') }}
                    </VBtn>
                    <VBtn :loading="isLoading" color="success" @click="onSubmit" variant="tonal"
                        :class="$vuetify.display.smAndDown ? 'w-100' : ''" :width="150" :max-width="200">
                        {{ $t('Save') }}
                    </VBtn>
                </div>
            </div>
            <VCardText class="py-0">
                <VTabs v-model="currentStep" grow stacked>
                    <VTab v-for="(step, index) in steps" :key="index" :value="index">
                        <VIcon :icon="step.icon" class="mb-2" />
                        <span>{{ $t(step.title) }}</span>
                    </VTab>
                </VTabs>
            </VCardText>
        </div>

        <VCardText>
            <VWindow v-model="currentStep">
                <VWindowItem :value="0">
                    <ClientForm ref="clientFormRef" :form="form" :errors="errors" :fetchPatientItems="fetchPatientItems"
                        :patientItems="patientItems" @validate="currentStep++;" @previous="handlePrevious" />
                </VWindowItem>
                <VWindowItem :value="1">
                    <ServicesForm ref="servicesFormRef" v-model:billServices="form.bill_services"
                        :errors="restructuredErrors" :fetchServices="fetchServices" @previous="handlePrevious" />
                </VWindowItem>
            </VWindow>
        </VCardText>
    </AppCardActions>
</template>

<style lang="scss" scoped>
@use "@styles/variables/_vuetify.scss";
.sticky-header {
    position: sticky;
    z-index: 9;
    inset-block-end: 0;
    inset-block-start: 4rem;
    transition: all 0.3s ease-in-out;
}

.card-title {
    border-top-left-radius: vuetify.$card-border-radius;
    border-top-right-radius: vuetify.$card-border-radius;
}
</style>