<script setup>
import Form from "@/pages/roles/components/Form.vue";

definePage({
    meta: {
        isRoute: true,
        navActiveLink: "roles",
        ownerOnly: true,
        userTypes: ['doctor'],
        feature: 'user_management',
    },
});
</script>

<template>
    <Form />
</template>