export default [
    { heading: "System Administration" },
    {
        title: "Terminal",
        icon: {
            icon: "tabler-terminal-2",
        },
        to: "dashboard",
    },
    {
        title: "Owners",
        icon: { icon: "tabler-users" },
        to: "admin-owners",
    },
    {
        title: "Licenses",
        icon: { icon: "tabler-shield-lock" },
        to: "admin-licenses",
    },
    {
        title: "Specialties",
        icon: {
            icon: "tabler-stethoscope",
        },
        to: "admin-specialties",
    },
    {
        title: "Plans",
        icon: {
            icon: "tabler-credit-card",
        },
        to: "admin-plans",
    },
    {
        title: "Versions",
        icon: { icon: "tabler-box" },
        to: "admin-versions",
    },
    {
        title: "Tutorials",
        icon: { icon: "tabler-player-play" },
        to: "admin-tutorials",
    },
    {
        title: "Notifications",
        icon: { icon: "tabler-bell" },
        to: "admin-notifications",
    },
    {
        title: "Databases",
        icon: { icon: "tabler-database" },
        to: "admin-databases",
    },
    {
        title: "Demo Requests",
        icon: { icon: "tabler-device-desktop" },
        to: "admin-demos",
    },
    {
        title: "Inquiries",
        icon: { icon: "tabler-brand-telegram" },
        to: "admin-inquiries",
    },
];