<template>
    <VCard class="chat-card">
        <div class="chat-layout">
            <div :class="[
                'chat-menu',
                { 'chat-menu-open': isMenuOpen },
                { 'chat-menu-mobile': vuetifyDisplays.smAndDown.value }
            ]">
                <div class="menu-content">
                    <div class="pa-2 d-flex justify-space-between align-center">
                        <VList>
                            <VListItem prepend-avatar="https://randomuser.me/api/portraits/men/85.jpg" title="John Doe"
                                subtitle="Online"></VListItem>
                        </VList>
                        <VBtn v-if="vuetifyDisplays.smAndDown.value" icon="tabler-menu-2" variant="text"
                            @click="toggleMenu"></VBtn>
                    </div>

                    <VDivider/>

                    <VList dense nav>
                        <VListItem prepend-icon="tabler-folder" title="My Files" value="myfiles"></VListItem>
                        <VListItem prepend-icon="tabler-users" title="Shared with me" value="shared"></VListItem>
                        <VListItem prepend-icon="tabler-star" title="Starred" value="starred"></VListItem>
                    </VList>
                </div>
            </div>

            <div class="chat-content">
                <div class="pa-2">
                    <div class="d-flex justify-space-between align-center">
                        <VBtn icon="tabler-menu-2" variant="text" @click="toggleMenu" v-if="showMenuButton"></VBtn>
                        <VCardTitle>Chat</VCardTitle>
                        <div style="width: 48px"></div> <!-- Spacer for alignment -->
                    </div>
                </div>

                <VDivider></VDivider>

                <VCardText class="message-area" ref="messageArea">
                    <div v-if="messages.length === 0"
                        class="text-center full-height d-flex align-center justify-center">
                        <div>
                            <VIcon icon="tabler-message-circle-off" size="64" color="grey-lighten-1" />
                            <div class="text-h6 mt-2">No active chat</div>
                        </div>
                    </div>
                    <div v-else>
                        <div v-for="(msg, index) in messages" :key="index" class="message-wrapper">
                            <div :class="['message', msg.sender]">
                                <div class="message-content">{{ msg.text }}</div>
                                <div class="message-time">{{ msg.time }}</div>
                            </div>
                        </div>
                        <div v-if="isTyping" class="message-wrapper">
                            <div class="message bot typing-indicator">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </VCardText>

                <div class="input-area pa-4">
                    <div class="d-flex align-center">
                        <AppTextField v-model="message" @keyup.enter="sendMessage" placeholder="Type your message..."
                            class="flex-grow-1 mr-2" hide-details />
                        <VBtn color="primary" @click="sendMessage">
                            Send
                        </VBtn>
                    </div>
                </div>
            </div>
        </div>
    </VCard>
</template>

<script setup>
import { ref, nextTick, computed } from 'vue';
import { useDisplay } from 'vuetify';

definePage({
    meta: {
        isRoute: true,
        navActiveLink: "calendar",
        userTypes: ['doctor', 'user'],
    },
});

const vuetifyDisplays = useDisplay();
const isMenuOpen = ref(false);
const message = ref('');
const messages = ref([]);
const messageArea = ref(null);
const isTyping = ref(false);

const showMenuButton = computed(() => {
    return vuetifyDisplays.smAndUp.value || (vuetifyDisplays.smAndDown.value && !isMenuOpen.value);
});

const toggleMenu = () => {
    isMenuOpen.value = !isMenuOpen.value;
};

const sendMessage = () => {
    if (message.value.trim()) {
        const now = new Date();
        const time = `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;
        messages.value.push({ text: message.value, sender: 'user', time });
        message.value = '';

        isTyping.value = true;
        scrollToBottom();

        setTimeout(() => {
            const replies = [
                "Got it, thanks for the update.",
                "I'll look into that and get back to you.",
                "That sounds good to me.",
                "Is there anything else you need?",
                "Let me know if you need any more information."
            ];
            const randomReply = replies[Math.floor(Math.random() * replies.length)];

            isTyping.value = false;
            messages.value.push({ text: randomReply, sender: 'bot', time });
            scrollToBottom();
        }, 2000);
    }
};

const scrollToBottom = () => {
    nextTick(() => {
        if (messageArea.value) {
            messageArea.value.scrollTop = messageArea.value.scrollHeight;
        }
    });
};
</script>

<style scoped>
.chat-card {
    height: 600px;
    overflow: hidden;
}

.chat-layout {
    display: flex;
    height: 100%;
    position: relative;
}

.chat-menu {
    width: 0;
    overflow: hidden;
    transition: all 0.3s ease-out;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.chat-menu-open {
    width: 250px;
}

.chat-menu-mobile {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 10;
    background-color: white;
    transform: translateX(-100%);
}

.chat-menu-mobile.chat-menu-open {
    transform: translateX(0);
    width: 250px;
}

.menu-content {
    width: 250px;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.chat-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;
}

.message-area {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.full-height {
    height: 100%;
}

.input-area {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.chat-menu :deep(.v-divider),
.chat-content .v-divider {
    margin-top: 0;
    margin-bottom: 0;
}

.chat-menu .menu-content>div:first-child,
.chat-content>div:first-child {
    height: 56px;
    display: flex;
    align-items: center;
}

.message-wrapper {
    display: flex;
    margin-bottom: 8px;
}

.message {
    max-width: 70%;
    padding: 8px 12px;
    border-radius: 8px;
    position: relative;
}

.message.user {
    margin-left: auto;
    background-color: #e3f2fd;
}

.message.bot {
    margin-right: auto;
    background-color: #f5f5f5;
}

.message-content {
    word-wrap: break-word;
}

.message-time {
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: -18px;
    right: 0;
}

.typing-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 30px;
    padding: 0;
}

.typing-indicator span {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #606060;
    border-radius: 50%;
    margin: 0 2px;
    animation: typing 1s infinite ease-in-out;
}

.typing-indicator span:nth-child(2) {
    animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes typing {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}
</style>