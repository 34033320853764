<script setup>
import { ref, defineAsyncComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { $versionCode } from '@/utils/version.js';

definePage({
    meta: {
        isRoute: true,
        navActiveLink: "patients",
        userTypes: ['doctor', 'user'],
        permission: 'read_patient',
    },
});

const router = useRouter();
const error = ref(null);

const PatientList = defineAsyncComponent({
    loader: () => {
        const version = $versionCode();
        return import(`@/pages/patients/components/List/${version}.vue`)
            .catch((err) => {
                console.error(`Failed to load component for version ${version}:`, err);
                throw new Error(`Unsupported version: ${version}`);
            });
    },
    error: ErrorComponent,
    delay: 200,
    timeout: 3000,
});

function ErrorComponent(props) {
    const { error } = props;
    return h('div', `Error: ${error.message || 'Failed to load the patient list.'}`);
}

onMounted(() => {
    const version = $versionCode();
    import(`@/pages/patients/components/List/${version}.vue`)
        .catch(err => console.error(`Failed to preload component for version ${version}:`, err));
});

const handleError = (err) => {
    console.error('Error loading patient list:', err);
    error.value = err;
    router.push({ name: 'error', params: { error: err.message } });
};
</script>

<template>
    <Suspense @pending="() => error = null" @error="handleError">
        <template #default>
            <PatientList />
        </template>
        <template #fallback>
            <div class="loading-container">
                <VProgressCircular indeterminate color="primary" :size="50" />
            </div>
        </template>
    </Suspense>
</template>