<script setup>
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'
import { useReset } from "@/composables/Auth/useReset";
import { requiredValidator } from '@core/utils/validators.js';

const { resetForm, resetPassword, loading, success, errors, snackBar } = useReset();

definePage({
  meta: {
    isRoute: true,
    layout: 'blank',
    unauthenticatedOnly: true,
  },
});

const refVForm = ref();

const onSubmit = () => {
  refVForm.value?.validate().then(({ valid: isValid }) => {
    if (isValid) resetPassword();
  });
};
</script>

<template>
  <div class="login-page-wrapper">
    <VAppBar>
      <template v-slot:prepend>
        <router-link :to="'/'" class="d-flex align-center">
          <VNodeRenderer :nodes="themeConfig.app.logo" />
          <VToolbarTitle class="text-h4 ml-2">
            <span class="text-capitalize font-weight-bold">{{ themeConfig.app.title }}</span>
          </VToolbarTitle>
        </router-link>
      </template>
    </VAppBar>

    <div class="auth-wrapper d-flex align-center justify-center">
      <VCard width="450" max-width="100%" class="login-card">
        <VCardTitle class="text-center py-4">
          {{ $t('Reset Password') }}
        </VCardTitle>
        <VDivider />
        <VCardText>
          <VForm ref="refVForm" @submit.prevent="onSubmit">
            <VRow>

              <VCol cols="12">
                <AppTextField v-model="resetForm.email" :label="$t('Email')" type="email"
                  :placeholder="$t('user') + '@email.com'" :rules="[requiredValidator, emailValidator]"
                  :error-messages="errors.email" />
              </VCol>

              <VCol cols="12">
                <VBtn v-show="!success" :loading="loading" block type="submit">
                  {{ $t('Reset') }}
                  <template #loader>
                    <span class="button-loader">
                      <VIcon size="30" icon="tabler-loader-2" />
                    </span>
                  </template>
                </VBtn>
                <VBtn v-show="success" :loading="loading" block type="submit" color="success" class="px-10">
                  <VIcon icon="tabler-mail" size="25" />
                </VBtn>
              </VCol>

              <VCol cols="12">
                <RouterLink class="d-flex align-center justify-center" :to="{ name: 'login' }">
                  <VIcon icon="tabler-chevron-left" class="flip-in-rtl" />
                  <span>{{ $t('Back to login') }}</span>
                </RouterLink>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </div>
  </div>
  <VSnackbar v-model="snackBar.visible" :color="snackBar.color">
    <div class="text-center">{{ $t(snackBar.message) }}.</div>
  </VSnackbar>
</template>

<style lang="scss">
@use "@core-scss/template/pages/page-auth.scss";
</style>
