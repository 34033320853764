import { storeToRefs } from 'pinia';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { usePlanStore } from '@/stores/usePlanStore';
import { useUserStore } from '@/stores/useUserStore';
import { useAuthentication } from "@/@core/composable/useAuthentication";

export function useAccessControl() {
    const { isAuthenticated } = useAuthentication();

    const permissionsStore = usePermissionsStore();
    const planStore = usePlanStore();
    const userStore = useUserStore();

    const { isReady } = storeToRefs(permissionsStore);

    if(!isAuthenticated){
        return false;
    }
    
    const canAccess = {
        get isReady() {
            return isReady.value;
        },
        byType: (types) => {
            const allowedTypes = Array.isArray(types) ? types : [types];
            return allowedTypes.includes(userStore.type);
        },
        byPermission: (permission) => {
            return permissionsStore.hasPermission(permission) || !["doctor", "user"].includes(userStore.type);
        },
        byFeature: (feature) => {
            return planStore.hasFeature(feature) || !["doctor", "user"].includes(userStore.type);
        },
        byAdminStatus: () => permissionsStore.isAdmin,
        byOwnerStatus: () => permissionsStore.isOwner,
        byUserStatus: () => permissionsStore.isUser,
        byPatientStatus: () => permissionsStore.isPatient,
    };

    return {
        canAccess,
    };
}