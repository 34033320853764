import { encrypt, decrypt } from "../utils/crypto";

export const encryptedStorage = {
    getItem(key: string): string | null {
        const encryptedData = localStorage.getItem(key);
        if (encryptedData) {
            try {
                return decrypt(encryptedData);
            } catch (error) {
                console.error("Failed to decrypt data:", error);
                return null;
            }
        }
        return null;
    },

    setItem(key: string, value: string): void {
        const encryptedData = encrypt(value);
        localStorage.setItem(key, encryptedData);
    },

    removeItem(key: string): void {
        localStorage.removeItem(key);
    },
};
