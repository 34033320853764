import { breakpointsVuetify } from '@vueuse/core'
import { AppContentLayoutNav, ContentWidth, FooterType, HorizontalNavType, NavbarType } from '@layouts/enums'
import { h } from 'vue';

export const layoutConfig = {
    app: {
        title: 'my-layout',
        logo: h('img', { src: '/src/assets/logo.svg' }),
        contentWidth: ContentWidth.Boxed,
        contentLayoutNav: AppContentLayoutNav.Vertical,
        overlayNavFromBreakpoint: breakpointsVuetify.md,

        // isRTL: false,
        i18n: {
            enable: true,
        },
        iconRenderer: h('div'),
    },
    navbar: {
        type: NavbarType.Sticky,
        navbarBlur: true,
    },
    footer: {
        type: FooterType.Static,
    },
    verticalNav: {
        isVerticalNavCollapsed: false,
        defaultNavItemIconProps: { icon: 'tabler-circle' },
    },
    horizontalNav: {
        type: HorizontalNavType.Sticky,
        transition: 'none',
    },
    icons: {
        chevronDown: { icon: 'tabler-chevron-down' },
        chevronRight: { icon: 'tabler-chevron-right' },
        close: { icon: 'tabler-x' },
        verticalNavPinned: { icon: 'tabler-medical-cross' },
        verticalNavUnPinned: { icon: 'tabler-medical-cross-off' },
        sectionTitlePlaceholder: { icon: 'tabler-minus' },
    },
}