import { VThemeProvider } from 'vuetify/components/VThemeProvider';
import { useThemeStore } from '@/stores/useThemeStore';
import { AppContentLayoutNav } from '@layouts/enums';
import { computed, watch, h } from "vue";

// TODO: Use `VThemeProvider` from dist instead of lib (Using this component from dist causes navbar to loose sticky positioning)
export const useSkins = () => {
    const themeStore = useThemeStore()

    const layoutAttrs = computed(() => ({
        verticalNavAttrs: {
            wrapper: h(VThemeProvider, { tag: 'aside' }),
            wrapperProps: {
                withBackground: true,
                theme: (themeStore.isVerticalNavSemiDark && themeStore.appContentLayoutNav === AppContentLayoutNav.Vertical) ?
                    'dark' : undefined,
            },
        },
    }))

    const injectSkinClasses = () => {
        if (typeof document !== 'undefined') {
            const bodyClasses = document.body.classList
            const genSkinClass = _skin => `skin--${_skin}`

            watch(() => themeStore.skin, (val, oldVal) => {
                bodyClasses.remove(genSkinClass(oldVal))
                bodyClasses.add(genSkinClass(val))
            }, { immediate: true })
        }
    }

    return {
        injectSkinClasses,
        layoutAttrs,
    }
}