<script setup>
import Form from "@/pages/admin/databases/components/TableForm.vue";
import { useRoute } from "vue-router";

const route = useRoute();

definePage({
    meta: {
        isRoute: true,
        adminOnly: true,
        userTypes: ['admin'],
        navActiveLink: "admin-databases",
    },
});
</script>

<template>
    <Form :database="route.params.database" :table="route.params.table" />
</template>