<script setup>
import { ref, computed, onMounted, defineAsyncComponent, watch, provide } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { usePatient } from "@/composables/Tenant/usePatient";
import { useBill } from "@/composables/Tenant/useBill";
import { useAppointment } from "@/composables/Tenant/useAppointment";
import { useConsultation } from "@/composables/Tenant/useConsultation";
import { useIntervention } from "@/composables/Tenant/useIntervention";
import { useControl } from "@/composables/Tenant/useControl";
import { useAccessControl } from "@/composables/useAccessControl";
import { useNotificationsStore } from '@/stores/useNotificationsStore';
import { useDisplay } from 'vuetify'
import { $versionCode } from '@/utils/version.js';

import Exploration from "./components/PatientTabs/Multimedia.vue";
import PrintingDrawer from "./components/PrintingDrawer.vue";
import Appointments from "./components/PatientTabs/Appointments.vue";
import Bills from "./components/PatientTabs/Bills.vue";
import Consultation from "./components/PatientTabs/Consultation.vue";
import Intervention from "./components/PatientTabs/Intervention.vue";
import Control from "./components/PatientTabs/Control.vue";
import Timeline from './components/Timeline.vue';

const Panel = defineAsyncComponent(() => import(`@/pages/patients/components/Panels/${$versionCode()}.vue`));

definePage({
    meta: {
        isRoute: true,
        navActiveLink: "patients",
        userTypes: ['doctor', 'user'],
        permission: 'read_patient',
        key: "tab",
    },
});

const { canAccess } = useAccessControl();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const notificationsStore = useNotificationsStore();

const { folder, fetchFolder, fetchTimeline, softDestroyPatient } = usePatient();
const { destroyConsultation } = useConsultation();
const { destroyIntervention } = useIntervention();
const { destroyControl } = useControl();
const { softDestroyAppointment } = useAppointment();

const patient = ref(null);
const timeline = ref([]);
const consultations = ref([]);
const interventions = ref([]);
const controls = ref([]);
const hasAppointments = ref(false);
const hasBills = ref(false);

const vuetifyDisplay = useDisplay();
const patientId = computed(() => Number(route.params.id));
const activeTab = ref(0);
const loading = ref(true);

const currentConsultationIndex = ref(0);
const currentConsultation = computed(() => consultations.value[currentConsultationIndex.value] || null);
const hasNextConsultation = computed(() => currentConsultationIndex.value < consultations.value.length - 1);
const hasPreviousConsultation = computed(() => currentConsultationIndex.value > 0);

const nextConsultation = () => {
    if (hasNextConsultation.value) {
        currentConsultationIndex.value++;
    }
};

const previousConsultation = () => {
    if (hasPreviousConsultation.value) {
        currentConsultationIndex.value--;
    }
};

const updateConsultations = (updatedConsultations) => {
    consultations.value = updatedConsultations;
};

const deleteConsultation = async ({ id }) => {
    try {
        const index = consultations.value.findIndex(consultation => consultation.id === id);

        if (index !== -1) {
            await destroyConsultation(id);

            consultations.value.splice(index, 1);

            if (consultations.value.length > 0) {
                if (index === currentConsultationIndex.value) {
                    if (index === consultations.value.length) {
                        currentConsultationIndex.value = Math.max(0, index - 1);
                    }
                } else if (index < currentConsultationIndex.value) {
                    currentConsultationIndex.value--;
                }
            } else {
                currentConsultationIndex.value = 0;
            }
        }

        if (consultations.value.length === 0) {
            activeTab.value = 0;
        }

        await fetchTimeline(patientId.value);
    } catch (error) {
        console.error("Error deleting consultation:", error);
        notificationsStore.push("system", {
            type: 'error',
            content: t("An error has occured, try again later."),
        });
    }
};

const currentInterventionIndex = ref(0);
const currentIntervention = computed(() => interventions.value[currentInterventionIndex.value] || null);
const hasNextIntervention = computed(() => currentInterventionIndex.value < interventions.value.length - 1);
const hasPreviousIntervention = computed(() => currentInterventionIndex.value > 0);

const nextIntervention = () => {
    if (hasNextIntervention.value) {
        currentInterventionIndex.value++;
    }
};

const previousIntervention = () => {
    if (hasPreviousIntervention.value) {
        currentInterventionIndex.value--;
    }
};

const updateInterventions = (updatedInterventions) => {
    interventions.value = updatedInterventions;
};

const deleteIntervention = async ({ id }) => {
    try {
        const index = interventions.value.findIndex(intervention => intervention.id === id);

        if (index !== -1) {
            await destroyIntervention(id);

            interventions.value.splice(index, 1);

            if (interventions.value.length > 0) {
                if (index === currentInterventionIndex.value) {
                    if (index === interventions.value.length) {
                        currentInterventionIndex.value = Math.max(0, index - 1);
                    }
                } else if (index < currentInterventionIndex.value) {
                    currentInterventionIndex.value--;
                }
            } else {
                currentInterventionIndex.value = 0;
            }
        }

        if (interventions.value.length === 0) {
            activeTab.value = 0;
        }

        await fetchTimeline(patientId.value);
    } catch (error) {
        console.error("Error deleting intervention:", error);
        notificationsStore.push("system", {
            type: 'error',
            content: t("An error has occured, try again later."),
        });
    }
};

const currentControlIndex = ref(0);
const currentControl = computed(() => controls.value[currentControlIndex.value] || null);
const hasNextControl = computed(() => currentControlIndex.value < controls.value.length - 1);
const hasPreviousControl = computed(() => currentControlIndex.value > 0);

const nextControl = () => {
    if (hasNextControl.value) {
        currentControlIndex.value++;
    }
};

const previousControl = () => {
    if (hasPreviousControl.value) {
        currentControlIndex.value--;
    }
};

const updateControls = (updatedControls) => {
    controls.value = updatedControls;
};

const deleteControl = async ({ id }) => {
    try {
        const index = controls.value.findIndex(control => control.id === id);

        if (index !== -1) {
            await destroyControl(id);

            controls.value.splice(index, 1);

            if (controls.value.length > 0) {
                if (index === currentControlIndex.value) {
                    if (index === controls.value.length) {
                        currentControlIndex.value = Math.max(0, index - 1);
                    }
                } else if (index < currentControlIndex.value) {
                    currentControlIndex.value--;
                }
            } else {
                currentControlIndex.value = 0;
            }
        }

        if (controls.value.length === 0) {
            activeTab.value = 0;
        }

        await fetchTimeline(patientId.value);
    } catch (error) {
        console.error("Error deleting control:", error);
        notificationsStore.push("system", {
            type: 'error',
            content: t("An error has occured, try again later."),
        });
    }
};

const fetchData = async () => {
    try {
        loading.value = true;
        await fetchFolder(patientId.value);

        patient.value = folder.value.patient;
        timeline.value = folder.value.timeline;
        consultations.value = folder.value.consultations;
        interventions.value = folder.value.interventions;
        controls.value = folder.value.controls;
        hasAppointments.value = folder.value.hasAppointments;
        hasBills.value = folder.value.hasBills;

        } catch (error) {
        console.error("Error fetching data:", error);
        notificationsStore.push("system", {
            type: 'error',
            content: t("An error has occurred, please try again later."),
        });
        router.push({ name: 'patients' });
    } finally {
        loading.value = false;
    }
};

onMounted(() => {
    if (patientId.value) fetchData();
});

const updateAppointmentsDisplay = (value) => {
    hasAppointments.value = value;
};

const updateBillsDisplay = (value) => {
    hasBills.value = value;
};

import VueScrollTo from 'vue-scrollto';

const scrollToTabs = () => {
    if (vuetifyDisplay.xs.value || vuetifyDisplay.sm.value) {
        VueScrollTo.scrollTo('.v-tabs-pill', {
            duration: 300,
            offset: -70,
            easing: 'ease',
        });
    }
};

watch(activeTab, scrollToTabs);

const drawerOpen = ref(false);

provide('drawerOpen', drawerOpen);

const toggleDrawer = () => {
    drawerOpen.value = !drawerOpen.value;
};

const appointmentsComponent = ref(null);

const handleTimelineItemDelete = async (item) => {
    let deleteFunction;
    let arrayToUpdate;

    switch (item.type) {
        case 'consultation':
            deleteFunction = destroyConsultation;
            arrayToUpdate = consultations;
            break;
        case 'intervention':
            deleteFunction = destroyIntervention;
            arrayToUpdate = interventions;
            break;
        case 'control':
            deleteFunction = destroyControl;
            arrayToUpdate = controls;
            break;
        case 'appointment':
            deleteFunction = softDestroyAppointment;
            break;
        case 'creation':
            deleteFunction = softDestroyPatient;
            break;
        default:
            console.warn('Invalid Type: ', item.type);
            return;
    }

    if (deleteFunction) {
        try {
            await deleteFunction(item.id);

            if (arrayToUpdate) {
                const index = arrayToUpdate.value.findIndex(i => i.id === item.id);
                if (index !== -1) {
                    arrayToUpdate.value.splice(index, 1);
                }

                if (arrayToUpdate === consultations) {
                    adjustCurrentIndex(consultations, currentConsultationIndex);
                } else if (arrayToUpdate === interventions) {
                    adjustCurrentIndex(interventions, currentInterventionIndex);
                } else if (arrayToUpdate === controls) {
                    adjustCurrentIndex(controls, currentControlIndex);
                }
            }

            await fetchTimeline(patientId.value);

            const capitalizedType = item.type.charAt(0).toUpperCase() + item.type.slice(1);

            const successMessage = item.type === 'creation'
                ? t('Patient deleted successfully')
                : t(`${capitalizedType} deleted successfully`);

            notificationsStore.push("system", {
                type: 'success',
                content: successMessage,
            });

            if (item.type === 'appointment') {
                appointmentsComponent.value?.fetchAndSetAppointments();
            }
            
            if (item.type === 'creation') {
                router.push({ name: 'patients' });
            }
        } catch (error) {
            console.error(`Error deleting ${item.type}:`, error);
            notificationsStore.push("system", {
                type: 'error',
                content: t(`Failed to delete ${item.type}. Please try again.`),
            });
        }
    }
};

const adjustCurrentIndex = (array, currentIndex) => {
    if (array.value.length === 0) {
        currentIndex.value = 0;
    } else if (currentIndex.value >= array.value.length) {
        currentIndex.value = array.value.length - 1;
    }
};

const handleTimelineItemEdit = (item) => {
    let routeName = '';
    switch (item.type) {
        case 'consultation':
            routeName = 'patients-consultation-edit-id';
            break;
        case 'intervention':
            routeName = 'patients-intervention-edit-id';
            break;
        case 'control':
            routeName = 'patients-control-edit-id';
            break;
        case 'appointment':
            routeName = 'appointments-edit-id';
            break;
        case 'creation':
            routeName = 'patients-edit-id';
            break;
        default:
            console.warn('Invalid Type: ', item.type);
            return;
    }
    router.push({ name: routeName, params: { id: item.id } });
};

</script>

<template>
    <VRow class="justify-center">
        <VCol cols="12" md="5" lg="4">
            <VSkeletonLoader v-if="loading" loading-text="Loading" type="card" height="655" />
            <Panel v-show="!loading" v-model:data="patient" @toggle-drawer="toggleDrawer" />
        </VCol>
        <VCol cols="12" md="7" lg="8">
            <Timeline v-if="timeline" :timeline="timeline" @delete="handleTimelineItemDelete"
                @edit="handleTimelineItemEdit" />
            <VSkeletonLoader v-else loading-text="Loading" type="table-tbody" height="655" />
        </VCol>
        <VCol cols="12" class="text-center">
            <VProgressLinear v-if="loading" indeterminate color="primary" class="mb-10" />
            <VTabs
                v-else-if="consultations.length || interventions.length || controls.length || hasAppointments || hasBills"
                ref="tabsRef" align-tabs="center" show-arrows grow stacked v-model="activeTab" class="mb-1"
                :style="{ background: 'rgb(var(--v-theme-surface))' }">
                <VTab :value="0" v-if="consultations?.length">
                    <VIcon size="28" icon="medical-icon-i-inpatient" class="me-1 mb-2" />
                    <span>{{ $t("Consultations") }}</span>
                </VTab>
                <VTab :value="1" v-if="interventions?.length">
                    <VIcon size="28" icon="medical-icon-i-surgery" class="me-1 mb-2" />
                    <span>{{ $t("Interventions") }}</span>
                </VTab>
                <VTab :value="2" v-if="controls?.length">
                    <VIcon size="28" icon="medical-icon-i-intensive-care" class="me-1 mb-2" />
                    <span>{{ $t("Controls") }}</span>
                </VTab>
                <VTab :value="3"
                    v-if="canAccess.byFeature('scheduling_planning') && canAccess.byPermission('read_appointment') && hasAppointments">
                    <VIcon size="28" icon="tabler-calendar-clock" class="me-1 mb-2" />
                    <span>{{ $t("Appointments") }}</span>
                </VTab>
                <VTab :value="4"
                    v-if="canAccess.byFeature('financial_management') && canAccess.byPermission('read_bill') && hasBills">
                    <VIcon size="28" icon="tabler-file-dollar" class="me-1 pb-4 mb-2" />
                    <span>{{ $t("Bills") }}</span>
                </VTab>
            </VTabs>
        </VCol>
        <VCol cols="12" lg="8">
            <div v-show="!loading">
                <VWindow v-model="activeTab" :touch="false">
                    <VWindowItem :value="0" v-if="consultations?.length">
                        <Consultation :current-consultation="currentConsultation" :has-next="hasNextConsultation"
                            :has-previous="hasPreviousConsultation" @delete:consultation="deleteConsultation"
                            @next="nextConsultation" @previous="previousConsultation" @back="activeTab = 0" />
                        <p></p>
                        <Exploration :data="consultations" :current-id="currentConsultation?.id" @back="activeTab = 0"
                            @updateData="updateConsultations" />
                    </VWindowItem>
                    <VWindowItem :value="1" v-if="interventions?.length">
                        <Intervention :current-intervention="currentIntervention" :has-next="hasNextIntervention"
                            :has-previous="hasPreviousIntervention" @delete:intervention="deleteIntervention"
                            @next="nextIntervention" @previous="previousIntervention" @back="activeTab = 0" />
                        <p></p>
                        <Exploration :data="interventions" :current-id="currentIntervention?.id" @back="activeTab = 0"
                            @updateData="updateInterventions" />
                    </VWindowItem>
                    <VWindowItem :value="2" v-if="controls?.length">
                        <Control :current-control="currentControl" :has-next="hasNextControl"
                            :has-previous="hasPreviousControl" @delete:control="deleteControl" @next="nextControl"
                            @previous="previousControl" @back="activeTab = 0" />
                        <p></p>
                        <Exploration :data="controls" :current-id="currentControl?.id" @back="activeTab = 0"
                            @updateData="updateControls" />
                    </VWindowItem>
                    <VWindowItem :value="3"
                        v-if="canAccess.byFeature('scheduling_planning') && canAccess.byPermission('read_appointment') && hasAppointments">
                        <Appointments ref="appointmentsComponent" @display="updateAppointmentsDisplay"
                            @back="activeTab = 0" :patient-id="patientId" />
                    </VWindowItem>
                    <VWindowItem :value="4"
                        v-if="canAccess.byFeature('financial_management') && canAccess.byPermission('read_bill') && hasBills">
                        <Bills @display="updateBillsDisplay" @back="activeTab = 0" :patient-id="patientId" />
                    </VWindowItem>
                </VWindow>
            </div>
            <VSkeletonLoader v-if="loading" loading-text="Loading" type="card" height="600" />
        </VCol>
    </VRow>
    <PrintingDrawer :consultations="consultations" :interventions="interventions" />
</template>