<script setup>
import { useGenerateImageVariant } from '@core/composable/useGenerateImageVariant'
import miscMaskDark from '@images/pages/misc-mask-dark.png'
import miscMaskLight from '@images/pages/misc-mask-light.png'

const authThemeMask = useGenerateImageVariant(miscMaskLight, miscMaskDark)

definePage({
    meta: {
        isRoute: true,
        layout: 'blank',
        public: true,
    },
});
</script>

<template>
    <div class="misc-wrapper">
        <ErrorHeader status-code="500" :title="$t('500_ERROR_TITLE')"
            :description="$t('500_ERROR_MESSAGE')" />
        <VBtn to="/dashboard" class="mb-12">
            {{ $t('Home') }}
        </VBtn>

        <VImg :src="authThemeMask" class="misc-footer-img d-none d-md-block" />
    </div>
</template>

<style lang="scss">
@use "@core-scss/template/pages/misc.scss";
</style>
