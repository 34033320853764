import { createApp } from "vue";
import App from "@/App.vue";
import { registerPlugins } from "@core/utils/plugins";
import axios from "axios";
import "@core-scss/template/index.scss";
import "@styles/styles.scss";

const app = createApp(App);

app.config.errorHandler = (err, vm, info) => {
    console.error(err);

    axios.post('/api/log-error', {
            message: err.message,
            url: window.location.href,
            line: err.line,
            column: err.column,
            error: err.stack,
            userAgent: navigator.userAgent,
        })
        .catch((error) => {
            console.error("Failed to send error log:", error);
        });
};

registerPlugins(app);

app.mount("#app");