<script setup>
import FullCalendar from '@fullcalendar/vue3';
import { blankEvent, useCalendar, } from "@/composables/Tenant/useCalendar";
import { useCalendarStore } from "@/composables/Tenant/useCalendarStore";
import CalendarEventHandler from '@/pages/calendar/components/CalendarEventHandler.vue';
import { useDisplay } from 'vuetify';
import { useI18n } from 'vue-i18n';
import { ref, watchEffect, watch, computed, onMounted, nextTick } from 'vue';
import {useResponsiveLeftSidebar} from '@core/composable/useResponsiveSidebar.js';

const vuetifyDisplays = useDisplay();

definePage({
  meta: {
    isRoute: true,
    navActiveLink: "calendar",
    userTypes: ['doctor', 'user', 'patient'],
    permission: 'read_calendar',
  },
});

const { locale } = useI18n({ useScope: "global" });
const store = useCalendarStore()
const event = ref(structuredClone(blankEvent))
const handlerEvent = ref(null);

watchEffect(() => {
  if (event.value.end && event.value.allDay) {
    let endDate = new Date(event.value.end);
    endDate.setDate(endDate.getDate() - 1);
    event.value.end = endDate;
  }
});

const isEventHandlerSidebarActive = ref(false)

watch(isEventHandlerSidebarActive, val => {
  if (!val)
    event.value = structuredClone(blankEvent)
})

const { isLeftSidebarOpen } = useResponsiveLeftSidebar()

const { refCalendar, calendarOptions, addEvent, updateEvent, removeEvent } = useCalendar(event, isEventHandlerSidebarActive, isLeftSidebarOpen)

if (vuetifyDisplays.smAndDown.value) {
  calendarOptions.initialView = "timeGridDay";
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours() - 1).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

watchEffect(() => {
  const updatedEvent = { ...event.value };

  if (updatedEvent.start) {
    updatedEvent.start = formatDate(updatedEvent.start);
  }

  if (updatedEvent.end) {
    updatedEvent.end = formatDate(updatedEvent.end);
  }

  handlerEvent.value = updatedEvent;
});


const checkAll = computed({
  get: () => store.selectedCalendars.length === store.availableCalendars.length,
  set: val => {
    if (val)
      store.selectedCalendars = store.availableCalendars.map(i => i.label)
    else if (store.selectedCalendars.length === store.availableCalendars.length)
      store.selectedCalendars = []
  },
});

const isCalendarReady = ref(false)

onMounted(() => {
  nextTick(() => {
    isCalendarReady.value = true
  })
});

</script>

<template>
  <div>
    <VCard>
      <VLayout style="z-index: 0;">
        <VNavigationDrawer v-model="isLeftSidebarOpen" width="292" absolute touchless location="start"
          class="calendar-add-event-drawer" :temporary="$vuetify.display.mdAndDown">
          <div style="margin: 1.4rem;">
            <VBtn block prepend-icon="tabler-plus" @click="isEventHandlerSidebarActive = true">
              {{ $t('Add Event') }}
            </VBtn>
          </div>

          <VDivider />
          <div class="pa-7">
            <p class="text-sm text-uppercase text-disabled mb-3">
              {{ $t('FILTER') }}
            </p>

            <div class="d-flex flex-column calendars-checkbox">
              <VCheckbox v-model="checkAll" :label="$t('View all')" />
              <VCheckbox v-for="calendar in store.availableCalendars" :key="calendar.label"
                v-model="store.selectedCalendars" :value="calendar.label" :color="calendar.color"
                :label="$t(calendar.label)" />
            </div>
          </div>
        </VNavigationDrawer>

        <VMain>
          <VCard flat>
            <FullCalendar v-if="isCalendarReady" :key="locale" ref="refCalendar" :options="calendarOptions" />
          </VCard>
        </VMain>

      </VLayout>
    </VCard>
    <CalendarEventHandler v-model:isDrawerOpen="isEventHandlerSidebarActive" :event="handlerEvent" @add-event="addEvent"
      @update-event="updateEvent" @remove-event="removeEvent" />
  </div>
</template>

<style lang="scss">
@use "@core-scss/template/libs/full-calendar";

.calendars-checkbox {
  .v-label {
    color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));
    opacity: var(--v-high-emphasis-opacity);
  }
}

.calendar-add-event-drawer {
  max-width: 260px;
  &.v-navigation-drawer:not(.v-navigation-drawer--temporary) {
    border-end-start-radius: 0.375rem;
    border-start-start-radius: 0.375rem;
  }
}

.calendar-date-picker {
  display: none;

  +.flatpickr-input {
    +.flatpickr-calendar.inline {
      border: none;
      box-shadow: none;

      .flatpickr-months {
        border-block-end: none;
      }
    }
  }

  &~.flatpickr-calendar .flatpickr-weekdays {
    margin-block: 0 4px;
  }
}

.fc-toolbar-title::first-letter,.fc-col-header-cell-cushion::first-letter {
  text-transform: uppercase;
}

@media (max-width: 633px) {
  .fc-header-toolbar.fc-toolbar {
    justify-content: center !important;
  }
}

@media (max-width: 420px) {
  .fc-header-toolbar .fc-toolbar-chunk:last-child .fc-button-group {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.v-layout {
  overflow: visible !important;

  .v-card {
    overflow: visible;
  }
}

.fc-button-group:last-of-type {
  display: none;
}

.fc-button-group:last-child {
  display: none;
}
</style>
