import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { push } from "notivue";
import { getI18n } from "../plugins/i18n";
import axios from "axios";
import GlobalNotification, {
    type GlobalNotificationProps,
} from "../@core/components/notifications/GlobalNotification.vue";
import UploadNotification, {
    type UploadNotificationProps,
} from "../@core/components/notifications/UploadNotification.vue";
import SimpleNotification, {
    type SimpleNotificationProps,
} from "../@core/components/notifications/SimpleNotification.vue";
import { useUserStore } from "./useUserStore";
import { encryptedStorage } from "../@core/composable/encryptedStorage";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

const i18n = getI18n();
const t = i18n.global.t;

export const useNotificationsStore = defineStore("notifications", {
    state: () => ({
        notifications: ref([]),
        progressBar: false,
        activePromises: ref([]),
        channels: ref([]),
        refreshInterval: 5 * 60 * 1000,
        lastRefreshTime: null as number | null,
        isReady: false,
    }),
    actions: {
        async initialize() {
            try {
                await this.fetch();
                this.initializeSocket();
                this.isReady = true;
            } catch (error) {
                console.error("Error fetching notifications:", error);
                this.isReady = false;
                throw error;
            }
        },
        async fetch() {
            try {
                const response = await axios.get(`/api/notifications`);
                this.notifications = response.data.notifications;
                this.lastRefreshTime = Date.now();
            } catch (error) {
                console.error("Failed to fetch notifications:", error);
            }
        },
        initializeSocket() {
            // const userStore = useUserStore();
            // const centralChannel = (window as any).Echo.channel(
            //     "central.notifications"
            // );
            // centralChannel.listen("CentralNotificationCreated", async (e) => {
            //     e.notification.type = "global";
            //     this.push("headless", e.notification);
            //     this.refresh();
            // });
            // this.channels.push(centralChannel);
            // const tenantChannel = (window as any).Echo.private(
            //     "tenant." + userStore.tenantId + ".notifications"
            // );
            // tenantChannel.listen("TenantNotificationCreated", async (e) => {
            //     e.notification.type = "global";
            //     this.push("headless", e.notification);
            //     this.refresh();
            // });
            // this.channels.push(tenantChannel);
        },
        pushUnseen() {
            const unseenNotifications = computed(() => {
                return this.notifications.filter(
                    (notification) => !notification.is_seen
                );
            });

            unseenNotifications.value.forEach((notification, index) => {
                notification.type = "global";
                setTimeout(() => {
                    this.push("headless", notification);
                }, index * 1000);
            });
        },
        extract(notification) {
            const index = this.activePromises.indexOf(notification);
            if (index !== -1) {
                return this.activePromises.splice(index, 1)[0];
            }
            return null;
        },
        resolve(notification, message) {
            const extractedNotification = this.extract(notification);
            if (extractedNotification) {
                extractedNotification.resolve({
                    message: message,
                    duration: 3000,
                });
            }
        },
        reject(notification, message) {
            const extractedNotification = this.extract(notification);
            if (extractedNotification) {
                extractedNotification.reject({
                    message: message,
                    duration: 3000,
                });
            }
        },
        push(type, arg) {
            if (type === "system") {
                return this.pushSystemNotification(arg);
            } else if (type === "headless") {
                return this.pushHeadlessNotification(arg);
            } else {
                console.error("Unsupported notification type: " + type);
            }
        },
        pushSystemNotification(arg) {
            const systemNotificationTypes = {
                success: push.success,
                error: push.error,
                warning: push.warning,
                info: push.info,
            };

            const { type, title, content } = arg;

            if (type in systemNotificationTypes) {
                systemNotificationTypes[type]({
                    title,
                    message: content,
                    duration: 3000,
                });
                return;
            } else if (type === "promise") {
                const notification = push.promise({
                    message: arg.content,
                });

                this.activePromises.push(notification);

                setTimeout(() => {
                    if (this.activePromises.includes(notification)) {
                        const extractedNotification =
                            this.extract(notification);
                        if (extractedNotification) {
                            extractedNotification.reject({
                                message: t("Request timed out") + ".",
                                duration: 3000,
                            });
                        }
                    }
                }, 60000);
                return notification;
            }
            console.error("Unsupported system notification type: " + type);
        },
        pushHeadlessNotification(arg) {
            const { type } = arg;
            if (type === "global") {
                return this.pushGlobalNotification(arg);
            } else if (type === "upload") {
                return this.pushUploadNotification(arg);
            } else if (type === "simple") {
                return this.pushSimpleNotification(arg);
            } else {
                console.error(
                    "Unsupported headless notification type: " + type
                );
            }
        },
        pushGlobalNotification(arg) {
            const { title, created_at, image, icon, color } = arg;
            const content = this.getLocalizedContent(arg);
            const props: GlobalNotificationProps = {
                image: "",
                isGlobalNotifiation: true,
                title,
                content: this.truncateContent(content),
                created_at,
                ...(image ? { image } : {}),
                ...(icon
                    ? { icon, color: color || "primary" }
                    : { icon: "tabler-bell-filled", color: "primary" }),
            };

            push.success<GlobalNotificationProps>({ props });
        },
        async pushUploadNotification(arg) {
            const props = {
                isUploadNotifiation: true,
                fileName: "excel-sheet.xlsx",
            };

            const promise = push.promise<UploadNotificationProps>({
                message: "Your file is being uploaded...",
                props,
            });

            await new Promise((resolve) => setTimeout(resolve, 2000));

            promise.resolve({
                message: "Your file has been successfully uploaded.",
                props,
            });
        },
        pushSimpleNotification(arg) {
            push.success<SimpleNotificationProps>({
                message: `Your message has been deleted.`,
                props: {
                    isSimpleNotification: true,
                },
            });
        },
        getLocalizedContent(arg) {
            const locale = i18n.global.locale.value;
            const contentKey = `content_${locale.toUpperCase()}`;
            return arg[contentKey] || "";
        },
        truncateContent(content: string): string {
            const maxLength = 38;
            return content.length > maxLength
                ? content.slice(0, maxLength) + "..."
                : content;
        },
        getNotificationMessages(action, entity) {
            return {
                promise: `${t(action + "ing")} ${t("of" + entity)}...`,
                resolve: `${t(entity)} ${t(
                    action.toLowerCase() + "ed successfully"
                )}`,
                reject: `${t("An error has occured")}`,
            };
        },
        reset() {
            this.resetSocket();
            this.$reset();
            localStorage.removeItem("notifications");
        },
        resetSocket() {
            // this.channels.forEach((channel) => {
            //     channel.stopListening("CentralNotificationCreated");
            //     channel.stopListening("TenantNotificationCreated");
            //     (window as any).Echo.leave(channel.name);
            // });
            // this.channels = ref([]);
        },
    },
    persist: {
        storage: encryptedStorage,
    },
});
