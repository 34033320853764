<script setup>
import { useDemo } from "@/composables/Central/Admin/useDemo";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import { useRouter } from "vue-router";
import { ref, watchEffect,computed } from "vue";

definePage({
    meta: {
        isRoute: true,
        adminOnly: true,
        userTypes: ['admin'],
    },
});

const vuetifyDisplays = useDisplay();
const { t } = useI18n();
const router = useRouter();
const demos = ref([]);
const totalDemos = ref(0);
const searchQuery = ref("");
const itemsPerPage = ref(10);
const page = ref(1);
const sortBy = ref();
const orderBy = ref();

const updateOptions = (options) => {
    demos.value = [];
    page.value = options.page;
    sortBy.value = options.sortBy[0]?.key;
    orderBy.value = options.sortBy[0]?.order;
};

const { fetchDemos, fetchingDemos, destroyDemo } = useDemo();

const refreshDemos = () => {
    fetchDemos({
        query: {
            q: searchQuery.value,
            itemsPerPage: itemsPerPage.value,
            page: page.value,
            sortBy: sortBy.value,
            orderBy: orderBy.value,
        },
    })
        .then((response) => {
            fetchingDemos.value = false;
            demos.value = response.data.demos;
            totalDemos.value = response.data.total;
        })
        .catch((error) => {
            console.error(error);
        });
};

watchEffect(refreshDemos);

const headers = computed(() => {
    const baseHeaders = [
        { title: t("Name"), value: "name", order: 1 },
    ];

    if (vuetifyDisplays.smAndUp.value) {
        baseHeaders.push(
            { title: t("Medical Specialty"), value: "specialty", order: 2 },
            { title: t("Date & Time"), value: "date_time", order: 3 }
        );
    } else {
        baseHeaders.push({ title: t("Date & Time"), value: "date_time", order: 2 });
    }

    if (vuetifyDisplays.mdAndUp.value) {
        baseHeaders.push(
            { title: t("Email"), value: "email", order: 4 },
            { title: t("Phone Number"), value: "phone_number", order: 5 },
            { title: t("Date"), value: "date", order: 6 },
            { title: t("Time"), value: "time", order: 7 },
            { title: t("Status"), value: "status", order: 8 }
        );
    }

    baseHeaders.push({ title: t("Actions"), value: "actions", sortable: false, align: "end", order: 9 });
    return baseHeaders.sort((a, b) => a.order - b.order);
});

const addNewDemo = () => {
    router.push({ name: "admin-demos-add" });
};

const deleteDemo = async (id) => {
    await destroyDemo(id).then(() => {
        refreshDemos();
        hideDeleteDialog();
    });
};

const editDemo = (id) => {
    router.push({ name: "admin-demos-edit-id", params: { id: id } });
};

const isDeleteDialogVisible = ref(false);
const selectedId = ref(null);
const deleteMessage = computed(() => {
    const demo = demos.value.find(m => m.id === selectedId.value);
    return demo ? t('deleteDemoMessage', { name: demo.name }) : '';
});

const showDeleteDialog = (id) => {
    selectedId.value = id;
    isDeleteDialogVisible.value = true;
};

const hideDeleteDialog = () => {
    isDeleteDialogVisible.value = false;
};

const getStatusColor = (status) => {
    return status === 1 ? 'success' : 'error';
};

const getStatusText = (status) => {
    return status === 1 ? t('Completed') : t('Not Completed');
};
</script>

<template>
    <section>
        <VRow>
            <VCol cols="12">
                <VCard :title="$t('Demo Request List')">
                    <VCardText class="d-flex flex-wrap py-4 gap-4">
                        <div class="me-3 d-none d-sm-flex gap-3">
                            <AppSelect :model-value="itemsPerPage" :items="[
                                { value: 10, title: '10' },
                                { value: 25, title: '25' },
                                { value: 50, title: '50' },
                                { value: 100, title: '100' },
                                { value: -1, title: 'All' },
                            ]" style="inline-size: 6.25rem"
                                @update:model-value="itemsPerPage = parseInt($event, 10)" />
                        </div>
                        <VSpacer />

                        <div class="d-flex align-center flex-wrap gap-4">
                            <div style="inline-size: 10rem">
                                <AppTextField v-model="searchQuery" :placeholder="$t('Search')" density="compact" />
                            </div>

                            <VBtn class="d-none d-md-flex" variant="tonal" color="secondary"
                                prepend-icon="tabler-reload" @click="refreshDemos">
                                {{ $t('Refresh') }}
                            </VBtn>

                            <VBtn @click="addNewDemo">
                                <VIcon icon="tabler-plus" size="22" class="mr-1" />
                                <span class="d-none d-md-flex">{{ $t('Add Demo Request') }}</span>
                            </VBtn>
                        </div>
                    </VCardText>

                    <VDivider />

                    <VDataTableServer loading-text="Loading" :loading="fetchingDemos" :sticky="true"
                        v-model:items-per-page="itemsPerPage" v-model:page="page" :items="demos"
                        :items-length="totalDemos" :headers="headers" class="text-no-wrap"
                        @update:options="updateOptions">

                        <template #item.name="{ item }">
                            <div class="d-flex align-center gap-4">
                                <span>{{ item.name || '-' }}</span>
                            </div>
                        </template>

                        <template #item.specialty="{ item }">
                            <div>{{ item.specialty || '-' }}</div>
                        </template>

                        <template #item.date_time="{ item }">
                            <div>
                                {{ item.date || '-' }} {{ item.time || '-' }}
                            </div>
                        </template>

                        <template #item.email="{ item }">
                            <div>{{ item.email || '-' }}</div>
                        </template>

                        <template #item.phone_number="{ item }">
                            <div>{{ item.phone_number || '-' }}</div>
                        </template>

                        <template #item.date="{ item }">
                            <div>{{ item.date || '-' }}</div>
                        </template>

                        <template #item.time="{ item }">
                            <div>{{ item.time || '-' }}</div>
                        </template>

                        <template #item.status="{ item }">
                            <VChip :color="getStatusColor(item.status)" text-color="white" size="small"
                                class="text-capitalize">
                                {{ getStatusText(item.status) }}
                            </VChip>
                        </template>

                        <template #item.actions="{ item }">
                            <div class="align-end">
                                <IconBtn @click="editDemo(item.id)">
                                    <VIcon icon="tabler-edit" />
                                </IconBtn>

                                <IconBtn @click="showDeleteDialog(item.id)">
                                    <VIcon icon="tabler-trash" />
                                </IconBtn>
                            </div>
                        </template>
                    </VDataTableServer>
                </VCard>
            </VCol>
        </VRow>
    </section>
    <DeleteConfirmation :is-dialog-visible="isDeleteDialogVisible" :id="selectedId" :message="deleteMessage"
        @delete="deleteDemo($event)" @cancel="hideDeleteDialog" />
</template>

<style lang="scss">
.text-capitalize {
    text-transform: capitalize;
}

.demo-list-name:not(:hover) {
    color: rgba(var(--v-theme-on-background), var(--v-medium-emphasis-opacity));
}

.align-right {
    text-align: right;
}

.align-end,
.v-data-table__th:last-child {
    display: flex !important;
    justify-content: flex-end !important;
}
</style>