<script setup>
import { useRole } from "@/composables/Tenant/useRole";
import { requiredValidator } from '@core/utils/validators.js';
import { ref, computed, watchEffect } from "vue";
import { useRoute } from "vue-router";

const props = defineProps({
    id: {
        type: Number,
        required: false,
    },
});

definePage({
    meta: {
        adminOnly: true,
        navActiveLink: 'roles',
    },
});

const {
    form,
    roleData,
    fetchRole,
    saveRole,
    isLoading,
    errors,
} = useRole();

const roleId = ref(props.id);

const alertMessage = ref('');
const showAlert = ref(false);

const hasPermissions = computed(() => {
    return permissions.value.some(permission =>
        permission.read || permission.write || permission.delete
    );
});

const onSubmit = async () => {
    if (!refVForm.value) return;
    const { valid: isValid } = await refVForm.value.validate();
    if (isValid) {
        if (!hasPermissions.value) {
            alertMessage.value = "Please select at least one permission before saving.";
            showAlert.value = true;
            return;
        }
        form.permissions = permissions.value;
        await saveRole();
    }
};

const refVForm = ref();

const permissions = ref([{
    name: 'Appointment Management',
    value: 'appointment',
    read: false,
    write: false,
    delete: false,
},
{
    name: 'Patient Management',
    value: 'patient',
    read: false,
    write: false,
    delete: false,
},
{
    name: 'Doctor Management',
    value: 'doctor',
    read: false,
    write: false,
    delete: false,
},
{
    name: 'Medication Management',
    value: 'medication',
    read: false,
    write: false,
    delete: false,
},
{
    name: 'Diagnostic Management',
    value: 'diagnostic',
    read: false,
    write: false,
    delete: false,
},
{
    name: 'Technique Management',
    value: 'technique',
    read: false,
    write: false,
    delete: false,
},
{
    name: 'Consent Management',
    value: 'consent',
    read: false,
    write: false,
    delete: false,
},
{
    name: 'Calendar Management',
    value: 'calendar',
    read: false,
    write: false,
    delete: false,
},
{
    name: 'Service Management',
    value: 'service',
    read: false,
    write: false,
    delete: false,
},
{
    name: 'Bill Management',
    value: 'bill',
    read: false,
    write: false,
    delete: false,
},
{
    name: 'Consultation Management',
    value: 'consultation',
    read: false,
    write: false,
    delete: false,
},
{
    name: 'Control Management',
    value: 'control',
    read: false,
    write: false,
    delete: false,
},
]);

const isSelectAll = computed(() => {
    return permissions.value.every(permission =>
        permission.read && permission.write && permission.delete
    );
});

const checkedCount = computed(() => {
    return permissions.value.reduce((acc, permission) => {
        acc += permission.read ? 1 : 0;
        acc += permission.write ? 1 : 0;
        acc += permission.delete ? 1 : 0;
        return acc;
    }, 0);
});

const toggleSelectAll = () => {
    const currentlySelected = permissions.value.every(permission =>
        permission.read && permission.write && permission.delete
    );
    permissions.value.forEach(permission => {
        permission.read = !currentlySelected;
        permission.write = !currentlySelected;
        permission.delete = !currentlySelected;
    });
};

const isIndeterminate = computed(() => {
    const totalCount = permissions.value.length * 3;
    const checked = checkedCount.value;
    return checked > 0 && checked < totalCount;
});

const onReset = () => {
    isSelectAll.value = false
    refVForm.value?.reset()
}

const setRole = async () => {
    if (roleId.value) {
        form.isLoading = true;
        try {
            await fetchRole(roleId.value);
            form.id = roleData.value.role.id;
            form.name = roleData.value.role.name;
            const fetchedPermissions = roleData.value.permissions;

            permissions.value = permissions.value.map(permission => {
                const fetchedPermission = fetchedPermissions.find(p => p.value === permission.value);
                return {
                    ...permission,
                    read: fetchedPermission ? fetchedPermission.read : false,
                    write: fetchedPermission ? fetchedPermission.write : false,
                    delete: fetchedPermission ? fetchedPermission.delete : false,
                };
            });

        } catch (error) {
            console.error("Failed to fetch role", error);
        } finally {
            form.isLoading = false;
        }
    } else {
        form.isLoading = false;
    }
};

const checkRead = (permission) => {
    if (permission.write || permission.delete) {
        permission.read = true;
    }
};

watchEffect(setRole);
</script>

<template>
    <AppCardActions :title="props.id ? $t('Edit Role') : $t('Create Role')" no-actions v-model:loading="form.isLoading">
        <VAlert v-if="showAlert" type="error" closable class="mb-4 mx-2" @click:close="showAlert = false">
            {{$t(alertMessage)}}
        </VAlert>

        <VCardText>
            <VForm ref="refVForm">
                <VTextField v-model="form.name" :label="$t('Name')" :rules="[requiredValidator]"
                    :error-messages="errors.name" class="mb-4" />
                <h3 class="text-h6 mb-4">{{ $t('Permissions') }}</h3>
                <div v-if="$vuetify.display.mdAndUp">
                    <VTable class="text-no-wrap mb-6 border rounded">
                        <thead>
                            <tr>
                                <th scope="col">{{ $t('Permission') }}</th>
                                <th scope="col">{{ $t('Read') }}</th>
                                <th scope="col">{{ $t('Write') }}</th>
                                <th scope="col">{{ $t('Delete') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="font-weight-bold text-base">
                                    {{ $t('Administrator Access') }}
                                </td>
                                <td colspan="3">
                                    <div class="d-flex justify-end">
                                        <VCheckbox @click="toggleSelectAll" :indeterminate="isIndeterminate"
                                            :model-value="isSelectAll" :label="$t('Select All')" />
                                    </div>
                                </td>
                            </tr>
                            <tr v-for="permission in permissions" :key="permission.name">
                                <td width="400px"><span class="text-base">{{ $t(permission.name) }}</span></td>
                                <td>
                                    <VCheckbox v-model="permission.read"
                                        :disabled="permission.write || permission.delete" :label="$t('Read')" />
                                </td>
                                <td>
                                    <VCheckbox v-model="permission.write" @change="() => checkRead(permission)"
                                        :label="$t('Write')" />
                                </td>
                                <td>
                                    <VCheckbox v-model="permission.delete" @change="() => checkRead(permission)"
                                        :label="$t('Delete')" />
                                </td>
                            </tr>
                        </tbody>
                    </VTable>
                </div>
                <div v-else>
                    <VCheckbox @click="toggleSelectAll" :indeterminate="isIndeterminate" :model-value="isSelectAll"
                        :label="$t('Select All')" class="mb-4" />
                    <div class="permissions-list">
                        <VExpansionPanels>
                            <VExpansionPanel v-for="permission in permissions" :key="permission.name">
                                <VExpansionPanelTitle>
                                    {{ $t(permission.name) }}
                                </VExpansionPanelTitle>
                                <VExpansionPanelText>
                                    <VCheckbox v-model="permission.read" :label="$t('Read')"
                                        :disabled="permission.write || permission.delete" />
                                    <VCheckbox v-model="permission.write" :label="$t('Write')"
                                        @change="() => checkRead(permission)" />
                                    <VCheckbox v-model="permission.delete" :label="$t('Delete')"
                                        @change="() => checkRead(permission)" />
                                </VExpansionPanelText>
                            </VExpansionPanel>
                        </VExpansionPanels>
                    </div>
                </div>
                <div class="d-flex align-center justify-center gap-3 mt-6">
                    <VBtn :loading="isLoading" @click="onSubmit">
                        {{ $t('Save') }}
                    </VBtn>
                    <VBtn color="secondary" variant="tonal" @click="onReset">
                        {{ $t('Reset') }}
                    </VBtn>
                </div>
            </VForm>
        </VCardText>
    </AppCardActions>
</template>

<style lang="scss" scoped>
.permissions-list {
    max-height: 60vh;
    overflow-y: auto;
}

.v-table {
    font-size: 15px;

    tr:nth-child(even) {
        background: rgba(var(--v-theme-on-surface), 2%)
    }
}

.v-label {
    font-size: 15px !important;
}
</style>