<script setup>
import { useRouter } from 'vue-router';
import authV1BottomShape from '@images/svg/auth-v1-bottom-shape.svg?raw';
import authV1TopShape from '@images/svg/auth-v1-top-shape.svg?raw';
import { VNodeRenderer } from '@layouts/components/VNodeRenderer';
import { themeConfig } from '@themeConfig'
import doctorAvatar from '@images/avatars/doctor.png';
import patientAvatar from '@images/avatars/patient.png';

definePage({
    meta: {
        isRoute: true,
        layout: 'blank',
        unauthenticatedOnly: true,
    },
})

const router = useRouter();

const selectRole = (role) => {
    router.push({ name: `register-${role}` });
};
</script>

<template>
    <VAppBar>
        <template v-slot:prepend>
            <router-link :to="'/'" class="d-flex align-center">
                <VNodeRenderer :nodes="themeConfig.app.logo" />
                <VToolbarTitle class="text-h4 ml-2">
                    <span class="text-capitalize font-weight-bold">{{ themeConfig.app.title }}</span>
                </VToolbarTitle>
            </router-link>
        </template>
    </VAppBar>

    <div class="auth-wrapper d-flex align-center justify-center">
        <VCard width="550" max-width="100%" class="auth-card">
            <VCardTitle class="text-center py-4">
                {{ $t('Choose Your Role') }}
            </VCardTitle>
            <VDivider />
            <VCardText>
                <VRow justify="center">
                    <VCol cols="12" sm="6">
                        <VCard @click="selectRole('doctor')" class="role-card h-100" elevation="0" border>
                            <VImg :src="doctorAvatar" height="200" class="role-avatar" contain />
                            <VCardTitle class="d-flex text-h5 justify-center">
                                {{ $t('Doctor') }}
                            </VCardTitle>
                            <VCardText class="text-center">
                                {{ $t('Sign up as a medical professional') }}
                            </VCardText>
                        </VCard>
                    </VCol>
                    <VCol cols="12" sm="6">
                        <VCard @click="selectRole('patient')" class="role-card h-100" elevation="0" border>
                            <VImg :src="patientAvatar" height="200" class="role-avatar" contain />
                            <VCardTitle class="d-flex text-h5 justify-center">
                                {{ $t('Patient') }}
                            </VCardTitle>
                            <VCardText class="text-center">
                                {{ $t('Sign up as a patient') }}
                            </VCardText>
                        </VCard>
                    </VCol>
                </VRow>
            </VCardText>
            <VRow>
                <VCol cols="12">
                    <RouterLink class="d-flex align-center justify-center pb-4" :to="{ name: 'login' }">
                        <VIcon icon="tabler-chevron-left" class="flip-in-rtl" />
                        <span>{{ $t('Back to login') }}</span>
                    </RouterLink>
                </VCol>
            </VRow>
        </VCard>
    </div>
</template>

<style lang="scss">
@use "@core-scss/template/pages/page-auth.scss";

.auth-wrapper {
    flex-grow: 1;
    padding: 1rem;
}

.auth-card {
    width: 550px;
    margin-top: 100px;
}

.role-card {
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
        background-color: rgba(var(--v-theme-primary), 0.05);
        transform: translateY(-5px);
        box-shadow: 0 4px 25px 0 rgba(var(--v-theme-on-surface), 0.1);
    }
}

.role-avatar {
    object-fit: contain;
    background-color: #f5f5f5;
}

@media (max-width: 599.98px) {
    .auth-card {
        width: 100% !important;
    }

    .v-col {
        padding: 6px 12px !important;
    }
}
</style>