<script setup>
import { VideoPlayer } from "@videojs-player/vue";
import "video.js/dist/video-js.css";
import { onMounted } from "vue";
import { useTutorial } from "@/composables/Central/useTutorial";

definePage({
    meta: {
        isRoute: true,
        navActiveLink: "documentation",
    },
});

const { tutorialData, fetchTutorialByCode } = useTutorial();

const route = useRoute();

onMounted(() => {
    fetchTutorialByCode(route.params.code);
});
</script>

<template>
    <VCard v-if="tutorialData" class="overflow-visible course-details">
        <VCardText>
            <VRow>
                <VCol cols="12">
                    <VCardItem class="pa-0 mb-2">
                        <VCardTitle class="mb-2">
                            {{ tutorialData.title }}
                        </VCardTitle>
                        <template #append>
                            <div
                                class="d-flex gap-2 align-center text-capitalize"
                            >
                                <VChip variant="tonal" color="error" label>
                                    {{ tutorialData.tag }}
                                </VChip>
                            </div>
                        </template>
                    </VCardItem>

                    <VCard flat border>
                        <div class="video-container px-2 pt-2">
                            <VideoPlayer
                                :src="`/api/video/${tutorialData.video}`"
                                :poster="tutorialData.thumbnail"
                                controls
                                plays-inline
                                :height="$vuetify.display.mdAndUp ? 440 : 250"
                                class="w-100 rounded"
                            />
                        </div>
                        <VCardText>
                            <VDivider class="my-6" />
                            <h5 class="text-h5 mb-3">
                                {{ $t("Description") }}
                            </h5>
                            <p>{{ tutorialData.description }}</p>
                        </VCardText>
                    </VCard>
                </VCol>
            </VRow>
        </VCardText>
    </VCard>
</template>

<style lang="scss" scoped>
.card-list {
    --v-card-list-gap: 1rem;
}

.course-content {
    position: sticky;
    inset-block-start: 5.25rem;
}
</style>

<style lang="scss">
.video-container {
    display: flex;
    justify-content: center;
    max-width: 777px;
    margin: auto;
}
.v-expansion-panel-text__wrapper {
    padding-block: 0.75rem !important;
    padding-inline: 0.5rem !important;
}

.v-expansion-panel--active {
    .v-expansion-panel-title {
        border-block-end: 1px solid rgba(var(--v-theme-on-surface), 0.12) !important;
    }
}
</style>

<style lang="scss">
body .v-layout .v-application__wrap {
    .course-details {
        .v-expansion-panels {
            .v-expansion-panel-title,
            .v-expansion-panel-title--active,
            .v-expansion-panel-title:hover,
            .v-expansion-panel-title:focus,
            .v-expansion-panel-title:focus-visible,
            .v-expansion-panel-title--active:focus,
            .v-expansion-panel-title--active:hover {
                .v-expansion-panel-title__overlay {
                    opacity: 0.04 !important;
                }
            }
        }
    }
}
</style>
