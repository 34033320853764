import { useAuthentication } from "@/@core/composable/useAuthentication";
import { useInitializationStore } from "@/stores/useInitializationStore";
import { useAccessControl } from "@/composables/useAccessControl";
import NProgress from "nprogress";

export const setupGuards = (router) => {
    router.beforeEach(async (to, from, next) => {
        const { isAuthenticated } = useAuthentication();
        const initializationStore = useInitializationStore();
        const { canAccess } = useAccessControl();

        NProgress.start();

        try {
            if (!initializationStore.isInitialized) {
                await initializationStore.initialize();
            }

            if (to.name === "error" || to.name === "login") {
                return next();
            }

            if (!to.meta.isRoute) {
                if (process.env.NODE_ENV === "production") {
                    return next({ name: "not-found" });
                }
            }

            if (to.meta.unauthenticatedOnly) {
                if (isAuthenticated) {
                    return next({ name: "dashboard" });
                }
                return next();
            }

            if (to.meta.public) {
                return next();
            } else {
                if (!isAuthenticated) {
                    return next({
                        name: "login",
                        query: {
                            redirect:
                                to.fullPath !== "/" ? to.fullPath : undefined,
                        },
                    });
                }
            }

            if (to.meta.adminOnly && !canAccess.byAdminStatus()) {
                console.log("ERROR 1");
                return next({ name: "not-authorized" });
            }

            if (to.meta.ownerOnly && !canAccess.byOwnerStatus()) {
                console.log("ERROR 2");
                return next({ name: "not-authorized" });
            }

            if (
                to.meta.permission &&
                !canAccess.byPermission(to.meta.permission)
            ) {
                console.log("ERROR 3");
                return next({ name: "not-authorized" });
            }

            if (to.meta.feature && !canAccess.byFeature(to.meta.feature)) {
                return next({ name: "error" });
            }

            if (to.meta.userTypes && !canAccess.byType(to.meta.userTypes)) {
                console.log("ERROR 4");
                return next({ name: "not-authorized" });
            }

            next();
        } catch (error) {
            console.error("Router guard error:", error);
            next({ name: "error" });
        } finally {
            NProgress.done();
        }
    });

    router.afterEach(() => {
        NProgress.done();
    });

    router.onError((error) => {
        console.error("Router error:", error);
        NProgress.done();
        // router.push({ name: "error", replace: true }).catch(() => {});
    });
};
