import { defineStore } from "pinia";
import axios from "axios";
import { encryptedStorage } from "../@core/composable/encryptedStorage";

export const useUserStore = defineStore("user", {
    state: () => ({
        id: null,
        medsyn_id: null,
        tenantId: null,
        type: "",
        role: "",
        avatar: "",
        firstName: "",
        lastName: "",
        email: "",
        emailVerifiedAt: null,
        isReady: false,
        refreshInterval: 5 * 60 * 1000,
        lastRefreshTime: null,
    }),

    getters: {
        fullName: (state) => state.firstName + " " + state.lastName,
        user: (state) => ({
            id: state.id,
            type: state.type,
            avatar: state.avatar,
            first_name: state.firstName,
            last_name: state.lastName,
            email: state.email,
        }),
    },

    actions: {
        async initialize() {
            try {
                await this.fetch();
                this.isReady = true;
            } catch (error) {
                console.error("Error initializing user data:", error);
                this.isReady = false;
                throw error;
            }
        },

        async fetch() {
            try {
                const response = await axios.get("/api/user");
                const userData = response.data.user;
                this.set(userData);
                this.lastRefreshTime = Date.now();
            } catch (error) {
                console.error("Failed to fetch user data:", error);
                throw error;
            }
        },

        set(user) {
            this.id = user.id ?? this.id;
            this.medsyn_id = user.medsyn_id ?? this.medsyn_id;
            this.tenantId = user.tenant_id ?? this.tenantId;
            this.type = user.type ?? this.type;
            this.role = user.role ?? this.role;
            this.avatar = user.avatar ?? this.avatar;
            this.firstName = user.first_name ?? this.firstName;
            this.lastName = user.last_name ?? this.lastName;
            this.email = user.email ?? this.email;
            this.emailVerifiedAt =
                user.email_verified_at ?? this.emailVerifiedAt;
        },

        reset() {
            this.$reset();
            localStorage.removeItem("user");
        },
    },

    persist: {
        storage: encryptedStorage,
    },
});