<script setup>
import Form from "@/pages/admin/databases/components/DatabaseForm.vue";
import { useRoute } from "vue-router";

const route = useRoute();

definePage({
    meta: {
        isRoute: true,
        adminOnly: true,
        userTypes: ['admin'],
        navActiveLink: "admin-databases",
    },
});
</script>

<template>
    <Form :id="Number(route.params.id)" />
</template>