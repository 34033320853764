<script setup>
import authV1BottomShape from '@images/svg/auth-v1-bottom-shape.svg?raw'
import authV1TopShape from '@images/svg/auth-v1-top-shape.svg?raw'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'
import { useVerify } from "@/composables/Auth/useVerify";
import { useUserStore } from '@/stores/useUserStore';
import { h } from 'vue';
import { useRoute } from 'vue-router';

definePage({
  meta: {
    isRoute: true,
    layout: 'blank',
  }
})

const route = useRoute();
const userStore = useUserStore();
const { sendVerification, verifyLoading, snackBar } = useVerify();

</script>

<template>
  <div class="auth-wrapper d-flex align-center justify-center pa-4">
    <div class="position-relative my-sm-16">
      <VNodeRenderer :nodes="h('div', { innerHTML: authV1TopShape })"
        class="text-primary auth-v1-top-shape d-none d-sm-block" />

      <VNodeRenderer :nodes="h('div', { innerHTML: authV1BottomShape })"
        class="text-primary auth-v1-bottom-shape d-none d-sm-block" />

      <VCard class="auth-card pa-4" max-width="448">
        <VCardText>
          <VNodeRenderer :nodes="themeConfig.app.logo" class="text-center" />
          <h4 class="text-h4 mb-1 text-center mb-5">
            <span class="text-capitalize"> {{ themeConfig.app.title }} </span>
          </h4>
          <h4 class="text-h4 mb-1 text-center">
            ✉️
          </h4>
          <h5 class="text-h5 mb-1 text-center">
            <span> {{ $t('Email Verification') }} </span>
          </h5>
        </VCardText>

        <VCardText class="pt-2 px-10">
          <p>
            {{ $t('Account activation link sent to your email address') }}:
          </p>
          <p class="text-center font-weight-bold">
            {{ userStore.email }}
          </p>

          <VBtn block :to="route.query.redirect || '/dashboard'" class="mb-6">
            {{ $t('Skip for now') }}
          </VBtn>

          <div class="d-flex align-center justify-center">
            <span class="me-1">{{ $t('Didn\'t get the mail') }}? </span>
            <a v-show="!verifyLoading" @click="sendVerification" href="#">{{ $t('Resend') }}</a>
            <VProgressCircular v-show="verifyLoading" :size="20" width="3" color="primary" indeterminate class="ml-2" />
          </div>
        </VCardText>
      </VCard>
    </div>
  </div>
  <VSnackbar v-model="snackBar.visible" :color="snackBar.color">
    <div class="text-center">{{ $t(snackBar.message) }}.</div>
  </VSnackbar>
</template>

<style lang="scss">
@use "@core-scss/template/pages/page-auth.scss";
</style>
