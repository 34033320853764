import { defineStore } from "pinia";
import axios from "axios";
import slugify from "slugify";
import { useUserStore } from "./useUserStore";
import { encryptedStorage } from "../@core/composable/encryptedStorage";
import { useCookie } from "@/@core/composable/useCookie";

export const usePermissionsStore = defineStore("permissions", {
    state: () => ({
        type: null,
        isReady: false,
        permissions: [],
        refreshInterval: 15 * 60 * 1000,
        lastRefreshTime: null,
    }),

    getters: {
        isUser: () => useUserStore().type === "user",
        isOwner: () => useUserStore().type === "doctor",
        isPatient: () => useUserStore().type === "patient",
        isAdmin: () => useUserStore().type === "admin",
    },

    actions: {
        async initialize() {
            try {
                await this.fetch();
                this.initializeSocket();
            } catch (error) {
                console.error("Error initializing permissions:", error);
                this.isReady = false;
                throw error;
            }
        },

         async fetch() {
            try {
                const response = await axios.get("/api/permissions");
                this.type = response.data.type;
                this.permissions = response.data.permissions;
                if (this.type !== 'admin' && this.permissions.length === 0) {
                    throw new Error("Permissions are empty");
                }
                this.lastRefreshTime = Date.now();
                this.$patch({ isReady: true });
            } catch (error) {
                console.error("Error fetching permissions:", error);
                throw error;
            }
        },

        initializeSocket() {
            const userStore = useUserStore();
            if (this.isAdmin) {
                return;
            }

            if (userStore.type === "user" && userStore.role) {
                this.subscribeToRoleChannel(userStore.role);
            }

            if (userStore.id) {
                this.subscribeToUserChannel(userStore.id);
            }
        },

        subscribeToRoleChannel(role) {
            const channelName = `roles.${slugify(role, {
                lower: true,
                remove: /[*+~.()'"!:@]/g,
            })}`;
            // Commented out as per original code
            // window.Echo.private(channelName).listen(".role.updated", (e) => {
            //     this.fetch();
            // });
        },

        subscribeToUserChannel(userId) {
            const userStore = useUserStore();

            // Commented out as per original code
            // window.Echo.private(`user.role.updated.${userId}`).listen(
            //     ".user.role.updated",
            //     async (e) => {
            //         await this.fetch();
            //         userStore.role = e.newRole;
            //         this.resetSocket();
            //         this.initializeSocket();
            //     }
            // );

            // window.Echo.private(`user.${userId}`).listen(
            //     ".user.deleted",
            //     () => {
            //         this.handleUserDeletion();
            //     }
            // );
        },

        handleUserDeletion() {
            useCookie("accessToken").value = null;
            useCookie("shortcuts").value = null;
            const userStore = useUserStore();
            userStore.reset();
            this.reset();
        },

        resetSocket() {
            const userStore = useUserStore();
            if (userStore.type === "user" && userStore.role) {
                const roleChannelName = `roles.${slugify(userStore.role, {
                    lower: true,
                    remove: /[*+~.()'"!:@]/g,
                })}`;
                // Commented out as per original code
                // window.Echo.leave(roleChannelName);
            }
            if (userStore.id) {
                // Commented out as per original code
                // window.Echo.leave(`user.role.updated.${userStore.id}`);
                // window.Echo.leave(`user.${userStore.id}`);
            }
        },

        hasPermission(permission) {
            return (
                this.isAdmin ||
                this.permissions.includes("manage_everything") ||
                this.permissions.includes(permission)
            );
        },

        reset() {
            this.resetSocket();
            this.$reset();
            localStorage.removeItem("permissions");
        },
    },

    persist: {
        storage: encryptedStorage,
    },
});