<script lang="ts" setup>
import GlobalNotification, {
  type GlobalNotificationProps,
} from "@/@core/components/notifications/GlobalNotification.vue";

import UploadNotification, {
  type UploadNotificationProps,
} from "@/@core/components/notifications/UploadNotification.vue";

import SimpleNotification, {
    type SimpleNotificationProps,
} from "@/@core/components/notifications/SimpleNotification.vue";

import {
  Notivue,
  NotivueItem,
  Notification,
  NotivueSwipe,
  slateTheme,
  lightTheme,
  NotificationProgress,
  filledIcons,
} from "notivue";
import { useNotificationsStore } from "@/stores/useNotificationsStore";
import "@styles/notivue-custom.css";

const notificationsStore = useNotificationsStore();
</script>
<template>
  <Notivue v-slot="item">
    <NotivueSwipe :item="item">
      <Notification
        v-if="!Object.keys(item.props).length"
        :key="$vuetify.theme.name"
        :item="item"
        :icons="filledIcons"
        :theme="$vuetify.theme.name === 'light' ? lightTheme : slateTheme"
        :class="[
          'notivue-notification',
          `notification-${item.type}`,
          { 'has-progress-bar': notificationsStore.progressBar },
        ]"
        activeClass="is-active"
        leaveClass="is-leaving"
        aria-live="polite"
        role="status"
        tabindex="0"
        @click="item.type !== 'promise' && item.clear()"
      >
        <NotificationProgress v-if="notificationsStore.progressBar" :item="item" />
      </Notification>

      <GlobalNotification
        v-if="item.props.isGlobalNotifiation"
        :item="item as NotivueItem<GlobalNotificationProps>"
      />

      <UploadNotification
        v-if="item.props.isUploadNotifiation"
        :item="item as NotivueItem<UploadNotificationProps>"
      />

      <SimpleNotification
        v-if="item.props.isSimpleNotification"
        :item="item as NotivueItem<SimpleNotificationProps>"
      />
    </NotivueSwipe>
  </Notivue>
</template>
