<script setup>
import { usePatient } from "@/composables/Tenant/usePatient";
import { useUser } from "@/composables/Central/useUser";
import { usePdf } from "@/composables/Tenant/usePdf";
import { useConsultation } from "@/composables/Tenant/useConsultation";
import { useIntervention } from "@/composables/Tenant/useIntervention";
import { useConsent } from "@/composables/Tenant/useConsent";
import { usePrinting } from "@/composables/Tenant/usePrinting";
import PreoperativeAssessment from '@/pages/patients/components/Printables/PreoperativeAssessment.vue';
import AdmissionLetter from '@/pages/patients/components/Printables/AdmissionLetter.vue';
import ConfidentialLetter from '@/pages/patients/components/Printables/ConfidentialLetter.vue';
import DoctorLetter from '@/pages/patients/components/Printables/DoctorLetter.vue';
import MedicalCertificate from '@/pages/patients/components/Printables/MedicalCertificate.vue';
import Prescription from '@/pages/patients/components/Printables/Prescription.vue';
import HospitalReport from '@/pages/patients/components/Printables/HospitalReport.vue';
import OperativeReport from '@/pages/patients/components/Printables/OperativeReport.vue';
import ConsentForm from '@/pages/patients/components/Printables/ConsentForm.vue';
import Transfert from '@/pages/patients/components/Transfert.vue';
import { usePrintStore } from '@/stores/usePrintStore';
import { useRouter, useRoute } from "vue-router";
import { computed, ref, onMounted } from "vue";
import { useStatic } from '@/composables/useStatic';

definePage({
  meta: {
    isRoute: true,
    navActiveLink: 'patients',
    userTypes: ['doctor', 'user'],
    permission: 'read_patient',
  },
});

const router = useRouter();
const route = useRoute();
const printStore = usePrintStore();

const { owner, fetchOwner } = useUser();
const { patientData, fetchPatient } = usePatient();
const { fetchConsultation } = useConsultation();
const { fetchIntervention } = useIntervention();
const { consentData, fetchConsent } = useConsent();
const { form, fetchPrinting } = usePrinting();
const { downloadPDF, downloadLoading, transfertPDF, transferLoading, isTransferredSnackbarVisible, transferSnackBarContent, transferSnackBarColor } = usePdf();

const componentMap = {
  PreoperativeAssessment,
  AdmissionLetter,
  ConfidentialLetter,
  DoctorLetter,
  MedicalCertificate,
  Prescription,
  HospitalReport,
  OperativeReport,
  ConsentForm,
};

const currentComponent = computed(() => {
  return componentMap[requestData.value.action] || null;
});

const patientId = route.params.id;
const isTransfertSidebarVisible = ref(false);
const fetchingAdmin = ref(true);
const fetchingPatient = ref(true);
const fetchingConsultation = ref(true);
const fetchingIntervention = ref(true);
const fetchingConsent = ref(true);
const fetchingPrinting = ref(false);

const consultationData = ref(null);
const interventionData = ref(null);

const requestData = ref({
  action: '',
  patientId: patientId,
  consultationId: '',
  interventionIdId: '',
  date: '',
  time: '',
  parent: '',
  days: '',
  consultation: '',
  intervention: '',
  medications: '',
  consentId: '',
  father: '',
  mother: '',
  imagesIds: '',
});

const { getUrl } = useStatic();

const initRequestData = () => {
  if (printStore.printData) {
    Object.assign(requestData.value, printStore.printData);
    // printStore.clearPrintData();
  }
};

const setAdmin = async () => {
  await fetchOwner().then(() => {
    fetchingAdmin.value = false;
  });
};

const setPatient = async () => {
  if (patientId) {
    await fetchPatient(patientId).then(() => {
      fetchingPatient.value = false;
    });
  } else {
    fetchingPatient.value = false;
  }
};

const setConsultation = async () => {
  if (requestData.value.consultationId) {
    try {
      const response = await fetchConsultation(requestData.value.consultationId);
      consultationData.value = response;

    } catch (error) {
      console.error("Error fetching consultation data:", error);
    } finally {
      fetchingConsultation.value = false;
    }
  } else {
    fetchingConsultation.value = false;
  }
};

const setIntervention = async () => {
  if (requestData.value.interventionId) {
    try {
      const response = await fetchIntervention(requestData.value.interventionId);
      interventionData.value = response;
    } catch (error) {
      console.error("Error fetching intervention data:", error);
    } finally {
      fetchingIntervention.value = false;
    }
  } else {
    fetchingIntervention.value = false;
  }
};

const setConsent = async () => {
  if (requestData.value.consentId) {
    try {
      await fetchConsent(requestData.value.consentId);
    } catch (error) {
      console.error("Error fetching consent data:", error);
    } finally {
      fetchingConsent.value = false;
    }
  } else {
    fetchingConsent.value = false;
  }
};

const setPrinting = async () => {
  await fetchPrinting().then(() => {
    fetchingPrinting.value = false;
  });
}

const handleSubmission = (mailData) => {
  requestData.value.emailTo = mailData.emailTo;
  requestData.value.emailSubject = mailData.emailSubject;
  requestData.value.emailContent = mailData.emailContent;
  transfertPDF(requestData.value);
}

const printInvoice = () => {
  window.print()
}

const printData = computed(() => ({
  admin: owner,
  patientData: patientData,
  consultationData: consultationData,
  interventionData: interventionData,
  consentData: consentData,
  ...requestData.value
}));

onMounted(() => {
  if (!printStore.printData) {
    router.push({ name: 'patients-id', params: { id: route.params.id } });
  } else {
    initRequestData();
    setAdmin();
    setPatient();
    setConsultation();
    setIntervention();
    setConsent();
    setPrinting();
  }
});
</script>

<template>
  <section>
    <VRow class="justify-space-between justify-center-sm back-btn">
      <VCol cols="12" class="d-flex justify-start mt-2 mt-sm-0">
        <VBtn prepend-icon="tabler-arrow-left" color="secondary" class="mb-2"
          :to="{ name: 'patients-id', params: { id: patientId } }">
          {{ $t('Back') }}
        </VBtn>
      </VCol>
    </VRow>
    <VRow class="d-flex justify-center">
      <VCol cols="12" md="8" sm="12">
        <VCard
          v-if="!fetchingAdmin && !fetchingPatient && !fetchingConsultation && !fetchingIntervention && !fetchingConsent"
          class="pt-0">
          <VRow align-content="center">
            <VCol cols="5" align-self="center" class="d-flex justify-start">
              <span v-html="form.header_left" class="ml-2" />
            </VCol>
            <VCol cols="2" align-self="center">
              <VImg v-if="form.header_image" :max-height="100" :src="getUrl(form.header_image)" contain />
            </VCol>
            <VCol cols="5" align-self="center" class="d-flex justify-end">
              <span v-html="form.header_right" class="mr-2" />
            </VCol>
          </VRow>
          <VDivider />
          <VCardText class="text-end">Tunis le {{ new Date().toLocaleDateString('en-GB') }}</VCardText>

          <div class="component-container" :class="requestData.action">
            <div class="side-column"></div>
            <div class="center-column">
              <component :is="currentComponent" :printData="printData"></component>
            </div>
            <div class="side-column"></div>
          </div>

          <div class="d-flex justify-space-between my-6">
            <div class="d-flex gap-5">
              <VImg v-if="requestData.signature && form.signature" :max-height="60" :width="200"
                :src="getUrl(form.signature)">
              </VImg>
              <VImg v-if="requestData.stamp && form.stamp" :max-height="60" :width="200" :src="getUrl(form.stamp)">
              </VImg>
            </div>
            <VCardText v-if="!fetchingAdmin" class="text-end">Dr {{ owner.first_name }} {{ owner.last_name }}
            </VCardText>
          </div>

        </VCard>
        <VSkeletonLoader v-else loading-text="Loading" type="article" :height="650" />
      </VCol>

      <VCol md="4" sm="12" class="d-print-none">
        <VCard>
          <VCardText>
            <VBtn :loading="transferLoading" :disabled="transferLoading" block prepend-icon="tabler-send" class="mb-2"
              @click="isTransfertSidebarVisible = true">
              {{ $t('Transfer') }}
            </VBtn>

            <VBtn :loading="downloadLoading" :disabled="downloadLoading" @click="downloadPDF(requestData)"
              color="success" block prepend-icon="tabler-download" class="mb-2">
              {{ $t('Download') }}
            </VBtn>

            <VBtn block prepend-icon="tabler-printer" color="info" class="mb-2" @click="printInvoice">
              {{ $t('Print') }}
            </VBtn>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
    <Transfert v-model:isDrawerOpen="isTransfertSidebarVisible" @submit="handleSubmission" />
  </section>
  <VSnackbar v-model="isTransferredSnackbarVisible" :color="transferSnackBarColor">
    {{ $t(transferSnackBarContent) }}
  </VSnackbar>
</template>

<style lang="scss">
.custom-vcard-text table {
  border-collapse: collapse;
  border-spacing: 0;
}

.custom-vcard-text table,
.custom-vcard-text th,
.custom-vcard-text td {
  border: 0 !important;
  padding: 0;
}

.invoice-preview-table {
  --v-table-row-height: 44px !important;
}

.component-container {
  display: flex;
  width: 100%;
}

.side-column {
  width: var(--side-column-width, 5%);
}

.center-column {
  width: var(--center-column-width, 90%);
}

.AdmissionLetter {
  --side-column-width: 30%;
  --center-column-width: 70%;
}

.ConfidentialLetter {
  --side-column-width: 10%;
  --center-column-width: 80%;
}

.PreoperativeAssessment {
  --side-column-width: 10%;
  --center-column-width: 80%;
}

.Prescription {
  --side-column-width: 20%;
  --center-column-width: 60%;
}

.OperativeReport {
  --side-column-width: 5%;
  --center-column-width: 95%;
}

.DoctorLetter {
  --side-column-width: 10%;
  --center-column-width: 80%;
}

.MedicalCertificate {
  --side-column-width: 10%;
  --center-column-width: 80%;
}

.HospitalReport {
  --side-column-width: 10%;
  --center-column-width: 80%;
}

.ConsentForm {
  --side-column-width: 10%;
  --center-column-width: 80%;
}

@media print {
  .v-theme--dark {
    --v-theme-surface: 255, 255, 255;
    --v-theme-on-surface: 94, 86, 105;
  }

  body {
    background: none !important;
  }

  @page {
    margin: 0;
    size: auto;
  }

  .layout-page-content,
  .v-row,
  .v-col-md-9 {
    padding: 0;
    margin: 0;
  }

  .product-buy-now {
    display: none;
  }

  .v-navigation-drawer,
  .layout-vertical-nav,
  .app-customizer-toggler,
  .layout-footer,
  .layout-navbar,
  .layout-navbar-and-nav-container {
    display: none;
  }

  .v-card {
    box-shadow: none !important;

    .print-row {
      flex-direction: row !important;
    }
  }

  .layout-content-wrapper {
    padding-inline-start: 0 !important;
  }

  .v-table__wrapper {
    overflow: hidden !important;
  }

  .back-btn {
    display: none;
  }
}
</style>