<script setup>
import { onMounted, watch } from 'vue';
import { useActivity } from "@/composables/Tenant/useActivity";

definePage({
    meta: {
        isRoute: true,
        ownerOnly: true,
        userTypes: ['doctor']
    },
});

const { getActivity, activities, currentPage, totalPages, activityLoading } = useActivity();

const resolveIcon = model => {
    if (model === 'Patient')
        return 'fluent-patient-20-filled';
    else if (model === 'Consultation')
        return 'medical-icon-i-inpatient';
    else if (model === 'Intervention')
        return 'medical-icon-i-surgery';
    else if (model === 'Control')
        return 'medical-icon-i-intensive-care';
    else if (model === 'Doctor')
        return 'tabler-nurse';
    else if (model === 'Medication')
        return 'tabler-vaccine-bottle';
    else if (model === 'Diagnostic')
        return 'tabler-heart-rate-monitor';
    else if (model === 'Technique')
        return 'tabler-emergency-bed';
    else if (model === 'Consent')
        return 'tabler-license';
    else if (model === 'Appointment')
        return 'tabler-calendar-clock';
    else if (model === 'User')
        return 'tabler-users-group';
    else if (model === 'Service')
        return 'tabler-first-aid-kit';
    else if (model === 'Bill')
        return 'tabler-file-invoice';
    else if (model === 'Role')
        return 'tabler-user-check';
}

const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'error'];

const generateColor = () => {
    const randomIndex = Math.floor(Math.random() * colors.length)
    return colors[randomIndex]
}

onMounted(() => {
    getActivity(currentPage);
});

watch(currentPage, () => {
    getActivity();
});

</script>

<template>
    <VRow>
        <VCol cols="12">
            <VCard flat variant="text" :title="$t('Activity')">
                <VCardText>
                    <div v-if="activityLoading" class="d-flex justify-center">
                        <VProgressCircular color="primary" indeterminate>
                        </VProgressCircular>
                    </div>
                    <div v-else>
                        <VTimeline v-if="activities && activities.length" align="start" justify="center"
                            truncate-line="both" :density="$vuetify.display.smAndDown ? 'compact' : 'default'"
                            class="v-timeline-icon-only">
                            <VTimelineItem v-for="(activity, index) in activities" :key="index"
                                :dot-color="generateColor()" size="x-small">
                                <VCard>
                                    <VCardText>
                                        <div class="d-flex justify-space-between align-center mb-1">
                                            <span class="app-timeline-title">
                                                {{ activity.causer }}
                                                <span class="timeline-description mb-0">
                                                    {{ $t(activity.description) }}.
                                                </span>
                                            </span>
                                            <div
                                                class="d-flex flex-column justify-center text-center app-timeline-meta">
                                                <p class="mb-1 my-0">
                                                    {{ activity.date }}
                                                </p>
                                                <p class="my-0">
                                                    {{ activity.time }}
                                                </p>
                                            </div>
                                        </div>

                                        <VDivider class="my-4" />

                                        <div class="d-flex justify-space-between align-center flex-wrap gap-4">
                                            <span class="d-flex align-items-center">
                                                <VAvatar color="primary" :icon="resolveIcon(activity.model)"
                                                    class="me-3" />
                                                <div class="d-flex flex-column justify-center">
                                                    <h6 class="model-name font-weight-medium m-0">{{
                                                        activity.instance_name
                                                        }}
                                                    </h6>
                                                </div>
                                            </span>
                                            <div>
                                                <IconBtn v-if="activity.edit_link" :to="activity.edit_link">
                                                    <VIcon icon="tabler-edit" size="20" />
                                                </IconBtn>
                                                <IconBtn>
                                                    <VIcon icon="tabler-alert-circle" size="20" />
                                                </IconBtn>
                                            </div>
                                        </div>
                                    </VCardText>
                                </VCard>
                            </VTimelineItem>
                        </VTimeline>
                        <VEmptyState v-else icon="tabler-history-off" :title="$t('No Activity Yet')"
                            :text="$t('There are no activities to display at the moment. New activities will appear here as they occur.')"
                            :size="48">
                            <template v-slot:actions>
                                <v-btn color="primary" @click="getActivity(1)">
                                    {{ $t('Refresh') }}
                                </v-btn>
                            </template>
                        </VEmptyState>
                    </div>
                </VCardText>
            </VCard>
        </VCol>
        <VCol v-if="activities && activities.length" class="pt-10 d-flex align-center justify-center">
            <VPagination class="w-50" v-model="currentPage" :length="totalPages" />
        </VCol>
    </VRow>
</template>

<style scoped>
.timeline-description {
    font-size: 15px;
    line-height: 1.25rem;
    color: gray
}

.model-name {
    font-size: 15px;
}
</style>