<script setup>
import { usePrinting } from "@/composables/Tenant/usePrinting";
import { useShortcut } from "@/composables/Tenant/useShortcut";
import Printing from '@/pages/settings/components/Printing.vue'
import Shortcut from '@/pages/settings/components/Shortcut.vue';
import Menu from '@/pages/settings/components/Menu.vue'
import Theme from '@/pages/settings/components/Theme.vue';
import { onMounted, watch } from "vue";
import { useRoute } from 'vue-router';
import { computed } from 'vue';

definePage({
  meta: {
    isRoute: true,
    ownerOnly: true,
  },
});

const route = useRoute();

const activeTab = computed({
  get: () => route.params.tab,
  set: () => route.params.tab,
})

const tabs = [
  {
    title: 'Shortcuts',
    icon: 'tabler-layout-grid-add',
    tab: 'shortcuts',
  },
  {
    title: 'Menu',
    icon: 'tabler-menu-2',
    tab: 'menu',
  },
  {
    title: 'Printing',
    icon: 'tabler-printer',
    tab: 'printing',
  },
  {
    title: 'Theme',
    icon: 'tabler-brush',
    tab: 'theme',
  },
];

const { form, errors, fetchPrinting, savePrinting, printingUpdated, printingLoading, fetchingPrinting } = usePrinting();
const { shortcuts, fetchShortcuts, updateShortcuts, shortcutsLoading, shortcutsUpdated } = useShortcut();

onMounted(async () => {
  await fetchPrinting();
  await fetchShortcuts();
});

const onSubmitPrinting = async () => {
  await savePrinting();
}

const onSubmitShortcuts = async (selectedShortcuts) => {
  shortcuts.value = selectedShortcuts;
  await updateShortcuts();
}

watch(() => form.value.signature_label, (newValue) => {
  if (!newValue) {
    form.value.signature = null;
  }
});

watch(() => form.value.stamp_label, (newValue) => {
  if (!newValue) {
    form.value.stamp = null;
  }
});

const updateForm = (newForm) => {
  form.value = newForm;
};
</script>

<template>
  <VTabs show-arrows align-tabs="center" grow fixed-tabs v-model="activeTab" class="v-tabs-pill" scrollable>
    <VTab v-for="item in tabs" :key="item.icon" :value="item.tab"
      :to="{ name: 'settings-tab', params: { tab: item.tab } }">
      <VIcon size="20" start :icon="item.icon" />
      {{ $t(item.title) }}
    </VTab>
  </VTabs>

  <VWindow v-model="activeTab" class="mt-6 disable-tab-transition" :touch="false">
    <VWindowItem value="shortcuts">
      <Shortcut v-if="shortcuts" :shortcuts="shortcuts" :isLoading="shortcutsLoading" @submit="onSubmitShortcuts"
        :shortcutsUpdated="shortcutsUpdated" />
      <VSkeletonLoader v-else min-height="500" loading-text="Loading" type="card" class="fill-height" />
    </VWindowItem>

    <VWindowItem value="menu">
      <Menu />
    </VWindowItem>

    <VWindowItem value="printing">
      <Printing v-if="!fetchingPrinting" v-model:form="form" :errors="errors" :isLoading="printingLoading"
        @submit="onSubmitPrinting" :printingUpdated="printingUpdated" />
      <VSkeletonLoader v-else min-height="500" loading-text="Loading" type="card" class="fill-height" />
    </VWindowItem>

    <VWindowItem value="theme">
      <Theme />
    </VWindowItem>
  </VWindow>
</template>