<script setup>
import { useUser } from "@/composables/Central/useUser";
import { useRole } from "@/composables/Tenant/useRole";
import { requiredValidator } from '@core/utils/validators.js';
import { ref, watchEffect } from "vue";

const props = defineProps({
    id: {
        type: Number,
        required: false,
    },
});

definePage({
    meta: {
        adminOnly: true,
        navActiveLink: 'users',
    },
});

const {
    userForm,
    fetchUser,
    saveUser,
    userLoading,
    userErrors,
    errorMessage,
} = useUser();

const {
    fetchRoles
} = useRole();

const onSubmit = async () => {
    if (!refVForm.value) return;
    const { valid: isValid } = await refVForm.value.validate();
    if (isValid) {
        await saveUser();
    }
};

const userId = props.id;

const setUser = async () => {
    if (userId) {
        await fetchUser(userId).then((response) => {
            for (const key in response.data) {
                if (response.data.hasOwnProperty(key) && userForm.hasOwnProperty(key)) {
                    userForm[key] = response.data[key];
                }
            }
            userForm.isLoading = false;
        })
    }
};

watchEffect(setUser);

const refVForm = ref();

const rolesList = ref([]);

fetchRoles().then((response) => {
    rolesList.value = response.data.roles;
});

</script>

<template>
    <VAlert v-if="errorMessage" color="error" variant="tonal" class="mb-2">
        {{ errorMessage }}
    </VAlert>
    <VCard :title="props.id ? $t('Edit User') : $t('Create User')">
        <VCardText>
            <VForm ref="refVForm">
                <VRow>
                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis" for="first_name">
                                    {{ $t('First Name') }}</label>
                            </VCol>

                            <VCol cols="12" md="9">
                                <AppTextField id="first_name" v-model="userForm.first_name" persistent-placeholder
                                    :rules="[requiredValidator]" :error-messages="userErrors.first_name" />
                            </VCol>
                        </VRow>
                    </VCol>

                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis" for="last_name">
                                    {{ $t('Last Name') }}</label>
                            </VCol>

                            <VCol cols="12" md="9">
                                <AppTextField id="last_name" v-model="userForm.last_name" persistent-placeholder
                                    :rules="[requiredValidator]" :error-messages="userErrors.last_name" />
                            </VCol>
                        </VRow>
                    </VCol>

                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis" for="email">{{ $t('Email')
                                    }}</label>
                            </VCol>

                            <VCol cols="12" md="9">
                                <AppTextField id="email" v-model="userForm.email" persistent-placeholder
                                    :rules="[requiredValidator]" :error-messages="userErrors.email" />
                            </VCol>
                        </VRow>
                    </VCol>

                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis" for="phone_number">
                                    {{ $t('Phone Number') }}</label>
                            </VCol>

                            <VCol cols="12" md="9">
                                <AppTextField id="phone_number" v-model="userForm.phone_number" persistent-placeholder
                                    :error-messages="userErrors.phone_number" />
                            </VCol>
                        </VRow>
                    </VCol>
                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis" for="role">
                                    {{ $t('Role') }}</label>
                            </VCol>

                            <VCol cols="12" md="9">
                                <AppSelect id="role" v-model="userForm.role" chips :items="rolesList" item-title="name"
                                    item-value="id" :rules="[requiredValidator]" :error-messages="userErrors.role">
                                </AppSelect>
                            </VCol>
                        </VRow>
                    </VCol>

                    <VCol offset-md="1" cols="12" class="d-flex gap-4 justify-center">
                        <VBtn :key="userLoading" :loading="userLoading" @click="onSubmit">
                            {{ $t('Save') }}
                        </VBtn>
                        <VBtn color="secondary" type="reset" variant="tonal">
                            {{ $t('Reset') }}
                        </VBtn>
                    </VCol>
                </VRow>
            </VForm>
        </VCardText>
    </VCard>
</template>