{
    "2": "2",
    "3": "3",
    "5": "5",
    "10": "10",
    "20": "20",
    "25": "25",
    "50": "50",
    "100": "100",
    "Features": "الميزات",
    "Revolutionize Your Medical Practice with MedSyn": "ثورة في ممارستك الطبية مع MedSyn",
    "heroText": "برنامج شامل، مدعوم بالذكاء الاصطناعي، لإدارة فعالة للمرضى وتحسين الصحة",
    "Demo Request": "طلب العرض التجريبي",
    "Fill out the form below to request a demo of MedSyn.": "قم بملء النموذج أدناه لطلب عرض توضيحي لبرنامج MedSyn.",
    "Submit Request": "إرسال الطلب",
    "Tailored for Your Expertise": "مصمم خصيصًا لخبرتك",
    "Specialized solutions for diverse medical fields": "حلول متخصصة لمجالات طبية متنوعة",
    "Pediatric Surgery": "جراحة الأطفال",
    "Providing specialized care for infants, children, and adolescents with a range of surgical needs.": "تقديم رعاية متخصصة للرضع والأطفال والمراهقين مع مجموعة متنوعة من الاحتياجات الجراحية.",
    "General Surgery": "الجراحة العامة",
    "Comprehensive surgical solutions addressing a broad spectrum of conditions.": "حلول جراحية شاملة لمعالجة مجموعة واسعة من الحالات.",
    "Cosmetic Surgery": "الجراحة التجميلية",
    "Advanced cosmetic procedures to enhance and refine your natural beauty.": "إجراءات تجميلية متقدمة لتعزيز جمالك الطبيعي وإبرازه.",
    "Core Features": "الميزات الرئيسية",
    "Empower Your Practice": "تعزيز ممارستك",
    "Comprehensive tools to streamline your workflow": "أدوات شاملة لتبسيط تدفق عملك",
    "Manage patient records, consultations, interventions, controls, and medical files.": "إدارة سجلات المرضى، الاستشارات، التدخلات، المتابعات، والملفات الطبية.",
    "Efficiently schedule and manage patient appointments with an intuitive interface.": "قم بجدولة وإدارة مواعيد المرضى بكفاءة باستخدام واجهة بديهية.",
    "Document Printing": "طباعة المستندات",
    "Easily create and print all sort of medical documents prefilled with your patient's data.": "قم بإنشاء وطباعة جميع أنواع المستندات الطبية بسهولة مع ملء مسبق ببيانات مرضاك.",
    "Extensive Medical Database": "قاعدة بيانات طبية واسعة",
    "Access and manage an extensive medical database tailored to your specialty.": "الوصول إلى وإدارة قاعدة بيانات طبية واسعة مصممة خصيصًا لتخصصك.",
    "Advanced Statistics": "إحصائيات متقدمة",
    "Gain valuable insights into your practice with detailed analytics and reporting tools.": "احصل على رؤى قيمة حول ممارستك باستخدام أدوات تحليل وتقارير مفصلة.",
    "Integrated Calendar": "تقويم متكامل",
    "Streamline your schedule with a comprehensive calendar system for interventions, consultations, appointments and other tasks.": "قم بتبسيط جدولك الزمني باستخدام نظام تقويم شامل للتدخلات، الاستشارات، المواعيد، والمهام الأخرى.",
    "Efficiently manage multiple users with customizable roles and permissions.": "قم بإدارة العديد من المستخدمين بكفاءة باستخدام أدوار وأذونات قابلة للتخصيص.",
    "Billing Management": "إدارة الفواتير",
    "Simplify your financial operations with integrated billing and invoicing features.": "قم بتبسيط عملياتك المالية باستخدام ميزات الفواتير وإصدار الفواتير المتكاملة.",
    "Intelligent Chatbot": "روبوت دردشة ذكي",
    "Get instant assistance and answers to your queries with our built-in chatbot.": "احصل على مساعدة فورية وإجابات لاستفساراتك باستخدام روبوت الدردشة المدمج.",
    "Technology": "التكنولوجيا",
    "Advancing Medical Practice": "تطوير الممارسة الطبية",
    "AI-driven tools, robust security, and seamless multilingual support": "أدوات مدفوعة بالذكاء الاصطناعي، أمان قوي، ودعم متعدد اللغات بسهولة",
    "AI-Powered": "مدعوم بالذكاء الاصطناعي",
    "Leverage cutting-edge AI for automatic report generation, speech-to-EHR functionality, and intelligent diagnostic suggestions.": "استخدم الذكاء الاصطناعي المتطور لتوليد تقارير تلقائية، تحويل الكلام إلى السجلات الطبية الإلكترونية، واقتراحات تشخيصية ذكية.",
    "Secure & Compliant": "آمن وملتزم بالمعايير",
    "HIPAA-compliant multi-tenant architecture ensures the highest level of data security and privacy for your practice and patients.": "الهندسة المعمارية المتوافقة مع معايير HIPAA تضمن أعلى مستوى من الأمان والخصوصية لبيانات ممارستك ومرضاك.",
    "Multilingual Support": "دعم متعدد اللغات",
    "Seamlessly switch between Arabic, French, and English to serve diverse patient populations and expand your practice reach.": "قم بالتبديل بسهولة بين العربية، الفرنسية، والإنجليزية لخدمة مجموعة متنوعة من المرضى وتوسيع نطاق ممارستك.",
    "Reviews": "المراجعات",
    "Trusted by Healthcare Professionals": "موثوق به من قبل المحترفين الصحيين",
    "What our users say about MedSyn Solutions": "ماذا يقول مستخدمونا عن حلول MedSyn",
    "Choose Your Plan": "اختر خطتك",
    "Flexible options to suit your practice needs": "خيارات مرنة لتلبية احتياجات ممارستك",
    "Pay Monthly": "الدفع شهريًا",
    "Pay Annual": "الدفع سنويًا",
    "Advanced": "متقدم",
    "Premium": "متميز",
    "Email Address": "البريد الإلكتروني",
    "Up to 500 patients annually": "حتى 500 مريض سنويًا",
    "Up to 5GB Storage": "حتى 5 جيجابايت للتخزين",
    "0 Extra Users": "0 مستخدمين إضافيين",
    "AI tools access": "الوصول إلى أدوات الذكاء الاصطناعي",
    "Up to 1000 patients annually": "حتى 1000 مريض سنويًا",
    "Up to 20GB Storage": "حتى 20 جيجابايت للتخزين",
    "2 Extra Users": "2 مستخدمين إضافيين",
    "Limited AI tools access": "وصول محدود إلى أدوات الذكاء الاصطناعي",
    "Up to 2000 patients annually": "حتى 2000 مريض سنويًا",
    "Up to 50GB Storage": "حتى 50 جيجابايت للتخزين",
    "5 Extra Users": "5 مستخدمين إضافيين",
    "Full AI tools access": "الوصول الكامل إلى أدوات الذكاء الاصطناعي",
    "Frequently Asked Questions": "الأسئلة المتكررة",
    "Get answers to common queries about MedSyn Solutions": "احصل على إجابات للأسئلة الشائعة حول حلول MedSyn",
    "Q1": "كيف تضمن MedSyn أمان بيانات المرضى؟",
    "A1": "تستخدم MedSyn تشفيرًا متقدمًا وتتبع إرشادات HIPAA لضمان أمان وسرية بيانات المرضى.",
    "Q2": "ما هي التخصصات الطبية التي تغطيها MedSyn Solutions؟",
    "A2": "توفر MedSyn Solutions واجهات متخصصة لمجموعة متنوعة من التخصصات الطبية، بما في ذلك جراحة الأطفال، الجراحة العامة، والجراحة التجميلية.",
    "Q3": "ما نوع الدعم الذي توفره MedSyn؟",
    "A3": "توفر MedSyn دعمًا تقنيًا على مدار الساعة وطوال أيام الأسبوع عبر الهاتف، البريد الإلكتروني، والدردشة المباشرة.",
    "Q4": "هل يمكن الوصول إلى MedSyn من خلال أجهزة أو مواقع متعددة؟",
    "A4": "نعم، MedSyn هو حل قائم على السحابة يمكن الوصول إليه من أي جهاز متصل بالإنترنت بطريقة آمنة.",
    "Q5": "هل يوجد تطبيق جوال لـ MedSyn؟",
    "A5": "حاليًا، يمكن الوصول إلى MedSyn من خلال تطبيق ويب متاح عبر أي جهاز مزود بمتصفح حديث.",
    "Contact": "اتصل",
    "Get in Touch": "تواصل معنا",
    "We're here to support your practice": "نحن هنا لدعم ممارستك",
    "Send us a message": "أرسل لنا رسالة",
    "ContactSubMsg": "إذا كانت لديك أي أسئلة حول الميزات أو الأسعار أو غيرها، فريقنا جاهز للإجابة على جميع استفساراتك.",
    "Request a Demo": "طلب عرض توضيحي",
    "Sent": "تم الإرسال",
    "Your request has been successfully submitted.": "تم إرسال طلبك بنجاح.",
    "Our team will contact you shortly to schedule your demo.": "سيتواصل فريقنا معك قريبًا لتحديد موعد العرض التوضيحي.",
    "Tools": "الأدوات",
    "Chatbot": "روبوت الدردشة",
    "Start": "ابدأ",
    "Chat not activated": "الدردشة غير مفعلة",
    "Use the menu to start a chat": "استخدم القائمة لبدء المحادثة",
    "No messages yet": "لا توجد رسائل حتى الآن",
    "Start typing to begin the conversation": "ابدأ بالكتابة لبدء المحادثة",
    "Pediatric Surgeon": "جراح الأطفال",
    "General Surgeon": "جراح عام",
    "Cosmetic Surgeon": "جراح تجميلي",
    "MedSyn Access": "الوصول إلى MedSyn",
    "Step": "خطوة",
    "Administrator": "المسؤول",
    "MedSyn ID": "معرف MedSyn",
    "Community & Networking": "التواصل والشبكات",
    "Platform": "المنصة",
    "Feed": "التغذية",
    "Welcome": "مرحبًا",
    "Dismiss": "رفض",
    "Request timed out": "انتهت مهلة الطلب",
    "Folder": "مجلد",
    "Patients": "المرضى",
    "Doctors": "الأطباء",
    "Medications": "الأدوية",
    "Diagnostics": "التشخيصات",
    "Techniques": "التقنيات",
    "Consents": "الموافقات",
    "Controls": "الضوابط",
    "Background": "الخلفية",
    "Medical Background": "الخلفية الطبية",
    "Family Background": "الخلفية العائلية",
    "MED_BG": "الخلفية الطبية",
    "FAM_BG": "الخلفية العائلية",
    "Control": "تحكم",
    "Control List": "قائمة التحكم",
    "Create Control": "إنشاء تحكم",
    "Edit Control": "تعديل التحكم",
    "Control Date": "تاريخ التحكم",
    "Add Control": "إضافة تحكم",
    "Appointments": "المواعيد",
    "Users": "المستخدمون",
    "Visits": "الزيارات",
    "Operations": "العمليات",
    "Upcoming Events": "الأحداث القادمة",
    "Upcoming Appointments": "المواعيد القادمة",
    "First Name": "الاسم الأول",
    "Last Name": "الاسم الأخير",
    "Age": "العمر",
    "Gender": "الجنس",
    "Years": "سنوات",
    "Months": "أشهر",
    "Date": "التاريخ",
    "Start Time": "وقت البدء",
    "Select Start Time": "اختر وقت البدء",
    "End Time": "وقت الانتهاء",
    "At": "في",
    "View All": "عرض الكل",
    "Recent Patients": "آخر المرضى",
    "Total Patients": "إجمالي المرضى",
    "Diagnosed Patients": "المرضى المشخصون",
    "Operated Patients": "المرضى الذين أجروا عمليات",
    "Pending Patients": "المرضى المنتظرون",
    "Last week analytics": "تحليلات الأسبوع الماضي",
    "Filters": "مرشحات",
    "Filter": "تصفية",
    "Patient List": "قائمة المرضى",
    "Deleted Patient List": "قائمة المرضى المحذوفة",
    "Doctor": "الطبيب",
    "Client": "العميل",
    "Diagnostic": "التشخيص",
    "Technique": "التقنية",
    "Last Diagnostics": "آخر التشخيصات",
    "Last Techniques": "آخر التقنيات",
    "Add Patient": "إضافة مريض",
    "SPEECH_EHR_INFO": "تتيح لك هذه الأداة تسجيل معلومات المرضى شفهيًا.",
    "Start Recording": "بدء التسجيل",
    "Stop Recording": "إيقاف التسجيل",
    "Ready to record. Click 'Start Recording' when you're ready to speak.": "جاهز للتسجيل. انقر 'بدء التسجيل' عندما تكون مستعدًا للتحدث.",
    "Recording... Speak clearly about the patient's information.": "جارٍ التسجيل... تحدث بوضوح عن معلومات المريض.",
    "Error accessing microphone. Please ensure you've granted microphone permissions.": "خطأ في الوصول إلى الميكروفون. يرجى التأكد من منح أذونات الميكروفون.",
    "Recording stopped. Processing...": "تم إيقاف التسجيل. جارٍ المعالجة...",
    "Couldn't extract any information. Please try again.": "تعذر استخراج أي معلومات. يرجى المحاولة مرة أخرى.",
    "Transcription successful.": "تم النسخ بنجاح.",
    "Extracted Data": "البيانات المستخرجة",
    "Generate Report": "إنشاء تقرير",
    "Download PDF Report": "تحميل التقرير بصيغة PDF",
    "Export": "تصدير",
    "Refresh": "تحديث",
    "Search": "بحث",
    "Parent": "ولي الأمر",
    "Upload Photo": "رفع صورة",
    "Upload": "رفع",
    "Reset": "إعادة تعيين",
    "Allowed JPG, GIF or PNG. Max size of 800K": "الصور المسموح بها JPG، GIF أو PNG. الحجم الأقصى 800K",
    "Male": "ذكر",
    "Female": "أنثى",
    "Blood Group": "فصيلة الدم",
    "Prescriber": "الواصف",
    "Prescribers": "الواصفون",
    "Birth Date": "تاريخ الميلاد",
    "Phone Number": "رقم الهاتف",
    "Address": "العنوان",
    "City": "المدينة",
    "Postal Code": "الرمز البريدي",
    "Civil Status": "الحالة الاجتماعية",
    "Single": "أعزب",
    "Married": "متزوج",
    "Divorced": "مطلق",
    "Widowed": "أرمل",
    "Separated": "منفصل",
    "Profession": "مهنة",
    "Save": "حفظ",
    "Next": "التالي",
    "Finish": "إنهاء",
    "Verify": "تحقق",
    "Medical Folder": "الملف الطبي",
    "Consultations": "الاستشارات",
    "Interventions": "التدخلات",
    "Follow Ups": "المتابعات",
    "Media": "وسائل الإعلام",
    "Documents": "الوثائق",
    "PATIENT": "مريض",
    "PARENT": "الوالد",
    "Not Defined": "غير محدد",
    "Create Patient": "إنشاء مريض",
    "Edit Patient": "تعديل مريض",
    "Patient": "مريض",
    "Connect Patient": "ربط المريض",
    "Connect": "ربط",
    "Connected": "مرتبط",
    "Previous": "السابق",
    "Disease": "المرض",
    "Interventation": "التدخل",
    "Multimedia": "وسائط متعددة",
    "Item": "عنصر",
    "Untitled": "بدون عنوان",
    "File": "ملف",
    "Billing": "الفوترة",
    "Follow Up": "متابعة",
    "Time": "الوقت",
    "Full Name": "الاسم الكامل",
    "Operator": "المشغل",
    "Billed Person": "المفوتر",
    "Total Amount": "المبلغ الإجمالي",
    "Consultation Date": "تاريخ الاستشارة",
    "Family History": "التاريخ العائلي",
    "Medical History": "التاريخ الطبي",
    "Disease History": "تاريخ المرض",
    "Intervention Date": "تاريخ التدخل",
    "Operative Techniques": "تقنيات العملية",
    "Anesthetist": "أخصائي التخدير",
    "Operative Report": "تقرير العملية",
    "Printing": "الطباعة",
    "Header": "الرأس",
    "Signature": "التوقيع",
    "Stamp": "الختم",
    "Choose an Image (PNG, JPG, JPEG)": "اختر صورة (PNG، JPG، JPEG)",
    "Print": "طباعة",
    "Generate": "توليد",
    "Preoperative Assessment": "التقييم قبل الجراحة",
    "Admission Letter": "خطاب القبول",
    "Date & Time": "التاريخ والوقت",
    "Confidential Letter": "رسالة سرية",
    "Consent Form": "نموذج الموافقة",
    "Father": "الأب",
    "Mother": "الأم",
    "Father Name": "اسم الأب",
    "Mother Name": "اسم الأم",
    "Consent": "الموافقة",
    "Consultation": "الاستشارة",
    "Add Consultation": "إضافة استشارة",
    "Consultation List": "قائمة الاستشارات",
    "Create Consultation": "إنشاء استشارة",
    "Edit Consultation": "تعديل استشارة",
    "Create Intervention": "إنشاء تدخل",
    "Edit Intervention": "تعديل تدخل",
    "Preoperative Consultation": "استشارة ما قبل الجراحة",
    "PreoperativeConsultation": "استشارة ما قبل الجراحة",
    "Doctor Letter": "خطاب الطبيب",
    "Hospital Report": "تقرير المستشفى",
    "Follow": "متابعة",
    "Medical Certificate": "شهادة طبية",
    "Number of days": "عدد الأيام",
    "Days": "الأيام",
    "Select Parent": "اختر الوالد",
    "Select Media": "اختر الوسائط",
    "Select follow up": "اختر المتابعة",
    "Medical Prescription": "الوصفة الطبية",
    "Medication": "الدواء",
    "Select Medication": "اختر الدواء",
    "Select date and time": "اختر التاريخ والوقت",
    "Select Year": "اختر السنة",
    "Select consent": "اختر الموافقة",
    "Close": "إغلاق",
    "Transfer": "نقل",
    "Download": "تحميل",
    "All": "الكل",
    "Designation": "التعيين",
    "Department": "القسم",
    "Add Doctor": "إضافة طبيب",
    "Doctor List": "قائمة الأطباء",
    "Deleted Doctor List": "قائمة الأطباء المحذوفين",
    "Create Doctor": "إنشاء طبيب",
    "Edit Doctor": "تعديل طبيب",
    "Type": "النوع",
    "Anesthesiologist": "أخصائي تخدير",
    "Other": "آخر",
    "Name": "الاسم",
    "Dosage": "الجرعة",
    "Frequency": "التردد",
    "Medication List": "قائمة الأدوية",
    "Deleted Medication List": "قائمة الأدوية المحذوفة",
    "Add Medication": "إضافة دواء",
    "Create Medication": "إنشاء دواء",
    "Edit Medication": "تعديل دواء",
    "Add Diagnostic": "إضافة تشخيص",
    "Diagnostic List": "قائمة التشخيص",
    "Deleted Diagnostic List": "قائمة التشخيصات المحذوفة",
    "Edit Diagnostic": "تعديل تشخيص",
    "Create Diagnostic": "إنشاء تشخيص",
    "Technique List": "قائمة التقنيات",
    "Deleted Technique List": "قائمة التقنيات المحذوفة",
    "Add Technique": "إضافة تقنية",
    "Create Technique": "إنشاء تقنية",
    "Edit Technique": "تعديل تقنية",
    "Content": "المحتوى",
    "Configuration": "الإعدادات",
    "Add Consent": "إضافة موافقة",
    "Consent List": "قائمة الموافقة",
    "Deleted Consent List": "قائمة الموافقات المحذوفة",
    "Create Consent": "إنشاء موافقة",
    "Edit Consent": "تعديل موافقة",
    "Create Patient Consultation": "إنشاء استشارة للمريض",
    "Edit Patient Consultation": "تعديل استشارة المريض",
    "Appointment List": "قائمة المواعيد",
    "Deleted Appointment List": "قائمة المواعيد المحذوفة",
    "Add Appointment": "إضافة موعد",
    "History": "التاريخ",
    "From": "من",
    "To": "إلى",
    "Notes": "ملاحظات",
    "Create Appointment": "إنشاء موعد",
    "Edit Appointment": "تعديل موعد",
    "Service": "الخدمة",
    "Service List": "قائمة الخدمات",
    "Deleted Service List": "قائمة الخدمات المحذوفة",
    "Add Service": "إضافة خدمة",
    "Create Service": "إنشاء خدمة",
    "Edit Service": "تعديل خدمة",
    "Base Price": "السعر الأساسي",
    "Description": "الوصف",
    "Select Patient": "اختيار مريض",
    "Zip Code": "الرمز البريدي",
    "Governorate": "المحافظة",
    "Select Governorate": "اختيار المحافظة",
    "Quantity is required": "الكمية مطلوبة",
    "Service is required for additional services": "الخدمة مطلوبة للخدمات الإضافية",
    "Service is required": "الخدمة مطلوبة",
    "Bill List": "قائمة الفواتير",
    "Deleted Bill List": "قائمة الفواتير المحذوفة",
    "Bill": "فاتورة",
    "Add Bill": "إضافة فاتورة",
    "Create Bill": "إنشاء فاتورة",
    "Edit Bill": "تعديل فاتورة",
    "Bill Service": "خدمة الفاتورة",
    "Select Service": "اختيار الخدمة",
    "Quantity": "الكمية",
    "Additional Amount": "مبلغ إضافي",
    "User List": "قائمة المستخدمين",
    "Add User": "إضافة مستخدم",
    "Create User": "إنشاء مستخدم",
    "Edit User": "تعديل مستخدم",
    "Password Required": "كلمة المرور مطلوبة",
    "Password": "كلمة المرور",
    "Add Event": "إضافة حدث",
    "Update Event": "تحديث الحدث",
    "Title": "العنوان",
    "Label": "التصنيف",
    "Select Date": "اختيار التاريخ",
    "Start Date": "تاريخ البدء",
    "Select Start Date": "اختيار تاريخ البدء",
    "End Date": "تاريخ الانتهاء",
    "Select End Date": "اختيار تاريخ الانتهاء",
    "All day": "طوال اليوم",
    "Cancel": "إلغاء",
    "Visit": "زيارة",
    "Intervention": "تدخل",
    "Appointment": "موعد",
    "Event": "حدث",
    "FILTER": "فلتر",
    "View all": "عرض الكل",
    "General": "عام",
    "Security": "الأمان",
    "Mailing": "البريد",
    "Shortcuts": "اختصارات",
    "Details Updated Successfully": "تم تحديث التفاصيل بنجاح",
    "Upload a new logo": "تحميل شعار جديد",
    "General Details": "التفاصيل العامة",
    "Profile Picture": "صورة الملف الشخصي",
    "Doctor Details": "تفاصيل الطبيب",
    "Doctor Details Updated Successfully": "تم تحديث تفاصيل الطبيب بنجاح",
    "Minimum 8 characters long": "الحد الأدنى 8 أحرف",
    "At least one lowercase character": "حرف صغير واحد على الأقل",
    "At least one number, symbol, or whitespace character": "رقم واحد أو رمز أو مسافة واحدة على الأقل",
    "Password Updated Successfully": "تم تحديث كلمة المرور بنجاح",
    "Current Password": "كلمة المرور الحالية",
    "New Password": "كلمة المرور الجديدة",
    "Confirm New Password": "تأكيد كلمة المرور الجديدة",
    "Password Requirements": "متطلبات كلمة المرور",
    "Change Password": "قم بتعديل كلمة المرور",
    "Mailing Updated Successfully": "تم تحديث البريد الإلكتروني بنجاح",
    "Host": "المضيف",
    "Port": "المنفذ",
    "Printing Updated Successfully": "تم تحديث الطباعة بنجاح",
    "View your patient list": "راجع قائمة المرضى الخاصة بك",
    "View your doctor list": "راجع قائمة الأطباء الخاصة بك",
    "View your medication list": "راجع قائمة الأدوية الخاصة بك",
    "View your diagnostic list": "راجع قائمة التشخيص الخاصة بك",
    "View your technique list": "راجع قائمة التقنيات الخاصة بك",
    "View your consent list": "راجع قائمة الموافقات الخاصة بك",
    "View your appointment list": "راجع قائمة المواعيد الخاصة بك",
    "View your user list": "راجع قائمة المستخدمين الخاصة بك",
    "View your service list": "راجع قائمة الخدمات الخاصة بك",
    "View your bill list": "راجع قائمة الفواتير الخاصة بك",
    "Create a new patient record": "إنشاء سجل مريض جديد",
    "Create a new doctor record": "إنشاء سجل طبيب جديد",
    "Create a new medication record": "إنشاء سجل دواء جديد",
    "Create a new diagnostic record": "إنشاء سجل تشخيص جديد",
    "Create a new technique record": "إنشاء سجل تقنية جديد",
    "Create a new consent record": "إنشاء سجل موافقة جديد",
    "Create a new appointment record": "إنشاء سجل موعد جديد",
    "Create a new user record": "إنشاء سجل مستخدم جديد",
    "Create a new service record": "إنشاء سجل خدمة جديد",
    "Create a new bill record": "إنشاء سجل فاتورة جديد",
    "List Patient": "قائمة المرضى",
    "List Doctor": "قائمة الأطباء",
    "List Medication": "قائمة الأدوية",
    "List Diagnostic": "قائمة التشخيصات",
    "List Technique": "قائمة التقنيات",
    "List Consent": "قائمة الموافقات",
    "List Appointment": "قائمة المواعيد",
    "List User": "قائمة المستخدمين",
    "List Service": "قائمة الخدمات",
    "List Bill": "قائمة الفواتير",
    "Try searching for": "حاول البحث عن",
    "No Result For": "لا توجد نتائج لـ",
    "system": "النظام",
    "light": "فاتح",
    "dark": "داكن",
    "English": "الإنجليزية",
    "French": "الفرنسية",
    "Arabic": "العربية",
    "Notifications": "الإشعارات",
    "Mark all as unread": "تحديد الكل كغير مقروء",
    "Mark all as read": "تحديد الكل كمقروء",
    "No Notification Found": "لم يتم العثور على إشعارات",
    "View All Notifications": "عرض جميع الإشعارات",
    "Support": "الدعم",
    "Logout": "تسجيل الخروج",
    "Your journey to surgical innovation starts now.": "رحلتك نحو الابتكار الجراحي تبدأ الآن.",
    "Current Plan": "الخطة الحالية",
    "Your Current Plan:": "خطتك الحالية:",
    "Plan": "الخطة",
    "Essential": "أساسي",
    "Professional": "احترافي",
    "Ultimate": "النهائي",
    "Foundational access for healthcare professionals": "وصول أساسي للعاملين في الرعاية الصحية",
    "Active until": "نشط حتى",
    "You'll receive an alert before your plan concludes to ensure uninterrupted access.": "ستتلقى تنبيهًا قبل انتهاء خطتك لضمان استمرار الوصول.",
    "100 TND Per Month": "100 دينار تونسي شهريًا",
    "ofDays": "{day} من {days} يوم",
    "remainingDays": "يتبقى {days} يوم قبل انتهاء خطتك",
    "Popular": "شائع",
    "Professional plan for individual practitioners and clinics": "خطة احترافية للأطباء الأفراد والعيادات",
    "Shortcuts Updated Successfully": "تم تحديث الاختصارات بنجاح",
    "user": "المستخدم",
    "Log In": "تسجيل الدخول",
    "Continue": "استمرار",
    "Back to login": "العودة لتسجيل الدخول",
    "Remember me": "تذكرني",
    "Select doctor": "اختر الطبيب",
    "Subject": "الموضوع",
    "Message": "الرسالة",
    "Send": "إرسال",
    "Document Attached": "الوثيقة مرفقة",
    "A reset link has been sent": "تم إرسال رابط إعادة التعيين",
    "Password has been successfully reset": "تمت إعادة تعيين كلمة المرور بنجاح",
    "Password Confirmation": "تأكيد كلمة المرور",
    "Confirm": "تأكيد",
    "Confirmation": "التأكيد",
    "Loading": "جارٍ التحميل",
    "Password Updated": "تم تحديث كلمة المرور",
    "admin": "مدير",
    "NoData": "لا توجد بيانات متاحة",
    "Choose Your Role": "اختر دورك",
    "Sign up as a medical professional": "التسجيل كمتخصص طبي",
    "Sign up as a patient": "التسجيل كمريض",
    "Activation": "التنشيط",
    "Profile": "الملف الشخصي",
    "License": "الرخصة",
    "License Key": "مفتاح الترخيص",
    "Access Code": "رمز الوصول",
    "Verification": "التحقق",
    "Email Verification": "التحقق من البريد الإلكتروني",
    "Account activation link sent to your email address": "تم إرسال رابط تفعيل الحساب إلى بريدك الإلكتروني",
    "Verification link has been sent successfully": "تم إرسال رابط التحقق بنجاح",
    "Skip for now": "تجاهل الآن",
    "Didn't get the mail": "لم تتلق البريد؟",
    "Resend": "إعادة الإرسال",
    "Invalid verification link": "رابط التحقق غير صالح",
    "Verification link expired": "رابط التحقق منتهي الصلاحية",
    "Email verified successfully": "تم التحقق من البريد الإلكتروني بنجاح",
    "User not found": "لم يتم العثور على المستخدم",
    "Speciality": "التخصص",
    "Logo": "الشعار",
    "Upload Logo": "رفع الشعار",
    "I agree to": "أوافق على",
    "Terms & Conditions": "الشروط والأحكام",
    "privacy policy & terms": "سياسة الخصوصية والشروط",
    "This field is required": "هذا الحقل مطلوب",
    "Please enter a valid email address": "يرجى إدخال عنوان بريد إلكتروني صالح",
    "The field must contain at least one uppercase, one lowercase, one special character and one number with a minimum of 8 characters": "يجب أن يحتوي الحقل على حرف كبير واحد على الأقل وحرف صغير ورمز خاص ورقم مع 8 أحرف على الأقل",
    "The password confirmation does not match": "تأكيد كلمة المرور لا يتطابق",
    "Enter number between": "أدخل رقمًا بين",
    "and": "و",
    "This field must be an integer": "يجب أن يكون هذا الحقل عددًا صحيحًا",
    "Operating Techniques": "تقنيات العمليات",
    "Select date": "اختر تاريخًا",
    "Billed": "تم الفوترة",
    "Create Follow-Up": "إنشاء متابعة",
    "Edit Follow-Up": "تعديل متابعة",
    "Back": "عودة",
    "Same Day": "نفس اليوم",
    "Dates": "التواريخ",
    "Files": "الملفات",
    "Title is required": "العنوان مطلوب",
    "File is required": "الملف مطلوب",
    "Add Files from URL": "إضافة ملفات من رابط URL",
    "Drag and Drop Your Image Here": "اسحب وأسقط صورتك هنا",
    "Browse Images": "استعرض الصور",
    "Remove File": "إزالة الملف",
    "or": "أو",
    "Add Anesthesiologist": "إضافة طبيب تخدير",
    "Document transferred successfully.": "تم نقل المستند بنجاح.",
    "An error has occurred, try again later.": "حدث خطأ، حاول مرة أخرى لاحقًا.",
    "No diagnostics recorded for this consultation.": "لم يتم تسجيل أي تشخيصات لهذه الاستشارة.",
    "No techniques recorded for this intervention.": "لم يتم تسجيل أي تقنيات لهذا التدخل.",
    "No disease history recorded for this consultation.": "لم يتم تسجيل تاريخ مرضي لهذه الاستشارة.",
    "No operative report recorded for this intervention.": "لم يتم تسجيل تقرير العملية لهذا التدخل.",
    "No content recorded for this control.": "لم يتم تسجيل محتوى لهذا التحكم.",
    "No media available": "لا توجد وسائط متاحة",
    "There are no files to display for the selected criteria": "لا توجد ملفات للعرض بناءً على المعايير المحددة.",
    "Account Setup": "إعداد الحساب",
    "Account Configured Successfully": "تم تكوين الحساب بنجاح",
    "Join": "انضم",
    "minCharField": "يجب أن يحتوي هذا الحقل على {length} حرفًا على الأقل.",
    "You are not authorized": "أنت غير مصرح",
    "You do not have permission to view this page using the credentials that you have provided while login.": "ليس لديك إذن لعرض هذه الصفحة باستخدام بيانات الاعتماد التي قدمتها أثناء تسجيل الدخول.",
    "If you believe this is a mistake, please contact your site administrator.": "إذا كنت تعتقد أن هذا خطأ، يرجى الاتصال بمسؤول الموقع.",
    "Administratif Access": "الوصول الإداري",
    "Terminal": "المحطة",
    "Appointment Management": "إدارة المواعيد",
    "Patient Management": "إدارة المرضى",
    "Doctor Management": "إدارة الأطباء",
    "Medication Management": "إدارة الأدوية",
    "Diagnostic Management": "إدارة التشخيص",
    "Technique Management": "إدارة التقنيات",
    "Consent Management": "إدارة الموافقات",
    "Service Management": "إدارة الخدمات",
    "Bill Management": "إدارة الفواتير",
    "Consultation Management": "إدارة الاستشارات",
    "Control Management": "إدارة التحكم",
    "Calendar Management": "إدارة التقويم",
    "Add Role": "إضافة دور",
    "Create Role": "إنشاء دور",
    "Edit Role": "تعديل دور",
    "Role List": "قائمة الأدوار",
    "Administrator Access": "إدارة الوصول",
    "Select All": "تحديد الكل",
    "Read": "قراءة",
    "Write": "كتابة",
    "Delete": "حذف",
    "Please select at least one permission before saving.":"يرجى تحديد إذن واحد على الأقل قبل الحفظ.",
    "Total Users": "إجمالي المستخدمين",
    "Medical Specialty": "التخصص الطبي",
    "Status": "الحالة",
    "Confirmed": "تم التأكيد",
    "Not Confirmed": "لم يتم التأكيد",
    "Services": "الخدمات",
    "Bills": "الفواتير",
    "Activity": "النشاط",
    "No Activity Yet": "لا توجد نشاطات بعد",
    "There are no activities to display at the moment. New activities will appear here as they occur.": "لا توجد أنشطة للعرض في الوقت الحالي. ستظهر الأنشطة الجديدة هنا عند حدوثها.",
    "Webmail": "البريد الإلكتروني",
    "Sidebar Configuration": "تكوين الشريط الجانبي",
    "Theme": "السمة",
    "Theme reinitialized successfully": "تمت إعادة تهيئة السمة بنجاح",
    "Theme saved successfully": "تم حفظ السمة بنجاح",
    "Theme Settings": "إعدادات السمة",
    "Primary Color": "اللون الأساسي",
    "Skins": "الأشكال",
    "Default": "افتراضي",
    "Bordered": "بحدود",
    "Semi Dark Menu": "قائمة شبه داكنة",
    "Layout": "التخطيط",
    "Vertical": "عمودي",
    "Collapsed": "مطوي",
    "Horizontal": "أفقي",
    "Compact": "مضغوط",
    "Wide": "عريض",
    "Direction": "الاتجاه",
    "Left to right": "من اليسار إلى اليمين",
    "Right to left": "من اليمين لليسار",
    "Account created successfully": "تم إنشاء الحساب بنجاح",
    "Creating": "إنشاء",
    "created successfully": "تم إنشاؤه بنجاح",
    "updated successfully": "تم التحديث بنجاح",
    "Updating": "تحديث",
    "An error has occurred": "حدث خطأ",
    "ofPatient": "للمريض",
    "ofAppointment": "للموعد",
    "ofConsent": "للموافقة",
    "ofMedication": "لدواء",
    "ofDiagnostic": "للتشخيص",
    "ofTechnique": "للتقنية",
    "ofDoctor": "لطبيب",
    "ofUser": "للمستخدم",
    "ofRole": "للدور",
    "ofService": "لخدمة",
    "ofBill": "لفاتورة",
    "ofFollow Up": "للمتابعة",
    "ofConsultation": "للاستشارة",
    "ofIntervention": "للتدخل",
    "ofControl": "للتحكم",
    "Deletion Failed": "فشل الحذف",
    "Operation Failed": "فشل العملية",
    "Storage limit exceeded. Consider upgrading your plan.": "تجاوزت حد التخزين. يرجى التفكير في ترقية خطتك.",
    "Delete Confirmation": "تأكيد الحذف",
    "deletePatientMessage": "هل ترغب في حذف المريض {name}؟",
    "deleteFollowUpMessage": "هل ترغب في حذف متابعة المريض {name}؟",
    "deleteAppointmentMessage": "هل ترغب في حذف موعدك {name}؟",
    "deleteBillMessage": "هل ترغب في حذف فاتورة {name}؟",
    "deleteServiceMessage": "هل ترغب في حذف الخدمة {name}؟",
    "deleteConsentMessage": "هل ترغب في حذف الموافقة {name}؟",
    "deleteConsultationMessage": "هل ترغب في حذف الاستشارة {name}؟",
    "deleteControlMessage": "هل ترغب في حذف التحكم {name}؟",
    "deleteDiagnosticMessage": "هل ترغب في حذف التشخيص {name}؟",
    "deleteDoctorMessage": "هل ترغب في حذف الطبيب {name}؟",
    "deleteMedicationMessage": "هل ترغب في حذف الدواء {name}؟",
    "deleteTechniqueMessage": "هل ترغب في حذف التقنية {name}؟",
    "deleteUserMessage": "هل ترغب في حذف حساب المستخدم {name}؟",
    "deleteRoleMessage": "هل ترغب في حذف الدور {name}؟",
    "deleteOwnerMessage": "هل ترغب في حذف المالك {name}؟",
    "deleteLicenseMessage": "هل ترغب في حذف الترخيص {name}؟",
    "deleteVersionMessage": "هل ترغب في حذف الإصدار {name}؟",
    "deleteSpecialityMessage": "هل ترغب في حذف التخصص {name}؟",
    "deletePlanMessage": "هل ترغب في حذف الخطة {name}؟",
    "deleteTutorialMessage": "هل ترغب في حذف البرنامج التعليمي {name}؟",
    "deleteNotificationMessage": "هل ترغب في حذف الإشعار {name}؟",
    "deleteMultimediaMessage": "هل ترغب في حذف ملف الوسائط المتعددة {name}؟",
    "deleteDatabaseMessage": "هل ترغب في حذف قاعدة البيانات {name}؟",
    "deleteTableMessage": "هل ترغب في حذف الجدول {name}؟",
    "deleteDemoMessage": "هل ترغب في حذف طلب العرض التجريبي {name}؟",
    "deleteInterventionMessage": "هل ترغب في حذف التدخل {name}؟",
    "Restore Confirmation": "تأكيد الاستعادة",
    "restorePatientMessage": "هل ترغب في استعادة المريض {name}؟",
    "restoreFollowUpMessage": "هل أنت متأكد من رغبتك في استعادة متابعة المريض {name}؟",
    "restoreAppointmentMessage": "هل ترغب في استعادة موعدك {name}؟",
    "restoreBillMessage": "هل ترغب في استعادة فاتورة {name}؟",
    "restoreServiceMessage": "هل ترغب في استعادة الخدمة {name}؟",
    "restoreConsentMessage": "هل ترغب في استعادة الموافقة {name}؟",
    "restoreConsultationMessage": "هل ترغب في استعادة الاستشارة {name}؟",
    "restoreControlMessage": "هل ترغب في استعادة التحكم {name}؟",
    "restoreDiagnosticMessage": "هل ترغب في استعادة التشخيص {name}؟",
    "restoreDoctorMessage": "هل ترغب في استعادة الطبيب {name}؟",
    "restoreMedicationMessage": "هل ترغب في استعادة الدواء {name}؟",
    "restoreTechniqueMessage": "هل ترغب في استعادة التقنية {name}؟",
    "restoreUserMessage": "هل ترغب في استعادة حساب المستخدم {name}؟",
    "restoreRoleMessage": "هل ترغب في استعادة الدور {name}؟",
    "This action cannot be undone.": "لا يمكن التراجع عن هذا الإجراء.",
    "docOverview": "نظرة عامة على الوثائق",
    "docMessage": "تصفح مقاطع الفيديو التعليمية والأدلة المكتوبة للتعرف على التطبيق وتسهيل عملك.",
    "All Tutorials": "جميع الدروس",
    "Watch": "شاهد",
    "created an appointment": "أنشأ موعدًا",
    "created a bill": "أنشأ فاتورة",
    "created a consent": "أنشأ موافقة",
    "created a diagnostic": "أنشأ تشخيصًا",
    "created a doctor": "أنشأ طبيبًا",
    "created a follow up": "أنشأ متابعة",
    "created a medication": "أنشأ دواء",
    "created a patient": "أنشأ مريضًا",
    "created a service": "أنشأ خدمة",
    "created a technique": "أنشأ تقنية",
    "created a consultation": "أنشأ استشارة",
    "created an intervention": "أنشأ تدخلًا",
    "created a control": "أنشأ تحكمًا",
    "updated an appointment": "حدث موعدًا",
    "updated a bill": "حدث فاتورة",
    "updated a consent": "حدث موافقة",
    "updated a diagnostic": "حدث تشخيصًا",
    "updated a doctor": "حدث طبيبًا",
    "updated a follow up": "حدث متابعة",
    "updated a medication": "حدث دواء",
    "updated a patient": "حدث مريضًا",
    "updated a service": "حدث خدمة",
    "updated a technique": "حدث تقنية",
    "updated a consultation": "حدث استشارة",
    "updated an intervention": "حدث تدخلًا",
    "updated a control": "حدث تحكمًا",
    "deleted an appointment": "حذف موعدًا",
    "deleted a bill": "حذف فاتورة",
    "deleted a consent": "حذف موافقة",
    "deleted a diagnostic": "حذف تشخيصًا",
    "deleted a doctor": "حذف طبيبًا",
    "deleted a follow up": "حذف متابعة",
    "deleted a medication": "حذف دواء",
    "deleted a patient": "حذف مريضًا",
    "deleted a service": "حذف خدمة",
    "deleted a technique": "حذف تقنية",
    "deleted a consultation": "حذف استشارة",
    "deleted an intervention": "حذف تدخلًا",
    "deleted a control": "حذف تدخلًا",
    "Patient deleted successfully": "تم حذف المريض بنجاح",
    "Appointment deleted successfully": "تم حذف الموعد بنجاح",
    "Consultation deleted successfully": "تم حذف الاستشارة بنجاح",
    "Intervention deleted successfully": "تم حذف التدخل بنجاح",
    "Control deleted successfully": "تم حذف المراقبة بنجاح",
    "Trash": "سلة المهملات",
    "System Administration": "إدارة النظام",
    "Owners": "المالكون",
    "Owner List": "قائمة المالكين",
    "Edit Owner": "تعديل المالك",
    "Licenses": "التراخيص",
    "License List": "قائمة التراخيص",
    "Key": "مفتاح",
    "Activated On": "مفعل في",
    "Activated By": "مفعل بواسطة",
    "Expires On": "ينتهي في",
    "Add License": "إضافة ترخيص",
    "Create License": "إنشاء ترخيص",
    "Edit License": "تعديل ترخيص",
    "Sub Specialty": "التخصص الفرعي",
    "Specialties": "التخصصات",
    "Speciality List": "قائمة التخصصات",
    "Add Speciality": "إضافة تخصص",
    "Create Speciality": "إنشاء تخصص",
    "Edit Speciality": "تعديل تخصص",
    "Plans": "الخطط",
    "Plan List": "قائمة الخطط",
    "Add Plan": "إضافة خطة",
    "Create Plan": "إنشاء خطة",
    "Edit Plan": "تعديل خطة",
    "Storage": "التخزين",
    "Max Patients": "أقصى عدد للمرضى",
    "Max Users": "أقصى عدد للمستخدمين",
    "Access": "الوصول",
    "Versions": "الإصدارات",
    "Version": "إصدار",
    "Code": "رمز",
    "Version List": "قائمة الإصدارات",
    "Variable": "متغير",
    "Add Version": "إضافة إصدار",
    "Create Version": "إنشاء إصدار",
    "Edit Version": "تعديل إصدار",
    "Databases": "قواعد البيانات",
    "Database List": "قائمة قواعد البيانات",
    "Add Database": "إضافة قاعدة بيانات",
    "Create Database": "إنشاء قاعدة بيانات",
    "Edit Database": "تعديل قاعدة بيانات",
    "Demo Requests": "طلبات العرض التجريبي",
    "Demo Request List": "قائمة طلبات العرض التجريبي",
    "Add Demo Request": "إضافة طلب عرض تجريبي",
    "Create Demo Request": "إنشاء طلب عرض تجريبي",
    "Link": "رابط",
    "Edit Demo Request": "تعديل طلب العرض التجريبي",
    "Completed": "مكتمل",
    "Not Completed": "غير مكتمل",
    "Inquiries": "استفسارات",
    "Inquiry List": "قائمة الاستفسارات",
    "Inquiry not found": "لم يتم العثور على الاستفسار",
    "Inquiry Details": "تفاصيل الاستفسار",
    "Mark as Completed": "وضع علامة كمكتمل",
    "Mark as Not Completed": "وضع علامة كغير مكتمل",
    "Length": "طول",
    "Null": "فارغ",
    "Create Table": "إنشاء جدول",
    "Edit Table": "تعديل جدول",
    "Add Column": "إضافة عمود",
    "Table name is required": "اسم الجدول مطلوب",
    "Save Changes": "حفظ التغييرات",
    "Enter custom default": "أدخل القيمة الافتراضية",
    "None": "لا شيء",
    "Attributes": "سمات",
    "Constraints": "قيود",
    "Add Constraint": "إضافة قيد",
    "Constraint Type": "نوع القيد",
    "Column": "عمود",
    "Reference Table": "الجدول المرجعي",
    "Manage Constraints": "إدارة القيود",
    "Primary Key on column": "المفتاح الأساسي على العمود",
    "Unique constraint on column": "قيد فريد على العمود",
    "Foreign Key": "مفتاح خارجي",
    "references": "مراجع",
    "Auto Increment": "زيادة تلقائية",
    "Primary Key": "مفتاح أساسي",
    "Tutorials": "الدروس",
    "Tutorial List": "قائمة الدروس",
    "Add Tutorial": "إضافة درس",
    "Create Tutorial": "إنشاء درس",
    "Edit Tutorial": "تعديل درس",
    "Thumbnail": "صورة مصغرة",
    "Video": "فيديو",
    "Tag": "علامة",
    "Language": "لغة",
    "Notification List": "قائمة الإخطارات",
    "Add Notification": "إضافة إشعار",
    "Create Notification": "إنشاء إشعار",
    "Edit Notification": "تعديل إشعار",
    "Personal": "شخصي",
    "Tenant": "مستأجر",
    "Icon": "أيقونة",
    "Color": "لون",
    "Arabic Content": "المحتوى باللغة العربية",
    "French Content": "المحتوى باللغة الفرنسية",
    "English Content": "المحتوى باللغة الإنجليزية",
    "Database": "قاعدة البيانات",
    "UI Elements": "عناصر واجهة المستخدم",
    "Forms & Tables": "النماذج والجداول",
    "Pages": "الصفحات",
    "Charts & Maps": "الرسوم البيانية والخرائط",
    "Others": "أخرى",
    "Typography": "الطباعة",
    "Cards": "البطاقات",
    "Basic": "أساسي",
    "Advance": "متقدم",
    "Widgets": "الأدوات",
    "Card Action": "إجراءات البطاقة",
    "Components": "المكونات",
    "Alert": "تنبيه",
    "Close Alert": "إغلاق التنبيه",
    "Avatar": "الصورة الرمزية",
    "Badge": "شارة",
    "Button": "زر",
    "Calendar": "التقويم",
    "Image": "صورة",
    "Pagination": "ترقيم الصفحات",
    "Progress Circular": "تقدم دائري",
    "Progress Linear": "تقدم خطي",
    "Autocomplete": "الإكمال التلقائي",
    "Tooltip": "تلميح",
    "Slider": "شريط التمرير",
    "Date Time Picker": "اختيار التاريخ والوقت",
    "Select": "تحديد",
    "Switch": "مفتاح التبديل",
    "Checkbox": "مربع الاختيار",
    "Radio": "زر الراديو",
    "Textarea": "مربع النص",
    "Rating": "تقييم",
    "File Input": "مدخل الملف",
    "Otp Input": "مدخل OTP",
    "Form Layout": "تصميم النموذج",
    "Form Validation": "التحقق من صحة النموذج",
    "Charts": "الرسوم البيانية",
    "Apex Chart": "مخطط Apex",
    "Chartjs": "مخطط Chartjs",
    "Account": "حساب",
    "Active": "نشط",
    "Suspended": "معلق",
    "Account Settings": "إعدادات الحساب",
    "User Profile": "ملف تعريف المستخدم",
    "FAQ": "الأسئلة الشائعة",
    "Dialog Examples": "أمثلة على الحوار",
    "Pricing": "التسعير",
    "List": "قائمة",
    "Edit": "تعديل",
    "Nav Levels": "مستويات التنقل",
    "Level 2.1": "المستوى 2.1",
    "Level 2.2": "المستوى 2.2",
    "Level 3.1": "المستوى 3.1",
    "Level 3.2": "المستوى 3.2",
    "Raise Support": "طلب الدعم",
    "Documentation": "التوثيق",
    "Dashboards": "لوحات المعلومات",
    "Analytics": "التحليلات",
    "Apps & Pages": "التطبيقات والصفحات",
    "Email": "البريد الإلكتروني",
    "Chat": "الدردشة",
    "Invoice": "فاتورة",
    "Preview": "معاينة",
    "Add": "إضافة",
    "User": "مستخدم",
    "View": "عرض",
    "Login v1": "تسجيل الدخول v1",
    "Login v2": "تسجيل الدخول v2",
    "Login": "تسجيل الدخول",
    "Registration": "التسجيل",
    "Register v1": "تسجيل v1",
    "Register v2": "تسجيل v2",
    "Register": "تسجيل",
    "Forget Password v1": "نسيت كلمة المرور v1",
    "Forget Password v2": "نسيت كلمة المرور v2",
    "Forgot Password v1": "نسيت كلمة المرور v1",
    "Forgot Password v2": "نسيت كلمة المرور v2",
    "Forgot Password": "نسيت كلمة المرور",
    "Reset Password v1": "إعادة تعيين كلمة المرور v1",
    "Reset Password v2": "إعادة تعيين كلمة المرور v2",
    "Reset Password": "إعادة تعيين كلمة المرور",
    "Miscellaneous": "متنوع",
    "Coming Soon": "قريبًا",
    "This feature is under development and will be available shortly": "هذه الميزة قيد التطوير وستكون متاحة قريبًا",
    "Thank you for your patience": "شكرًا على صبرك",
    "Not Authorized": "غير مصرح",
    "Under Maintenance": "قيد الصيانة",
    "Error": "خطأ",
    "Statistics": "الإحصائيات",
    "Year": "سنة",
    "Revenue": "الإيرادات",
    "Monthly Consultations": "الاستشارات الشهرية",
    "Genders": "الأجناس",
    "Card Actions": "إجراءات البطاقة",
    "Actions": "الإجراءات",
    "Access Control": "التحكم في الوصول",
    "User Interface": "واجهة المستخدم",
    "CRM": "إدارة علاقات العملاء",
    "eCommerce": "التجارة الإلكترونية",
    "Icons": "الأيقونات",
    "Chip": "رقاقة",
    "Dialog": "حوار",
    "Expansion Panel": "لوحة التوسع",
    "Combobox": "قائمة منسدلة",
    "Textfield": "حقل نص",
    "Range Slider": "شريط التمرير المتدرج",
    "Menu": "القائمة",
    "Utilities": "الأدوات المساعدة",
    "Healthcare Management": "إدارة الرعاية الصحية",
    "Scheduling & Planning": "الجدولة والتخطيط",
    "Medical Database": "قاعدة البيانات الطبية",
    "User Management": "إدارة المستخدمين",
    "Financial Management": "الإدارة المالية",
    "Communication Tools": "أدوات التواصل",
    "Reporting Tools": "أدوات التقارير",
    "Documentation & Resources": "التوثيق والموارد",
    "Snackbar": "إشعار",
    "Tabs": "التبويبات",
    "Home": "الرئيسية",
    "Access Denied": "تم رفض الوصول",
    "You do not have the necessary permissions to access this page": "ليس لديك الأذونات اللازمة للوصول إلى هذه الصفحة",
    "Form Elements": "عناصر النموذج",
    "Form Layouts": "تصميمات النماذج",
    "Authentication": "التحقق",
    "Page Not Found - 404": "الصفحة غير موجودة - 404",
    "Page Not Found": "الصفحة غير موجودة",
    "We couldn't find the page you are looking for": "لم نتمكن من العثور على الصفحة التي تبحث عنها",
    "500_ERROR_TITLE": "خطأ داخلي في الخادم",
    "500_ERROR_MESSAGE": "يواجه الخادم خطأً داخليًا أو تكوينًا غير صحيح ولا يمكنه إكمال طلبك.",
    "Not Authorized - 401": "غير مصرح - 401",
    "Server Error - 500": "خطأ في الخادم - 500",
    "Forms": "النماذج",
    "Timeline": "الجدول الزمني",
    "Show More": "عرض المزيد",
    "Show Less": "عرض أقل",
    "Disabled Menu": "القائمة غير مفعلة",
    "Help Center": "مركز المساعدة",
    "Verify Email": "تحقق من البريد الإلكتروني",
    "Verify Email v1": "التحقق من البريد الإلكتروني v1",
    "Verify Email v2": "التحقق من البريد الإلكتروني v2",
    "Two Steps": "التحقق بخطوتين",
    "Two Steps v1": "التحقق بخطوتين v1",
    "Two Steps v2": "التحقق بخطوتين v2",
    "Custom Input": "مدخل مخصص",
    "Extensions": "الامتدادات",
    "Tour": "جولة",
    "Register Multi-Steps": "التسجيل عبر خطوات متعددة",
    "Wizard Examples": "أمثلة على المعالج",
    "Checkout": "إتمام الشراء",
    "Create Deal": "إنشاء صفقة",
    "Property Listing": "قائمة الممتلكات",
    "Roles & Permissions": "الأدوار والأذونات",
    "Roles": "الأدوار",
    "Role": "دور",
    "Permissions": "الأذونات",
    "Permission": "إذن",
    "Simple Table": "جدول بسيط",
    "Tables": "الجداول",
    "Data Table": "جدول البيانات",
    "Apps": "التطبيقات",
    "Misc": "متنوع",
    "Wizard Pages": "صفحات المعالج",
    "Form Wizard": "معالج النموذج",
    "Numbered": "مرقّم",
    "ecommerce": "التجارة الإلكترونية",
    "Ecommerce": "التجارة الإلكترونية",
    "Product": "منتج",
    "Category": "فئة",
    "Order": "طلب",
    "Details": "تفاصيل",
    "Customer": "عميل",
    "Manage Review": "إدارة المراجعات",
    "Referrals": "الإحالات",
    "Settings": "الإعدادات",
    "Course Details": "تفاصيل الدورة",
    "My Course": "دورتي",
    "Overview": "نظرة عامة",
    "Academy": "أكاديمية",
    "Logistics": "اللوجستيات",
    "Dashboard": "لوحة المعلومات",
    "Fleet": "أسطول",
    "Editors": "المحررون",
    "Front Pages": "الصفحات الأمامية",
    "Landing": "الصفحة الرئيسية",
    "checkout": "إتمام الشراء",
    "Payment": "الدفع",
    "Swiper": "سلايدر",
    "Total": "الإجمالي",
    "Week": "أسبوع",
    "January": "يناير",
    "February": "فبراير",
    "March": "مارس",
    "April": "أبريل",
    "May": "مايو",
    "June": "يونيو",
    "July": "يوليو",
    "August": "أغسطس",
    "September": "سبتمبر",
    "October": "أكتوبر",
    "November": "نوفمبر",
    "December": "ديسمبر",
    "Jan": "يناير",
    "Feb": "فبراير",
    "Mar": "مارس",
    "Apr": "أبريل",
    "Jun": "يونيو",
    "Jul": "يوليو",
    "Aug": "أغسطس",
    "Sep": "سبتمبر",
    "Oct": "أكتوبر",
    "Nov": "نوفمبر",
    "Dec": "ديسمبر",
    "$vuetify": {
        "badge": "شارة",
        "noDataText": "لا توجد بيانات متاحة",
        "close": "إغلاق",
        "open": "فتح",
        "carousel": {
            "ariaLabel": {
                "delimiter": "محدد"
            }
        },
        "dataFooter": {
            "itemsPerPageText": "عدد العناصر في الصفحة:",
            "itemsPerPageAll": "الكل",
            "pageText": "{0}-{1} من {2}",
            "firstPage": "الصفحة الأولى",
            "prevPage": "الصفحة السابقة",
            "nextPage": "الصفحة التالية",
            "lastPage": "الصفحة الأخيرة"
        },
        "pagination": {
            "ariaLabel": {
                "root": "جذر",
                "previous": "سابقة",
                "next": "التالي",
                "currentPage": "الصفحة الحالية",
                "page": "صفحة"
            }
        },
        "input": {
            "clear": "مسح",
            "appendAction": "إضافة إجراء",
            "prependAction": "إجراء مسبق",
            "otp": "رمز التحقق"
        },
        "fileInput": {
            "counterSize": "حجم العداد"
        },
        "rating": {
            "ariaLabel": {
                "item": "عنصر"
            }
        },
        "datePicker": {
            "title": "عنوان",
            "header": "رأس"
        }
    }
}