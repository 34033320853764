<script setup>
import { useSpeciality } from "@/composables/Central/Admin/useSpeciality";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";
import { ref, watchEffect, computed } from "vue";

definePage({
    meta: {
        isRoute: true,
        adminOnly: true,
        userTypes: ['admin'],
    },
});

const { t } = useI18n();
const router = useRouter();
const specialties = ref([]);
const totalSpecialties = ref(0);
const searchQuery = ref("");
const itemsPerPage = ref(10);
const page = ref(1);
const sortBy = ref();
const orderBy = ref();

const updateOptions = (options) => {
    specialties.value = [];
    page.value = options.page;
    sortBy.value = options.sortBy[0]?.key;
    orderBy.value = options.sortBy[0]?.order;
};

const { fetchSpecialties, fetchingSpecialties, destroySpeciality } = useSpeciality();

const refreshSpecialties = () => {
    fetchSpecialties({
        query: {
            q: searchQuery.value,
            itemsPerPage: itemsPerPage.value,
            page: page.value,
            sortBy: sortBy.value,
            orderBy: orderBy.value,
        },
    })
        .then((response) => {
            fetchingSpecialties.value = false;
            specialties.value = response.data.specialties;
            totalSpecialties.value = response.data.total;
        })
        .catch((error) => {
            console.error(error);
        });
};

watchEffect(refreshSpecialties);

const headers = computed(() => {
    let baseHeaders = [{ title: t("Name"), value: "name", order: 1 }];

    const actionsHeader = { title: t("Actions"), value: "actions", sortable: false, align: "end", order: 4 };

    baseHeaders.push(actionsHeader);

    baseHeaders.sort((a, b) => a.order - b.order);

    return baseHeaders;
});

const addNewSpeciality = () => {
    router.push({ name: "admin-specialties-add" });
};

const deleteSpeciality = async (id) => {
    await destroySpeciality(id).then(() =>{
        refreshSpecialties();
        hideDeleteDialog();
    });
};

const editSpeciality = (id) => {
    router.push({ name: "admin-specialties-edit-id", params: { id: id } });
};

const isDeleteDialogVisible = ref(false);
const selectedId = ref(null);
const deleteMessage = computed(() => {
    const speciality = specialties.value.find(m => m.id === selectedId.value);
    return speciality ? t('deleteSpecialityMessage', { name: speciality.name }) : '';
});

const showDeleteDialog = (id) => {
    selectedId.value = id;
    isDeleteDialogVisible.value = true;
}

const hideDeleteDialog = () => {
    isDeleteDialogVisible.value = false;
}
</script>

<template>
    <section>
        <VRow>
            <VCol cols="12">
                <VCard :title="$t('Speciality List')">
                    <VCardText class="d-flex flex-wrap py-4 gap-4">
                        <div class="me-3 d-none d-sm-flex gap-3">
                            <AppSelect :model-value="itemsPerPage" :items="[
                                { value: 10, title: '10' },
                                { value: 25, title: '25' },
                                { value: 50, title: '50' },
                                { value: 100, title: '100' },
                                { value: -1, title: 'All' },
                            ]" style="inline-size: 6.25rem"
                                @update:model-value="itemsPerPage = parseInt($event, 10)" />
                        </div>
                        <VSpacer />

                        <div class="d-flex align-center flex-wrap gap-4">
                            <div style="inline-size: 10rem">
                                <AppTextField v-model="searchQuery" :placeholder="$t('Search')" density="compact" />
                            </div>

                            <VBtn class="d-none d-md-flex" variant="tonal" color="secondary"
                                prepend-icon="tabler-reload" @click="refreshSpecialties">
                                {{$t('Refresh')}}
                            </VBtn>

                            <VBtn @click="addNewSpeciality">
                                <VIcon icon="tabler-plus" size="22" class="mr-1" />
                                <span class="d-none d-md-flex">{{ $t('Add Speciality') }}</span>
                            </VBtn>
                        </div>
                    </VCardText>

                    <VDivider />

                    <VDataTableServer loading-text="Loading" :loading="fetchingSpecialties" :sticky="true"
                        v-model:items-per-page="itemsPerPage" v-model:page="page" :items="specialties"
                        :items-length="totalSpecialties" :headers="headers" class="text-no-wrap"
                        @update:options="updateOptions">

                        <template #item.actions="{ item }">
                            <div class="align-end">
                                <IconBtn @click="editSpeciality(item.id)">
                                    <VIcon icon="tabler-edit" />
                                </IconBtn>

                                <IconBtn @click="showDeleteDialog(item.id)">
                                    <VIcon icon="tabler-trash" />
                                </IconBtn>
                            </div>
                        </template>
                    </VDataTableServer>
                </VCard>
            </VCol>
        </VRow>
    </section>
    <DeleteConfirmation :is-dialog-visible="isDeleteDialogVisible" :id="selectedId" :message="deleteMessage"
         @delete="deleteSpeciality($event)" @cancel="hideDeleteDialog" />
</template>

<style lang="scss">

.text-capitalize {
    text-transform: capitalize;
}

.speciality-list-name:not(:hover) {
    color: rgba(var(--v-theme-on-background), var(--v-medium-emphasis-opacity));
}

.align-right {
    text-align: right;
}

.align-end, .v-data-table__th:last-child {
    display: flex !important;
    justify-content: flex-end !important;
}

</style>