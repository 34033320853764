import { defineStore } from "pinia";
import axios from "axios";
import { encryptedStorage } from "../@core/composable/encryptedStorage";

export const usePlanStore = defineStore("plan", {
    state: () => ({
        isReady: false,
        features: [],
        error: null,
        refreshInterval: 30 * 60 * 1000,
        lastRefreshTime: null,
    }),
    actions: {
        async initialize() {
            try {
                if (this.isReady) return;
                await this.fetch();
                this.isReady = true;
            } catch (error) {
                console.error("Error initializing plan:", error);
                this.isReady = false;
                throw error;
            }
        },
        async fetch() {
            try {
                const response = await axios.get("/api/features");
                this.features = response.data;
                this.lastRefreshTime = Date.now();
            } catch (error) {
                this.error =
                    error instanceof Error
                        ? error.message
                        : "An error occurred";
                console.error("Failed to fetch plan :", error);
                throw error;
            }
        },
        reset() {
            this.$reset();
            localStorage.removeItem("plan");
        },
    },
    getters: {
        hasFeature: (state) => (featureName) =>
            state.features.includes(featureName),
    },
    persist: {
        storage: encryptedStorage,
    },
});
