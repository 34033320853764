<script setup>
import { useConsent } from "@/composables/Tenant/useConsent";
import noImage from "@images/avatars/consent.png";
import { requiredValidator } from '@core/utils/validators.js';
import { useStatic } from '@/composables/useStatic';
import { ref, computed, watch, watchEffect } from "vue";

const props = defineProps({
    id: {
        type: Number,
        required: false,
    },
});

definePage({
    meta: {
        navActiveLink: 'consents',
        permission: 'write_consent',
    },
});

const {
    consentData,
    form,
    saveConsent,
    fetchConsent,
    isLoading,
    errors,
} = useConsent();

const { getUrl } = useStatic();
const refVForm = ref();
const image = ref([]);

const imageSrc = computed(() => {
    if (image.value[0]) {
        return URL.createObjectURL(image.value[0]);
    }
    return getUrl(form.value.image) || noImage;
});

const onSubmit = async () => {
    if (!refVForm.value) return;
    const { valid: isValid } = await refVForm.value.validate();
    if (isValid) {
        form.value.image = image.value ? image.value[0] : null;
        await saveConsent();
    }
};

const consentId = ref(props.id);

const setConsent = async () => {
    if (consentId.value) {
        await fetchConsent(consentId.value).then(() => {
            form.value = consentData.value;
            form.value.isLoading = false;
        });
    } else {
        form.value.isLoading = false;
    }
};

watch(() => form.value.image_label, (newValue) => {
    if (!newValue) {
        form.value.image = null;
    }
}
);

watchEffect(setConsent);

</script>

<template>
    <AppCardActions :title="props.id ? $t('Edit Consent') : $t('Create Consent')" no-actions
        v-model:loading="form.isLoading">
        <VCardText>
            <VForm :loading="form.loading" ref="refVForm">
                <VRow>

                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                            </VCol>
                            <VCol cols="12" md="9" class="d-flex align-items-center justify-center">
                                <VAvatar rounded="lg" size="100" class="me-6 mb-2" :image="imageSrc" />
                            </VCol>
                        </VRow>
                    </VCol>

                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis" for="image">
                                    {{ $t('Image') }}
                                </label>
                            </VCol>

                            <VCol cols="12" md="9">
                                <VFileInput v-if="!form.image_label" v-model="image" accept="image/*" show-size
                                    prepend-icon="tabler-camera" :error-messages="errors.image"
                                    :label="$t('Choose an Image (PNG, JPG, JPEG)')" />
                                <AppTextField v-else id="image" v-model="form.image_label" persistent-placeholder
                                    readonly clearable />
                            </VCol>
                        </VRow>
                    </VCol>
                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis" for="name">{{ $t('Name')
                                    }}</label>
                            </VCol>

                            <VCol cols="12" md="9">
                                <AppTextField id="name" v-model="form.name" persistent-placeholder
                                    :rules="[requiredValidator]" :error-messages="errors.name" />
                            </VCol>
                        </VRow>
                    </VCol>

                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis" for="content">{{ $t('Content')
                                    }}</label>
                            </VCol>

                            <VCol cols="12" md="9">
                                <AppTextarea id="content" v-model="form.content" auto-grow />
                            </VCol>
                        </VRow>
                    </VCol>

                    <VCol offset-md="1" cols="12" class="d-flex gap-4 justify-center">
                        <VBtn :loading="isLoading" @click="onSubmit">
                            {{ $t('Save') }}
                        </VBtn>
                        <VBtn color="secondary" type="reset" variant="tonal">
                            {{ $t('Reset') }}
                        </VBtn>
                    </VCol>
                </VRow>
            </VForm>
        </VCardText>
    </AppCardActions>
</template>