<script setup>
import { ref, defineAsyncComponent, onErrorCaptured } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { $versionCode } from '@/utils/version.js';

definePage({
    meta: {
        isRoute: true,
        navActiveLink: "patients",
        userTypes: ['doctor', 'user'],
        permission: 'write_patient',
    },
});

const router = useRouter();
const error = ref(null);

onErrorCaptured((e) => {
    error.value = e;
    return true;
});

const dynamicComponent = defineAsyncComponent(() => {
    if (error.value) {
        console.error('Error loading patient form:', error.value);
        router.push({ name: 'error', params: { error: error.value.message || 'Unknown error' } });
        return Promise.reject(error.value);
    }

    return import(`@/pages/patients/components/Forms/${$versionCode()}/index.vue`)
        .catch((e) => {
            console.error(`Failed to load component for version ${$versionCode()}:`, e);
            error.value = new Error(`Unsupported version: ${$versionCode()}`);
            router.push({ name: 'error', params: { error: error.value.message } });
            return Promise.reject(error.value);
        });
});

</script>

<template>
    <Suspense>
        <template #default>
            <component :is="dynamicComponent" />
        </template>

        <template #fallback>
            <div class="loading-container">
                <VProgressCircular indeterminate color="primary" :size="50"></VProgressCircular>
            </div>
        </template>
    </Suspense>
</template>