<script setup>
import { useConsent } from "@/composables/Tenant/useConsent";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";
import RestoreConfirmation from "@/components/RestoreConfirmation.vue";
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import {useAccessControl} from '@/composables/useAccessControl';
import { useRouter } from "vue-router";
import { ref, watchEffect, computed } from "vue";

definePage({
    meta: {
        isRoute: true,
        userTypes: ['doctor', 'user'],
        permission: 'read_consent',
    },
});

const vuetifyDisplays = useDisplay();
const { canAccess } = useAccessControl();
const { t } = useI18n();
const router = useRouter();
const consents = ref([]);
const totalConsents = ref(0);
const searchQuery = ref("");
const itemsPerPage = ref(10);
const page = ref(1);
const sortBy = ref();
const orderBy = ref();

const updateOptions = (options) => {
    fetchingConsents.value = true;
    page.value = options.page;
    sortBy.value = options.sortBy[0]?.key;
    orderBy.value = options.sortBy[0]?.order;
};

const { fetchConsents, fetchingConsents, destroyConsent, recoverConsent, isLoading } = useConsent();

const refreshConsents = () => {
    fetchConsents({
        query: {
            q: searchQuery.value,
            itemsPerPage: itemsPerPage.value,
            page: page.value,
            sortBy: sortBy.value,
            orderBy: orderBy.value,
            isDeleted: true,
        },
    })
        .then((response) => {
            fetchingConsents.value = false;
            consents.value = response.data.consents;
            totalConsents.value = response.data.totalConsents;

        })
        .catch((error) => {
            console.error(error);
        }).finally(() => {
            isLoading.value = false;
        });
};

watchEffect(refreshConsents);

const headers = computed(() => {
    let baseHeaders = [{ title: t("Name"), value: "name", order: 1 }];

    const smAndUpHeaders = [
        { title: t("Content"), value: "content", order: 2 },
    ];

    if (vuetifyDisplays.smAndUp.value) {
        baseHeaders = [...baseHeaders, ...smAndUpHeaders];
    }

    const actionsHeader = { title: t("Actions"), value: "actions", sortable: false, align: "end", order: 3 };

    if (canAccess.byPermission('write_consent') || canAccess.byPermission('delete_consent')) {
        baseHeaders.push(actionsHeader);
    }

    baseHeaders.sort((a, b) => a.order - b.order);

    return baseHeaders;
});

const addNewConsent = () => {
    router.push({ name: "consents-add" });
};

const deleteConsent = async (id) => {
    await destroyConsent(id).then(() => { 
        refreshConsents();
    hideDeleteDialog();
 });
};

const restoreConsent = async (id) => {
    await recoverConsent(id).then(() => {
        refreshConsents();
        hideRestoreDialog();
    });
};

const isDeleteDialogVisible = ref(false);
const selectedId = ref(null);
const deleteMessage = computed(() => {
    const consent = consents.value.find(c => c.id === selectedId.value);
    return consent ? t('deleteConsentMessage', { name: consent.name }) : '';
});

const showDeleteDialog = (id) => {
    selectedId.value = id;
    isDeleteDialogVisible.value = true;
}

const hideDeleteDialog = () => {
    isDeleteDialogVisible.value = false;
}

const isRestoreDialogVisible = ref(false);
const restoreMessage = computed(() => {
    const consent = consents.value.find(p => p.id === selectedId.value);
    return consent ? t('restoreConsentMessage', { name: consent.name }) : '';
});

const showRestoreDialog = (id) => {
    selectedId.value = id;
    isRestoreDialogVisible.value = true;
}

const hideRestoreDialog = () => {
    isRestoreDialogVisible.value = false;
}
</script>

<template>
    <section>
        <VRow>
            <VCol cols="12">
                <VCard :title="$t('Deleted Consent List')">
                    <VCardText class="d-flex flex-wrap py-4 gap-4">
                        <div class="me-3 d-none d-sm-flex gap-3">
                            <AppSelect :model-value="itemsPerPage" :items="[
                            { value: 10, title: '10' },
                            { value: 25, title: '25' },
                            { value: 50, title: '50' },
                            { value: 100, title: '100' },
                            { value: -1, title: 'All' },
                        ]" style="inline-size: 6.25rem" @update:model-value="itemsPerPage = parseInt($event, 10)" />
                        </div>
                        <VSpacer />

                        <div class="d-flex align-center flex-wrap gap-4">
                            <div style="inline-size: 10rem">
                                <AppTextField v-model="searchQuery" :placeholder="$t('Search')" density="compact" />
                            </div>
                        </div>
                    </VCardText>

                    <VDivider />

                    <VDataTableServer loading-text="Loading" :loading="fetchingConsents" :sticky="true"
                        v-model:items-per-page="itemsPerPage" v-model:page="page" :items="consents"
                        :items-length="totalConsents" :headers="headers" class="text-no-wrap"
                        @update:options="updateOptions">

                        <template #item.name="{ item }">
                            <div class="d-flex align-center gap-4">
                                <span class="text-truncate" style="max-width: 150px;">{{ item.name || '-' }}</span>
                            </div>
                        </template>

                        <template #item.content="{ item }">
                            <div class="d-flex align-center">
                                <span>{{ item.content ?? '-' }}</span>
                            </div>
                        </template>

                        <template #item.actions="{ item }">
                            <div class="align-end">
                                <IconBtn v-if="canAccess.byPermission('write_consent') " @click="showRestoreDialog(item.id)">
                                    <VIcon icon="tabler-restore" />
                                </IconBtn>

                                <IconBtn v-if="canAccess.byPermission('delete_consent')" @click="showDeleteDialog(item.id)">
                                    <VIcon icon="tabler-trash" />
                                </IconBtn>
                            </div>
                        </template>
                    </VDataTableServer>
                </VCard>
            </VCol>
        </VRow>
    </section>
    <DeleteConfirmation :is-dialog-visible="isDeleteDialogVisible" :id="selectedId" :message="deleteMessage"
        @delete="deleteConsent($event)" @cancel="hideDeleteDialog" />

    <RestoreConfirmation :is-dialog-visible="isRestoreDialogVisible" :id="selectedId" :message="restoreMessage"
        @restore="restoreConsent($event)" @cancel="hideRestoreDialog" />
</template>

<style lang="scss">
.text-capitalize {
    text-transform: capitalize;
}

.consent-list-name:not(:hover) {
    color: rgba(var(--v-theme-on-background), var(--v-medium-emphasis-opacity));
}

.align-right {
    text-align: right;
}

.align-end,
.v-data-table__th:last-child {
    display: flex !important;
    justify-content: flex-end !important;
}
</style>