import { defineStore } from "pinia";
import axios from "axios";
import { encryptedStorage } from "../@core/composable/encryptedStorage";

export const useShortcutsStore = defineStore("shortcuts", {
    state: () => ({
        shortcuts: [],
        isReady: false,
        error: null,
    }),
    actions: {
        async initialize() {
            try {
                const response = await axios.get("/api/shortcuts");
                this.shortcuts = response.data;
                this.isReady = true;
                this.error = null;
            } catch (error) {
                console.error("Error fetching shortcuts:", error);
                this.error =
                    error instanceof Error
                        ? error.message
                        : "An error occurred";
                this.isReady = false;
                throw error;
            }
        },
        reset() {
            this.$reset();
            localStorage.removeItem("shortcuts");
        },
        addShortcut(shortcut) {
            this.shortcuts.push(shortcut);
        },
        removeShortcut(shortcutId) {
            this.shortcuts = this.shortcuts.filter((s) => s.id !== shortcutId);
        },
    },
    getters: {
        getShortcutById: (state) => (id) => {
            return state.shortcuts.find((shortcut) => shortcut.id === id);
        },
    },
    persist: {
        storage: encryptedStorage,
    },
});