<script setup>
import { useUser } from "@/composables/Central/useUser";
import { useApp } from "@/composables/Tenant/useApp";
import General from '@/pages/account/components/General.vue';
import Doctor from '@/pages/account/components/Doctor.vue';
import Billing from '@/pages/account/components/Billing.vue'
import Security from '@/pages/account/components/Security.vue';
import { usePermissionsStore } from "@/stores/usePermissionsStore";
import { useRoute } from 'vue-router';
import { computed, onMounted } from 'vue';

definePage({
  meta: {
    isRoute: true,
  },
});

const permissionsStore = usePermissionsStore();

const route = useRoute();

const activeTab = computed({
  get: () => route.params.tab,
  set: () => route.params.tab,
})

const { app, appUpdated, fetchApp, saveApp, appLoading, appErrors } = useApp();
const { user, userUpdated, password, passwordUpdated, fetchLoggedUser, updateUser, updatePassword, userErrors, userLoading, isDialogVisible } = useUser();

const tabs = [
  {
    title: 'General',
    icon: 'tabler-home',
    tab: 'general',
    order: 0,
  },
  {
    title: 'Security',
    icon: 'tabler-lock',
    tab: 'security',
    order: 3,
  },
];

const ownerTabs = [
  {
    title: 'Doctor',
    icon: 'tabler-stethoscope',
    tab: 'doctor',
    order: 1,
  },
  {
    title: 'Billing',
    icon: 'tabler-file-text',
    tab: 'billing',
    order: 4,
  }
];

onMounted(fetchLoggedUser);
if (permissionsStore.isOwner) {
  tabs.push(...ownerTabs);
  tabs.sort((a, b) => a.order - b.order);
  onMounted(fetchApp);
}

const onSubmitGeneral = async () => {
  await updateUser(user.value.id);
}

const onSubmitDoctor = async () => {
  await saveApp(user.value.id);
}

const onSubmitSecurity = async () => {
  await updatePassword(user.value.id);
}

</script>

<template>
  <VRow>
    <VCol cols="12" md="2">
      <!-- <h6 class="text-h6 mb-4 text-center">
        {{$t('Account Settings')}}
      </h6> -->
      <VTabs v-model="activeTab" direction="vertical" class="v-tabs-pill disable-tab-transition">
        <VTab v-for="item in tabs" :key="item.icon" :value="item.tab"
          :to="{ name: 'account-tab', params: { tab: item.tab } }">
          <VIcon size="20" start :icon="item.icon" />
          {{ $t(item.title) }}
        </VTab>
      </VTabs>
    </VCol>

    <VCol cols="12" md="10">
      <VWindow v-model="activeTab" class="disable-tab-transition" :touch="false">
        <VWindowItem value="general">
          <General v-if="user" :user="user" :userUpdated="userUpdated" @update:user="user = $event"
            @submit="onSubmitGeneral" :errors="userErrors" :isLoading="userLoading"
            v-model:isDialogVisible="isDialogVisible" @openDialog="isDialogVisible = true"
            @closeDialog="isDialogVisible = false" />
          <VSkeletonLoader v-else min-height="500" loading-text="Loading" type="card" class="fill-height" />
        </VWindowItem>

        <VWindowItem value="doctor">
          <Doctor v-if="app && permissionsStore.isOwner" :app="app" :appUpdated="appUpdated" @update:app="app = $event"
            @submit="onSubmitDoctor" :errors="appErrors" :isLoading="appLoading" />
          <VSkeletonLoader v-else min-height="500" loading-text="Loading" type="card" class="fill-height" />
        </VWindowItem>

        <VWindowItem value="security">
          <Security :password="password" :passwordUpdated="passwordUpdated" :isLoading="userLoading"
            :errors="userErrors" @submit="onSubmitSecurity" />
        </VWindowItem>

        <VWindowItem value="billing">
          <Billing v-if="permissionsStore.isOwner" />
        </VWindowItem>
      </VWindow>
    </VCol>
  </VRow>
</template>

<style lang="scss">
.my-class {
  padding: 1.25rem;
  border-radius: 0.375rem;
  background-color: rgba(var(--v-theme-on-surface), var(--v-hover-opacity));
}
</style>
