<script setup>
import { useUser } from "@/composables/Central/useUser";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import { useRouter } from "vue-router";
import { ref, watchEffect, computed } from "vue";

definePage({
    meta: {
        isRoute: true,
        ownerOnly: true,
        feature: 'user_management',
    },
});

const vuetifyDisplays = useDisplay();
const { t } = useI18n();
const router = useRouter();
const users = ref([]);
const totalUsers = ref(0);
const searchQuery = ref("");
const itemsPerPage = ref(10);
const page = ref(1);
const sortBy = ref();
const orderBy = ref();
const alert = ref();
const isLimitReached = ref(false);

const updateOptions = (options) => {
    users.value = [];
    page.value = options.page;
    sortBy.value = options.sortBy[0]?.key;
    orderBy.value = options.sortBy[0]?.order;
};

const { fetchUsers, fetchingUsers, destroyUser } = useUser();

const refreshUsers = () => {
    fetchUsers({
        query: {
            q: searchQuery.value,
            itemsPerPage: itemsPerPage.value,
            page: page.value,
            sortBy: sortBy.value,
            orderBy: orderBy.value,
        },
    })
        .then((response) => {
            fetchingUsers.value = false;
            users.value = response.data.users;
            totalUsers.value = response.data.total;
            alert.value = response.data.alert;
            isLimitReached.value = response.data.isLimitReached;
        })
        .catch((error) => {
            console.error(error);
        });
};

watchEffect(refreshUsers);

const headers = computed(() => {
    let baseHeaders = [];

    const mdLgHeaders = [
        { title: t("First Name"), value: "first_name", sortable: false, order: 1 },
        { title: t("Last Name"), value: "last_name", sortable: false, order: 2 },
        { title: t("Email"), value: "email", order: 4 },
        { title: t("Phone Number"), value: "phone_number", order: 5 },
        { title: t("Status"), value: "status", order: 6 },
    ];

    const smAndUpHeaders = [
        { title: t("Role"), value: "role_name", order: 3 },
    ];

    const smHeaders = [
        { title: t("Full Name"), value: "full_name", sortable: false, order: 0 },
    ];

    if (vuetifyDisplays.smAndUp.value) {
        baseHeaders = [...baseHeaders, ...smAndUpHeaders];
    }

    if (vuetifyDisplays.mdAndUp.value) {
        baseHeaders.push(...mdLgHeaders);
    }

    if (vuetifyDisplays.smAndDown.value) {
        baseHeaders.push(...smHeaders);
    }

    const actionsHeader = { title: t("Actions"), value: "actions", sortable: false, align: "end", order: 7 };

    baseHeaders.push(actionsHeader);

    baseHeaders.sort((a, b) => a.order - b.order);

    return baseHeaders;
});

const addNewUser = () => {
    router.push({ name: "users-add" });
};

const deleteUser = async (id) => {
    await destroyUser(id).then(() => {
        refreshUsers();
        hideDeleteDialog();
    });
};

const editUser = (id) => {
    router.push({ name: "users-edit-id", params: { id: id } });
};

const isDeleteDialogVisible = ref(false);
const selectedId = ref(null);
const deleteMessage = computed(() => {
    const user = users.value.find(u => u.id === selectedId.value);
    return user ? t('deleteUserMessage', { name: user.full_name }) : '';
});

const showDeleteDialog = (id) => {
    selectedId.value = id;
    isDeleteDialogVisible.value = true;
}

const hideDeleteDialog = () => {
    isDeleteDialogVisible.value = false;
}
</script>

<template>
    <section>
        <VRow>
            <VCol cols="12">
                <VAlert v-if="alert" :color="alert.type" variant="tonal" class="mb-2">
                    {{ alert.message }}
                </VAlert>
                <VCard :title="$t('User List')">
                    <VCardText class="d-flex flex-wrap py-4 gap-4">
                        <div class="me-3 d-none d-sm-flex gap-3">
                            <AppSelect :model-value="itemsPerPage" :items="[
                                { value: 10, title: '10' },
                                { value: 25, title: '25' },
                                { value: 50, title: '50' },
                                { value: 100, title: '100' },
                                { value: -1, title: 'All' },
                            ]" style="inline-size: 6.25rem"
                                @update:model-value="itemsPerPage = parseInt($event, 10)" />
                        </div>
                        <VSpacer />

                        <div class="d-flex align-center flex-wrap gap-4">
                            <div style="inline-size: 10rem">
                                <AppTextField v-model="searchQuery" :placeholder="$t('Search')" density="compact" />
                            </div>

                            <VBtn class="d-none d-md-flex" variant="tonal" color="secondary"
                                prepend-icon="tabler-reload" @click="refreshUsers">
                                {{ $t('Refresh') }}
                            </VBtn>

                            <VBtn :disabled="isLimitReached" @click="addNewUser">
                                <VIcon :icon="!isLimitReached ? 'tabler-plus' : 'tabler-lock'" size="22" class="mr-1" />
                                <span class="d-none d-md-flex">{{ $t('Add User') }}</span>
                            </VBtn>
                        </div>
                    </VCardText>

                    <VDivider />
                    <VDataTableServer loading-text="Loading" :loading="fetchingUsers" :sticky="true"
                        v-model:items-per-page="itemsPerPage" v-model:page="page" :items="users"
                        :items-length="totalUsers" :headers="headers" class="text-no-wrap"
                        @update:options="updateOptions">

                        <template #item.full_name="{ item }">
                            <div class="d-flex align-center">
                                <span>{{ item.first_name + " " + item.last_name }}</span>
                            </div>
                        </template>

                        <template #item.phone_number="{ item }">
                            <div class="d-flex align-center">
                                <span>{{ item.phone_number ?? '-' }}</span>
                            </div>
                        </template>

                        <template #item.role_name="{ item }">
                            <div class="d-flex align-center gap-4">
                                <VChip label color="primary">
                                    {{ item.role_name }}
                                </VChip>
                            </div>
                        </template>

                        <template #item.status="{ item }">
                            <div class="d-flex align-center">
                                <VChip v-if="item.status" color="success">
                                    {{ $t('Confirmed') }}
                                </VChip>
                                <VChip v-else color="error">
                                    {{ $t('Not Confirmed') }}
                                </VChip>
                            </div>
                        </template>

                        <template #item.actions="{ item }">
                            <div class="align-end">
                                <IconBtn @click="editUser(item.id)">
                                    <VIcon icon="tabler-edit" />
                                </IconBtn>

                                <IconBtn @click="showDeleteDialog(item.id)">
                                    <VIcon icon="tabler-trash" />
                                </IconBtn>
                            </div>
                        </template>
                    </VDataTableServer>
                </VCard>
            </VCol>
        </VRow>
    </section>
    <DeleteConfirmation :is-dialog-visible="isDeleteDialogVisible" :id="selectedId" :message="deleteMessage"
        @delete="deleteUser($event)" @cancel="hideDeleteDialog" />
</template>

<style lang="scss">
.text-capitalize {
    text-transform: capitalize;
}

.user-list-name:not(:hover) {
    color: rgba(var(--v-theme-on-background), var(--v-medium-emphasis-opacity));
}

.align-right {
    text-align: right;
}

.align-end,
.v-data-table__th:last-child {
    display: flex !important;
    justify-content: flex-end !important;
}
</style>