<script setup>
import { useDiagnostic } from "@/composables/Tenant/useDiagnostic";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import { useRouter } from "vue-router";
import { useAccessControl } from '@/composables/useAccessControl';
import { ref, watchEffect, computed } from "vue";

definePage({
    meta: {
        isRoute: true,
        permission: 'read_diagnostic',
    },
});

const vuetifyDisplays = useDisplay();
const { canAccess } = useAccessControl();
const { t } = useI18n();
const router = useRouter();
const diagnostics = ref([]);
const totalDiagnostics = ref(0);
const searchQuery = ref("");
const itemsPerPage = ref(10);
const page = ref(1);
const sortBy = ref('id');
const orderBy = ref('desc');
const alert = ref();
const isLimitReached = ref(false);

const updateOptions = (options) => {
    fetchingDiagnostics.value = true;
    page.value = options.page;
    sortBy.value = options.sortBy[0]?.key;
    orderBy.value = options.sortBy[0]?.order;
};

const { fetchDiagnostics, fetchingDiagnostics, softDestroyDiagnostic } = useDiagnostic();

const refreshDiagnostics = () => {
    fetchDiagnostics({
        query: {
            q: searchQuery.value,
            itemsPerPage: itemsPerPage.value,
            page: page.value,
            sortBy: sortBy.value,
            orderBy: orderBy.value,
            isDeleted: false,
        },
    })
        .then((response) => {
            fetchingDiagnostics.value = false;
            diagnostics.value = response.data.diagnostics;
            totalDiagnostics.value = response.data.totalDiagnostics;
            alert.value = response.data.alert;
            isLimitReached.value = response.data.isLimitReached;
        })
        .catch((error) => {
            console.error(error);
        });
};

watchEffect(refreshDiagnostics);

const headers = computed(() => {
    let baseHeaders = [{ title: t("Name"), value: "name", order: 1 }];

    const smAndUpHeaders = [
        { title: t("History"), value: "history", order: 2 },
    ];

    if (vuetifyDisplays.smAndUp.value) {
        baseHeaders = [...baseHeaders, ...smAndUpHeaders];
    }

    const actionsHeader = { title: t("Actions"), value: "actions", sortable: false, align: "end", order: 3 };

    if (canAccess.byPermission('write_diagnostic') || canAccess.byPermission('delete_diagnostic')) {
        baseHeaders.push(actionsHeader);
    }

    baseHeaders.sort((a, b) => a.order - b.order);

    return baseHeaders;
});

const addNewDiagnostic = () => {
    router.push({ name: "diagnostics-add" });
};

const deleteDiagnostic = async (id) => {
    await softDestroyDiagnostic(id).then(() => {
        refreshDiagnostics();
        hideDeleteDialog();
    });
};

const editDiagnostic = (id) => {
    router.push({ name: "diagnostics-edit-id", params: { id: id } });
};

const isDeleteDialogVisible = ref(false);
const selectedId = ref(null);
const deleteMessage = computed(() => {
    const diagnostic = diagnostics.value.find(p => p.id === selectedId.value);
    return diagnostic ? t('deleteDiagnosticMessage', { name: diagnostic.name }) : '';
});

const showDeleteDialog = (id) => {
    selectedId.value = id;
    isDeleteDialogVisible.value = true;
}

const hideDeleteDialog = () => {
    isDeleteDialogVisible.value = false;
}

</script>

<template>
    <section>
        <VRow>
            <VCol cols="12">
                <VAlert v-if="alert" :color="alert.type" variant="tonal" class="mb-2">
                    {{ alert.message }}
                </VAlert>
                <VCard :title="$t('Diagnostic List')">
                    <VDivider />

                    <VCardText class="d-flex flex-wrap py-4 gap-4">

                        <div class="me-3 d-none d-sm-flex gap-3">
                            <AppSelect :model-value="itemsPerPage" :items="[{ value: 10, title: '10' },
                            { value: 25, title: '25' },
                            { value: 50, title: '50' },
                            { value: 100, title: '100' },
                            { value: -1, title: 'All' },]" style="inline-size: 6.25rem"
                                @update:model-value="itemsPerPage = parseInt($event, 10)" />
                        </div>

                        <VSpacer />

                        <div class="d-flex align-center flex-wrap gap-4">
                            <div style="inline-size: 10rem">
                                <AppTextField v-model="searchQuery" :placeholder="$t('Search')" density="compact" />
                            </div>

                            <VBtn class="d-none d-md-flex" variant="tonal" color="secondary"
                                prepend-icon="tabler-reload" @click="refreshDiagnostics">
                                {{ $t('Refresh') }}
                            </VBtn>

                            <VBtn :disabled="isLimitReached" v-if="canAccess.byPermission('write_diagnostic')"
                                @click="addNewDiagnostic">
                                <VIcon :icon="!isLimitReached ? 'tabler-plus' : 'tabler-lock'" size="22" class="mr-1" />
                                <span class="d-none d-md-flex">{{ $t('Add Diagnostic') }}</span>
                            </VBtn>

                        </div>
                    </VCardText>

                    <VDivider />

                    <VDataTableServer v-model:items-per-page="itemsPerPage" loading-text="Loading"
                        :loading="fetchingDiagnostics" v-model:page="page" :sticky="true" :items="diagnostics"
                        :items-length="totalDiagnostics" :headers="headers" class="text-no-wrap"
                        @update:options="updateOptions">

                        <template #item.history="{ item }">
                            <div class="d-flex align-center">
                                <span>{{ item.history ?? '-' }}</span>
                            </div>
                        </template>

                        <template #item.actions="{ item }">
                            <div class="align-end">
                                <IconBtn v-if="canAccess.byPermission('write_diagnostic')"
                                    @click="editDiagnostic(item.id)">
                                    <VIcon icon="tabler-edit" />
                                </IconBtn>

                                <IconBtn v-if="canAccess.byPermission('delete_diagnostic')"
                                    @click="showDeleteDialog(item.id)">
                                    <VIcon icon="tabler-trash" />
                                </IconBtn>
                            </div>
                        </template>
                    </VDataTableServer>
                </VCard>
            </VCol>
        </VRow>
    </section>
    <DeleteConfirmation :is-dialog-visible="isDeleteDialogVisible" :id="selectedId" :message="deleteMessage"
        :soft="true" @delete="deleteDiagnostic($event)" @cancel="hideDeleteDialog" />
</template>

<style lang="scss">
.text-capitalize {
    text-transform: capitalize;
}

.diagnostic-list-name:not(:hover) {
    color: rgba(var(--v-theme-on-background), var(--v-medium-emphasis-opacity));
}
</style>