<script setup>
import Form from "@/pages/patients/intervention/components/Form.vue";
import { useRoute } from "vue-router";

definePage({
    meta: {
        isRoute: true,
        navActiveLink: "patients",
        userTypes: ['doctor', 'user'],
        permission: 'write_patient',
    },
});

const route = useRoute();

</script>

<template>
    <Form :patientId="Number(route.params.id)" />
</template>