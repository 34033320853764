<script setup>
import { ref, defineAsyncComponent, onErrorCaptured } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/useUserStore';
import { $versionCode } from '@/utils/version.js';

definePage({
    meta: {
        isRoute: true,
        navActiveLink: "appointments",
        userTypes: ['doctor', 'user', 'patient'],
        permission: 'read_appointment',
        feature: 'scheduling_planning',
    },
});

const router = useRouter();
const userStore = useUserStore();
const error = ref(null);

onErrorCaptured((e) => {
    error.value = e;
    console.error('Caught an error:', e);
    return true;
});

const componentMap = {
    doctor: () => import(`./components/List/Doctor/${$versionCode()}.vue`),
    user: () => import(`./components/List/Doctor/${$versionCode()}.vue`),
    patient: () => import('./components/List/Patient/index.vue'),
};

const dynamicComponent = defineAsyncComponent(() => {
    if (error.value) {
        console.error('Error loading appointment list:', error.value);
        router.push({ name: 'error', params: { error: error.value.message || 'Unknown error' } });
        return Promise.reject(error.value);
    }

    const importFn = componentMap[userStore.type];
    if (!importFn) {
        const typeError = new Error(`Invalid user type: ${userStore.type}`);
        console.error(typeError);
        router.push({ name: 'error', params: { error: typeError.message } });
        return Promise.reject(typeError);
    }

    return importFn().catch((e) => {
        console.error(`Failed to load component for user type ${userStore.type}:`, e);
        router.push({ name: 'error', params: { error: e.message } });
        return Promise.reject(e);
    });
});
</script>

<template>
    <Suspense>
        <!-- Main content -->
        <template #default>
            <component :is="dynamicComponent" />
        </template>

        <!-- Loading state -->
        <template #fallback>
            <div class="loading-container">
                <VProgressCircular indeterminate color="primary" :size="50"></VProgressCircular>
            </div>
        </template>
    </Suspense>
</template>