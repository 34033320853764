import axios from "axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

window.axios = axios;

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
    (config) => {
        if (
            config.method.toLowerCase() !== "get" &&
            config.url !== "/api/log-error"
        ) {
            NProgress.start();
        }
        const token = getCookie("accessToken");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        const xsrfToken = getCookie("XSRF-TOKEN");
        if (xsrfToken) {
            config.headers["X-XSRF-TOKEN"] = decodeURIComponent(xsrfToken);
        }

        return config;
    },
    (error) => {
        NProgress.done();
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        if (response.config.method.toLowerCase() !== "get" && response.config.url !== '/api/log-error') {
            NProgress.done();
        }

        const cookies = response.headers["set-cookie"];
        if (cookies) {
            const xsrfToken = cookies.find((cookie) =>
                cookie.startsWith("XSRF-TOKEN=")
            );
            if (xsrfToken) {
                const token = xsrfToken.split(";")[0].split("=")[1];
                const decodedToken = decodeURIComponent(token);
                axios.defaults.headers.common["X-XSRF-TOKEN"] = decodedToken;
            }
        }

        return response;
    },
    (error) => {
        if (error.config && error.config.method.toLowerCase() !== "get" && error.config.url !== '/api/log-error') {
            NProgress.done();
        }

        if (error.response) {
            if (error.response.status === 500) {
                logServerError(error);
            } else if (
                error.response.status === 401 ||
                error.response.status === 400
            ) {
                document.cookie.split(";").forEach((c) => {
                    document.cookie = c
                        .replace(/^ +/, "")
                        .replace(
                            /=.*/,
                            "=;expires=" + new Date().toUTCString() + ";path=/"
                        );
                });

                localStorage.clear();

                window.location.href = "/login";
            }
        }

        return Promise.reject(error);
    }
);

function logServerError(error) {
    const errorData = {
        message: error.message || "Unknown error",
        url: error.config?.url || "Unknown URL",
        method: error.config?.method || "Unknown method",
        status: error.response?.status || "Unknown status",
        statusText: error.response?.statusText || "Unknown status text",
        headers: JSON.stringify(error.config?.headers || {}),
        data: JSON.stringify(error.config?.data || {}),
        params: JSON.stringify(error.config?.params || {}),
        responseData: error.response
            ? JSON.stringify(error.response.data || {})
            : "No response data",
        stack: error.stack || "No stack trace",
        userAgent: navigator.userAgent || "Unknown user agent",
        line:
            error.stack && error.stack.split("\n")[1]
                ? error.stack.split("\n")[1].trim()
                : "Unknown line",
        column: "Unknown column",
    };

    axios
        .post('/api/log-error', errorData)
        .catch((logError) => {
            console.error("Failed to send server error log:", logError);
        });
}

export default function (app) {
    app.config.globalProperties.$axios = axios;
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
