<script setup>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import { VForm } from 'vuetify/components/VForm'
import { requiredValidator } from '@core/utils/validators.js';
import { ref, watch, computed, nextTick } from 'vue';

definePage({
  meta: {
    permission: 'write_calendar',
  },
});

const props = defineProps({
  isDrawerOpen: {
    type: Boolean,
    required: true,
  },
  event: {
    type: null,
    required: true,
  },
})

const emit = defineEmits([
  'update:isDrawerOpen',
  'addEvent',
  'updateEvent',
  'removeEvent',
])

const refForm = ref()

const event = ref(JSON.parse(JSON.stringify(props.event)));

const resetEvent = () => {
  event.value = JSON.parse(JSON.stringify(props.event))
  nextTick(() => {
    refForm.value?.resetValidation()
  })
}

watch(() => props.isDrawerOpen, resetEvent)

const removeEvent = () => {
  emit('removeEvent', String(event.value.id))
  emit('update:isDrawerOpen', false)
}

const handleSubmit = () => {
  refForm.value?.validate().then(({ valid }) => {
    if (valid) {

      if ('id' in event.value)
        emit('updateEvent', event.value)

      else
        emit('addEvent', event.value)

      emit('update:isDrawerOpen', false)
    }
  })
}

const onCancel = () => {

  emit('update:isDrawerOpen', false)
  nextTick(() => {
    refForm.value?.reset()
    resetEvent()
    refForm.value?.resetValidation()
  })
}

const startDateTimePickerConfig = computed(() => {
  const config = {
    enableTime: !event.value.allDay,
    dateFormat: `Y-m-d${event.value.allDay ? '' : ' H:i'}`,
    time_24hr: true,
  }

  if (event.value.end)
    config.maxDate = event.value.end

  return config
})

const endDateTimePickerConfig = computed(() => {
  const config = {
    enableTime: !event.value.allDay,
    dateFormat: `Y-m-d${event.value.allDay ? '' : ' H:i'}`,
    time_24hr: true
  }

  if (event.value.start)
    config.minDate = event.value.start

  return config
})

const dialogModelValueUpdate = val => {
  emit('update:isDrawerOpen', val)
}
</script>

<template>
  <VNavigationDrawer temporary location="end" :model-value="props.isDrawerOpen" width="420" class="scrollable-content"
    @update:model-value="dialogModelValueUpdate">
    <AppDrawerHeaderSection :title="event.id ? $t('Update Event') : $t('Add Event')"
      @cancel="$emit('update:isDrawerOpen', false)">
      <template #beforeClose>
        <IconBtn :disabled="event.extendedProps.type === 'Consultation'" v-show="event.id" @click="removeEvent">
          <VIcon size="18" icon="tabler-trash" />
        </IconBtn>
      </template>
    </AppDrawerHeaderSection>

    <PerfectScrollbar :options="{ wheelPropagation: false }">
      <VCard flat>
        <VCardText>
          <VForm ref="refForm" @submit.prevent="handleSubmit">
            <VRow>
              <VCol cols="12">
                <AppTextField v-model="event.title" :label="$t('Title')" :rules="[requiredValidator]"
                  :disabled="['Appointment', 'Consultation', 'Intervention'].includes(event.extendedProps.type)" />
              </VCol>

              <VCol cols="12">
                <AppTextField readonly v-model="event.label" :label="$t('Label')" :placeholder="$t(event.extendedProps.type)" />
              </VCol>


              <VCol
                v-if="['Appointment', 'Consultation', 'Intervention'].includes(event.extendedProps.type)"
                cols="12">
                <AppDateTimePicker v-model="event.start" :label="$t('Date')" :config="{
                  dateFormat: 'Y-m-d',
                }" />
              </VCol>

              <VCol v-else cols="12">
                <AppDateTimePicker utc :timezone="'UTC'" :key="JSON.stringify(startDateTimePickerConfig)"
                  v-model="event.start" :rules="[requiredValidator]" :label="$t('Start Date')"
                  :placeholder="$t('Select Start Date')" :config="startDateTimePickerConfig" />
              </VCol>

              <VCol v-if="event.extendedProps.type === 'Appointment'" cols="12">
                <AppDateTimePicker v-model="event.extendedProps.appointmentStartTime" :label="$t('Start Time')"
                  :placeholder="$t('Select Start Time')"
                  :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', time_24hr: true }" />
              </VCol>

              <VCol v-if="event.extendedProps.type === 'Appointment'" cols="12">
                <AppDateTimePicker v-model="event.extendedProps.appointmentEndTime" :label="$t('End Time')"
                  placeholder="Select End Time"
                  :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', time_24hr: true }" />
              </VCol>

              <VCol
                v-show="!['Appointment', 'Consultation', 'Intervention'].includes(event.extendedProps.type)"
                cols="12">
                <AppDateTimePicker :key="JSON.stringify(endDateTimePickerConfig)" v-model="event.end"
                  :rules="[requiredValidator]" :label="$t('End Date')" :placeholder="$t('Select End Date')"
                  :config="endDateTimePickerConfig" />
              </VCol>

              <VCol
                v-show="!['Appointment', 'Consultation', 'Intervention'].includes(event.extendedProps.type)"
                cols="12">
                <VSwitch v-model="event.allDay" :label="$t('All day')" />
              </VCol>

              <VCol cols="12">
                <VBtn type="submit" class="me-3">
                  {{$t('Save')}}
                </VBtn>
                <VBtn variant="outlined" color="secondary" @click="onCancel">
                  {{$t('Cancel')}}
                </VBtn>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </PerfectScrollbar>
  </VNavigationDrawer>
</template>
