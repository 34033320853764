<script setup>
import Form from "@/pages/users/components/Form.vue";

definePage({
    meta: {
        isRoute: true,
        navActiveLink: "users",
        userTypes: ['doctor'],
        ownerOnly: true,
        feature: 'user_management',
    },
});
</script>

<template>
    <Form />
</template>