<script setup>
import { useDoctor } from "@/composables/Tenant/useDoctor";
import { requiredValidator, requiredIfValidator, emailValidator } from '@core/utils/validators.js';
import { ref, watchEffect } from "vue";

const props = defineProps({
  id: {
    type: Number,
    required: false,
  },
});

definePage({
    meta: {
        navActiveLink: 'doctors',
        permission: 'write_doctor',
    },
});

const {
    form,
    saveDoctor,
    fetchDoctor,
    doctorData,
    isLoading,
    errors,
} = useDoctor();

const onSubmit = async () => {
    if (!refVForm.value) return;
    const { valid: isValid } = await refVForm.value.validate();
    if (isValid) {
        await saveDoctor();
    }
};

const doctorId = ref(props.id);

const setDoctor = async () => {
    if (doctorId.value) {
        await fetchDoctor(doctorId.value).then(() => {
            for (const key in doctorData.value) {
                if (doctorData.value.hasOwnProperty(key) && form.hasOwnProperty(key)) {
                    form[key] = doctorData.value[key];
                }
                form.is_anesthesiologist = form.is_anesthesiologist == 1;
            }
            form.isLoading = false;
        });
    } else {
        form.isLoading = false;
    }
};

watchEffect(setDoctor);

const refVForm = ref();

const setAnesthesiologist = () => {
    if (form.is_anesthesiologist) {
        form.designation = 'Anesthésiste';
        form.department = 'Anesthésiologie';
        errors.designation = undefined;
    } else {
        form.designation = '';
        form.department = '';
    }
}
</script>

<template>

    <AppCardActions :title="props.id ? $t('Edit Doctor') : $t('Create Doctor')" no-actions
        v-model:loading="form.isLoading">
        <VCardText>
            <VForm :loading="form.loading" ref="refVForm">
                <VRow>
                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis" for="first_name">
                                    {{ $t('First Name') }}</label>
                            </VCol>

                            <VCol cols="12" md="9">
                                <AppTextField id="first_name" v-model="form.first_name" persistent-placeholder
                                    :rules="[requiredValidator]" :error-messages="errors.first_name" />
                            </VCol>
                        </VRow>
                    </VCol>

                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis" for="last_name">
                                    {{ $t('Last Name') }}</label>
                            </VCol>

                            <VCol cols="12" md="9">
                                <AppTextField id="last_name" v-model="form.last_name" persistent-placeholder
                                    :rules="[requiredValidator]" :error-messages="errors.last_name" />
                            </VCol>
                        </VRow>
                    </VCol>

                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis" for="email">{{ $t('Email')
                                    }}</label>
                            </VCol>

                            <VCol cols="12" md="9">
                                <AppTextField id="email" v-model="form.email" persistent-placeholder
                                    :rules="[emailValidator]" :error-messages="errors.email" />
                            </VCol>
                        </VRow>
                    </VCol>

                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis" for="phone_number">
                                    {{ $t('Phone Number') }}</label>
                            </VCol>

                            <VCol cols="12" md="9">
                                <AppTextField id="phone_number" v-model="form.phone_number" persistent-placeholder
                                    :error-messages="errors.phone_number" />
                            </VCol>
                        </VRow>
                    </VCol>

                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis" for="name">
                                    {{ $t('Type')}}
                                </label>
                            </VCol>

                            <VCol cols="12" md="9">
                                <VRadioGroup @change="setAnesthesiologist" v-model="form.is_anesthesiologist">
                                    <VRadio :label="$t('Anesthesiologist')" :value="true" />
                                    <VRadio :label="$t('Other')" :value="false" />
                                </VRadioGroup>
                            </VCol>
                        </VRow>
                    </VCol>

                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis" for="designation">
                                    {{ $t('Designation') }}
                                </label>
                            </VCol>

                            <VCol cols="12" md="9">
                                <AppTextField :disabled="form.is_anesthesiologist" id="designation"
                                    v-model="form.designation" persistent-placeholder
                                    :rules="[requiredIfValidator(!form.is_anesthesiologist, form.designation)]"
                                    :error-messages="errors.designation" />
                            </VCol>
                        </VRow>
                    </VCol>

                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis" for="department">{{
                                    $t('Department') }}</label>
                            </VCol>

                            <VCol cols="12" md="9">
                                <AppTextField :disabled="form.is_anesthesiologist" id="department"
                                    v-model="form.department" persistent-placeholder />
                            </VCol>
                        </VRow>
                    </VCol>


                    <VCol offset-md="1" cols="12" class="d-flex gap-4 justify-center">
                        <VBtn :loading="isLoading" @click="onSubmit">
                            {{ $t('Save') }}
                        </VBtn>
                        <VBtn color="secondary" type="reset" variant="tonal">
                            {{ $t('Reset') }}
                        </VBtn>
                    </VCol>
                </VRow>
            </VForm>
        </VCardText>
    </AppCardActions>
</template>