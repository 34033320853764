<script setup>
import { onMounted, onBeforeUnmount, ref } from 'vue';
import { useTheme } from 'vuetify';
import ScrollToTop from '@core/components/ScrollToTop.vue';
import { useThemeStore } from '@/stores/useThemeStore';
import { hexToRgb } from '@layouts/utils';
import Notivue from '@/layouts/components/Notivue.vue';
import { useInitializationStore } from '@/stores/useInitializationStore';
import initCore from './@core/initCore';

const { global } = useTheme();
const themeStore = useThemeStore();
const initializationStore = useInitializationStore();

const isLoading = ref(true);

onMounted(() => {
  initCore();
  themeStore.initializeTheme();
  isLoading.value = false;
});

onBeforeUnmount(() => {
  initializationStore.stopRefreshTimer();
});
</script>

<template>
  <VLocaleProvider :rtl="themeStore.isAppRTL">
    <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
      <Notivue />
      <template v-if="!isLoading">
        <RouterView />
      </template>
      <div v-else class="d-flex justify-center align-center" style="height: 100vh;">
        <VProgressCircular indeterminate color="primary" />
      </div>
      <ScrollToTop />
    </VApp>
  </VLocaleProvider>
</template>