<script setup>
import { useTechnique } from "@/composables/Tenant/useTechnique";
import { requiredValidator } from '@core/utils/validators.js';
import { ref, watchEffect } from "vue";
import { useRoute } from "vue-router";

const props = defineProps({
    id: {
        type: Number,
        required: false,
    },
});

definePage({
    meta: {
        navActiveLink: 'techniques',
        permission: 'write_technique',
    },
});

const {
    form,
    techniqueData,
    fetchTechnique,
    saveTechnique,
    isLoading,
    errors,
} = useTechnique();

const route = useRoute();

const techniqueId = props.id;

const setTechnique = async () => {
    if (techniqueId) {
        await fetchTechnique(techniqueId).then( () =>{
            for (const key in techniqueData.value) {
                if (techniqueData.value.hasOwnProperty(key) && form.hasOwnProperty(key)) {
                    form[key] = techniqueData.value[key];
                }
            }
            form.isLoading = false;
        });
    }else{
         form.isLoading = false;
    }
};

watchEffect(setTechnique);

const onSubmit = async () => {
    if (!refVForm.value) return;
    const { valid: isValid } = await refVForm.value.validate();
    if (isValid) {
        await saveTechnique();
    }
};

const refVForm = ref();

</script>

<template>
    <AppCardActions :title="props.id ? $t('Edit Technique') : $t('Create Technique')" no-actions
        v-model:loading="form.isLoading">
        <VCardText>
            <VForm ref="refVForm">
                <VRow>
                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis" for="name">{{$t('Name')}}</label>
                            </VCol>

                            <VCol cols="12" md="9">
                                <AppTextField id="name" v-model="form.name" persistent-placeholder
                                    :rules="[requiredValidator]" :error-messages="errors.name" />
                            </VCol>
                        </VRow>
                    </VCol>

                    <VCol cols="12">
                        <VRow no-gutters>
                            <VCol cols="12" md="3" class="d-flex align-items-center">
                                <label class="v-label text-body-2 text-high-emphasis"
                                    for="operative_report">{{$t('Operative Report')}}</label>
                            </VCol>

                            <VCol cols="12" md="9">
                                <AppTextarea auto-grow id="operative_report" v-model="form.operative_report"
                                    persistent-placeholder :error-messages="errors.operative_report" />
                            </VCol>
                        </VRow>
                    </VCol>

                    <VCol offset-md="1" cols="12" class="d-flex gap-4 justify-center">
                        <VBtn :loading="isLoading" @click="onSubmit">
                            {{$t('Save')}}
                        </VBtn>
                        <VBtn color="secondary" type="reset" variant="tonal">
                            {{$t('Reset')}}
                        </VBtn>
                    </VCol>
                </VRow>
            </VForm>
        </VCardText>
    </AppCardActions>
</template>