<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { useDisplay } from 'vuetify';
import { useI18n } from 'vue-i18n';
import { v4 as uuidv4 } from 'uuid';
import ChatMenu from './components/ChatMenu.vue';
import LoaderAnimation from './components/LoaderAnimation.vue';
import ChatMessages from './components/ChatMessages.vue';
import ChatInput from './components/ChatInput.vue';

definePage({
    meta: {
        isRoute: true,
        navActiveLink: "chatbot",
        userTypes: ['doctor'],
    },
});

const vuetifyDisplays = useDisplay();
const { locale } = useI18n({ useScope: 'global' });

const isMenuOpen = ref(vuetifyDisplays.mdAndUp);
const isChatActive = ref(false);
const message = ref('');
const messages = ref([]);
const isTyping = ref(false);
const sessionId = ref('');

const generateSessionId = () => {
    sessionId.value = uuidv4();
};

const clearChatHistory = async () => {
    try {
        await fetch('https://ai.medsyn.solutions/api/clear-chat', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                session_id: sessionId.value
            }),
            mode: 'cors',
        });
        messages.value = [];
    } catch (error) {
        console.error('Error clearing chat history:', error);
    }
};

const toggleMenu = () => {
    isMenuOpen.value = !isMenuOpen.value;
};

const activateChat = () => {
    isChatActive.value = true;
    if (vuetifyDisplays.smAndDown.value) {
        isMenuOpen.value = false;
    }
    generateSessionId();
};

const deactivateChat = async () => {
    await clearChatHistory();
    isChatActive.value = false;
    message.value = '';
    sessionId.value = '';
};

const sendMessage = async () => {
    if (message.value.trim()) {
        const now = new Date();
        const time = `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;
        messages.value.push({ text: message.value, sender: 'user', time });
        const sentMessage = message.value;
        message.value = '';

        isTyping.value = true;

        try {
            const response = await fetch('https://ai.medsyn.solutions/api/chatbot', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    message: sentMessage,
                    locale: locale.value,
                    session_id: sessionId.value
                }),
                mode: 'cors',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            isTyping.value = false;
            messages.value.push({ text: data.response, sender: 'bot', time });
        } catch (error) {
            console.error('Error:', error);
            isTyping.value = false;
            messages.value.push({ text: "Sorry, there was an error processing your request.", sender: 'bot', time });
        }
    }
};

const handleBeforeUnload = async () => {
    if (isChatActive.value) {
        await clearChatHistory();
    }
};

onMounted(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
});

onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
});
</script>

<template>
    <VCard class="chat-card">
        <div class="chat-layout">
            <ChatMenu :is-menu-open="isMenuOpen" :is-chat-active="isChatActive" :vuetify-displays="vuetifyDisplays"
                @activate-chat="activateChat" @deactivate-chat="deactivateChat" @toggle-menu="toggleMenu" />

            <div class="chat-content">
                <div class="chat-header">
                    <div class="d-flex justify-space-between align-center w-100 px-2">
                        <VBtn icon="tabler-menu-2" variant="text" @click="toggleMenu"
                            v-if="vuetifyDisplays.smAndUp.value || (vuetifyDisplays.smAndDown.value && !isMenuOpen)">
                        </VBtn>
                        <div v-else style="width: 48px"></div>
                        <div style="width: 48px"></div>
                    </div>
                </div>

                <VDivider></VDivider>

                <div class="chat-messages-container">
                    <ChatMessages :is-chat-active="isChatActive" :messages="messages" :is-typing="isTyping"
                        ref="messageArea" />
                </div>

                <ChatInput v-if="isChatActive" v-model="message" @send-message="sendMessage" />
            </div>
        </div>
    </VCard>
</template>

<style scoped>
.chat-card {
    height: 80vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.chat-layout {
    display: flex;
    height: 100%;
    overflow: hidden;
}

.chat-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;
    overflow: hidden;
}

.chat-header {
    flex-shrink: 0;
    height: 56px;
    display: flex;
    align-items: center;
}

.chat-messages-container {
    flex: 1;
    overflow-y: auto;
    min-height: 0;
}

.chat-content :deep(.v-divider) {
    margin-top: 0;
    margin-bottom: 0;
}

@media (max-width: 600px) {
    .chat-header {
        height: 56px !important;
    }
}
</style>