<script setup>
import { useOwner } from "@/composables/Central/Admin/useOwner";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import { useRouter } from 'vue-router';
import { ref, computed, watchEffect, onMounted } from 'vue';

definePage({
    meta: {
        isRoute: true,
        adminOnly: true,
        userTypes: ['admin'],
    },
});

const vuetifyDisplays = useDisplay();
const { t } = useI18n();
const router = useRouter();
const owners = ref([]);
const totalOwners = ref(0);
const searchQuery = ref("");
const itemsPerPage = ref(10);
const page = ref(1);
const sortBy = ref([]);
const isLoading = ref(true);
const expandedItems = ref([]);

const updateOptions = (options) => {
    page.value = options.page;
    itemsPerPage.value = options.itemsPerPage;
    sortBy.value = options.sortBy;
    refreshOwners();
};

const { fetchOwners, fetchingOwners, destroyOwner, suspend, reinstate } = useOwner();

const refreshOwners = async () => {
    isLoading.value = true;
    try {
        const response = await fetchOwners({
            query: {
                q: searchQuery.value,
                itemsPerPage: itemsPerPage.value,
                page: page.value,
                sortBy: sortBy.value.length > 0 ? sortBy.value[0].key : undefined,
                orderBy: sortBy.value.length > 0 ? sortBy.value[0].order : undefined,
            },
        });
        owners.value = response.data.owners;
        totalOwners.value = response.data.totalOwners;
    } catch (error) {
        console.error(error);
    } finally {
        isLoading.value = false;
    }
};

watchEffect(() => {
    if (searchQuery.value !== '') {
        refreshOwners();
    }
});

onMounted(refreshOwners);

const headers = computed(() => {
    let baseHeaders = [
        { title: '', key: 'data-table-expand', sortable: false, width: '48px' },
    ];

    const mdLgHeaders = [
        { title: t("First Name"), value: "first_name", sortable: true },
        { title: t("Last Name"), value: "last_name", sortable: true },
        { title: t("Email"), value: "email", sortable: true },
        { title: t("Phone Number"), value: "phone_number", sortable: false },
        { title: t("Status"), value: "status", sortable: false, width: '100px' },
    ];

    const smAndUpHeaders = [
        { title: "ID", value: "id", sortable: true, width: '80px' },
        { title: "Version", value: "version", sortable: true, width: '100px' },
    ];

    if (vuetifyDisplays.smAndUp.value) {
        baseHeaders = [...baseHeaders, ...smAndUpHeaders];
    }

    if (vuetifyDisplays.mdAndUp.value) {
        baseHeaders.push(...mdLgHeaders);
    }

    if (vuetifyDisplays.smAndDown.value) {
        baseHeaders.push({ title: t("Full Name"), value: "full_name", sortable: false });
    }

    const actionsHeader = { title: t("Actions"), value: "actions", sortable: false, align: "end", width: '120px' };

    baseHeaders.push(actionsHeader);

    return baseHeaders;
});

const flattenedItems = computed(() => {
    const flattened = [];
    owners.value.forEach(owner => {
        flattened.push({ ...owner, isChild: false });
        if (expandedItems.value.includes(owner.id)) {
            owner.associatedUsers.forEach(user => {
                flattened.push({ ...user, isChild: true, parentId: owner.id });
            });
        }
    });
    return flattened;
});

const deleteItem = async (id) => {
    await destroyOwner(id).then(() => {
        refreshOwners();
        hideDeleteDialog();
    });
};

const editItem = (id) => {
    router.push({ name: "admin-owners-edit-id", params: { id: id } });
};

const isDeleteDialogVisible = ref(false);
const selectedId = ref(null);
const deleteMessage = computed(() => {
    const item = findItemById(selectedId.value);
    return item ? t('deleteOwnerMessage', { name: item.full_name }) : '';
});

const showDeleteDialog = (id) => {
    selectedId.value = id;
    isDeleteDialogVisible.value = true;
}

const hideDeleteDialog = () => {
    isDeleteDialogVisible.value = false;
};

const handleStatusChange = async (newStatus, id) => {
    isLoading.value = true;
    try {
        if (newStatus) {
            await reinstate(id);
        } else {
            await suspend(id);
        }
        await refreshOwners();
    } catch (error) {
        console.error(error);
    } finally {
        isLoading.value = false;
    }
};

const findItemById = (id) => {
    for (const owner of owners.value) {
        if (owner.id === id) return owner;
        const user = owner.associatedUsers.find(u => u.id === id);
        if (user) return user;
    }
    return null;
};

const toggleExpand = (item) => {
    const index = expandedItems.value.indexOf(item.id);
    if (index === -1) {
        expandedItems.value.push(item.id);
    } else {
        expandedItems.value.splice(index, 1);
    }
};

const hasAssociatedUsers = computed(() => {
    return (item) => item.associatedUsers && item.associatedUsers.length > 0;
});
</script>

<template>
    <VCard :title="$t('Owner List')">
        <VCardText class="d-flex flex-wrap py-4 gap-4">
            <div class="me-3 d-none d-sm-flex gap-3">
                <AppSelect :model-value="itemsPerPage" :items="[
                    { value: 10, title: '10' },
                    { value: 25, title: '25' },
                    { value: 50, title: '50' },
                    { value: 100, title: '100' },
                    { value: -1, title: 'All' },
                ]" style="inline-size: 6.25rem" @update:model-value="itemsPerPage = parseInt($event, 10)" />
            </div>
            <VSpacer />
            <div class="d-flex align-center flex-wrap gap-4">
                <div style="inline-size: 10rem">
                    <AppTextField v-model="searchQuery" :placeholder="$t('Search')" density="compact" />
                </div>
                <VBtn class="d-none d-md-flex" variant="tonal" color="secondary" prepend-icon="tabler-screen-share"
                    @click="refreshOwners()">
                    {{ $t('Refresh') }}
                </VBtn>
            </div>
        </VCardText>
        <VDivider />
        <VDataTable :headers="headers" :items="flattenedItems" :items-per-page="itemsPerPage" :loading="isLoading"
            :search="searchQuery" class="elevation-1 owner-table" item-value="id" loading-text="Loading"
            @update:options="updateOptions">
            <template #item="{ item }">
                <tr
                    :class="{ 'owner-row': !item.isChild, 'child-row': item.isChild, 'expanded': expandedItems.includes(item.parentId || item.id) }">
                    <td v-for="header in headers" :key="header.value" :style="{ width: header.width }">
                        <template v-if="header.key === 'data-table-expand'">
                            <VBtn v-if="!item.isChild && hasAssociatedUsers(item)" icon variant="text" size="small"
                                @click="toggleExpand(item)">
                                <VIcon
                                    :icon="expandedItems.includes(item.id) ? 'tabler-chevron-up' : 'tabler-chevron-down'" />
                            </VBtn>
                            <div v-else-if="!item.isChild" class="expand-placeholder"></div>
                            <div v-else class="expand-placeholder"></div>
                        </template>
                        <template v-else-if="header.value === 'status'">
                            <VSwitch :model-value="!item.is_suspended"
                                @update:model-value="handleStatusChange($event, item.id)" :loading="isLoading"
                                density="compact" />
                        </template>
                        <template v-else-if="header.value === 'actions'">
                            <div class="d-flex justify-end">
                                <IconBtn @click="editItem(item.id)">
                                    <VIcon icon="tabler-edit" />
                                </IconBtn>
                                <IconBtn @click="showDeleteDialog(item.id)">
                                    <VIcon icon="tabler-trash" />
                                </IconBtn>
                            </div>
                        </template>
                        <template v-else-if="header.value === 'version'">
                            {{ item.isChild ? '-' : (item.version || '-') }}
                        </template>
                        <template v-else>
                            {{ item[header.value] }}
                        </template>
                    </td>
                </tr>
            </template>
        </VDataTable>
    </VCard>
    <DeleteConfirmation :is-dialog-visible="isDeleteDialogVisible" :id="selectedId" :message="deleteMessage"
        @delete="deleteItem($event)" @cancel="hideDeleteDialog" />
</template>

<style lang="scss">
.owner-table {
    .v-data-table__wrapper>table {
        border-spacing: 0;
    }

    td {
        height: 48px;
        padding: 0 8px;
        vertical-align: middle;
    }

    .owner-row {
        background-color: #ffffff;

        &:hover {
            background-color: #f5f5f5;
        }
    }

    .child-row {
        background-color: rgba(var(--v-theme-background), 1);

        &:hover {
            background-color: #f5f5f5;
        }
    }

    .expand-placeholder {
        width: 40px;
        visibility: hidden;
    }
}
</style>