<script setup>
import { useGenerateImageVariant } from '@core/composable/useGenerateImageVariant'
import miscComingSoon from '@images/pages/misc-coming-soon.png'
import miscMaskDark from '@images/pages/misc-mask-dark.png'
import miscMaskLight from '@images/pages/misc-mask-light.png'

definePage({
  meta: {
    isRoute: true,
    layout: 'blank',
  }
})

const email = ref('')
const authThemeMask = useGenerateImageVariant(miscMaskLight, miscMaskDark)
</script>

<template>
  <div class="misc-wrapper">
    <div>
      <div class="text-center mb-4">
        <h1 class="text-h1 font-weight-medium">
          503
        </h1>
        <h4 class="text-h4 font-weight-medium mb-3">
          {{ $t('Coming Soon') }}
        </h4>
        <p class="mb-6">
          {{ $t('This feature is under development and will be available shortly') }}.
        </p>
        <p>
          {{ $t('Thank you for your patience') }}.
        </p>
        <VBtn class="mt-2 mb-10" to="/">
          {{ $t('Dashboard') }}
        </VBtn>
      </div>
    </div>

    <VImg :src="authThemeMask" class="misc-footer-img d-none d-md-block" />
  </div>
</template>

<style lang="scss">
@use "@core-scss/template/pages/misc.scss";

.misc-email-input {
  margin-inline: auto;
  max-inline-size: 21.875rem;
  min-inline-size: 12.5rem;
}
</style>
