<script setup>
import { computed, defineAsyncComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/useUserStore';

definePage({
    meta: {
        isRoute: true,
        navActiveLink: "bills",
        userTypes: ['doctor', 'user', 'patient'],
        permission: 'read_appointment',
        feature: 'scheduling_planning',
    },
});

const router = useRouter();
const userStore = useUserStore();

const DoctorComponent = defineAsyncComponent(() =>
    import(`@/pages/bills/components/Lists/Doctor.vue`)
);
const PatientComponent = defineAsyncComponent(() =>
    import('@/pages/bills/components/Lists/Patient.vue')
);

const dynamicComponent = computed(() => {
    switch (userStore.type) {
        case 'doctor':
        case 'user':
            return DoctorComponent;
        case 'patient':
            return PatientComponent;
        default:
            router.push({ name: 'error', params: { error: 'Invalid user type' } });
            return null;
    }
});
</script>

<template>
    <Suspense>
        <template #default>
            <component :is="dynamicComponent" v-if="dynamicComponent" />
            <div v-else>Failed to load component</div>
        </template>

        <template #fallback>
            <div class="loading-container">
                <VProgressCircular indeterminate color="primary" :size="50"></VProgressCircular>
            </div>
        </template>
    </Suspense>
</template>