<script setup>
import { useNotification } from "@/composables/Central/useNotification";
import { useI18n } from 'vue-i18n';
import {useStatic} from '@/composables/useStatic';
import { ref, watchEffect,computed } from "vue";

definePage({
    meta: {
        isRoute: true,
    },
});

const { getUrl } = useStatic();
const { locale } = useI18n({ useScope: 'global' });
const { t } = useI18n();
const notifications = ref([]);
const totalNotifications = ref(0);
const itemsPerPage = ref(5);
const page = ref(1);
const sortBy = ref();
const orderBy = ref();

const updateOptions = (options) => {
    notifications.value = [];
    page.value = options.page;
    sortBy.value = options.sortBy[0]?.key;
    orderBy.value = options.sortBy[0]?.order;
};

const { fetchNotifications, fetchingNotifications, markSeen, totalPages } = useNotification();

const refreshNotifications = () => {
    fetchNotifications({
        query: {
            page: page.value,
            sortBy: sortBy.value,
            orderBy: orderBy.value,
        },
    })
        .then((response) => {
            fetchingNotifications.value = false;
            notifications.value = response.data.notifications;
            totalNotifications.value = response.data.total;
            totalPages.value = response.data.total_pages;
        })
        .catch((error) => {
            console.error(error);
        });
};

watchEffect(refreshNotifications);

const headers = computed(() => {
    let baseHeaders = [
        { title: '', key: 'data-table-expand' },
        { title: t("Title"), value: "title", sortable: false, order: 1 },
        { title: t("Tag"), value: "tag", sortable: false, order: 2 },
        { title: t("Date"), value: "date", sortable: false, order: 3 },
        { title: '', value: "status" },
    ];

    baseHeaders.sort((a, b) => a.order - b.order);

    return baseHeaders;
});

const resolveTagVariant = tag => {
    if (tag === 'General')
        return 'primary';
    else if (tag === 'Personal')
        return 'success';
}

const getContent = (item) => {
    if (locale.value === 'en') return item.content_EN;
    if (locale.value === 'fr') return item.content_FR;
    if (locale.value === 'ar') return item.content_AR;
    return '';
};

async function handleSeen(event, row) {
    if (!row.item.is_seen){
        row.item.is_seen = true;
        await markSeen(row.item.id);
    }
};

</script>

<template>
    <section>
        <VRow>
            <VCol cols="12">
                <VCard :title="$t('Notifications')">

                    <VDivider />

                    <VDataTableServer hide-footer hide-header loading-text="Loading" :loading="fetchingNotifications"
                        :sticky="true" v-model:items-per-page="itemsPerPage" v-model:page="page" :items="notifications"
                        :headers="headers" class="text-no-wrap" :items-length="totalNotifications"
                        @update:options="updateOptions" expand-on-click @click:row="handleSeen">

                        <template #item.title="{ item }">
                            <div class="d-flex align-center gap-x-3">
                                <VAvatar size="40" :color="item.color && item.icon ? item.color : undefined"
                                    :image="getUrl(item.image) || undefined" :icon="item.icon || undefined"
                                    :variant="item.image ? undefined : 'tonal' ">
                                </VAvatar>
                                <div class="d-flex flex-column">
                                    <span class="text-md">{{ item.title }}</span>
                                </div>
                            </div>
                        </template>

                        <template #item.tag="{ item }">
                            <VChip :color="resolveTagVariant(item.tag)" class="font-weight-medium" size="small">
                                {{ item.tag }}
                            </VChip>
                        </template>

                        <template #item.date="{ item }">
                            <div class="d-flex align-center gap-4">
                                <span>{{ item.date }}</span>
                            </div>
                        </template>

                        <template #item.status="{ item }">
                            <VBadge dot :color="!item.is_seen ? 'primary' : '#a8aaae'"
                                :class="`${item.is_seen ? 'visible-in-hover' : ''} d-flex align-center justify-center`" />
                        </template>

                        <template #expanded-row="slotProps">
                            <tr class="v-data-table__tr">
                                <td :colspan="headers.length">
                                    <p class="my-1">{{ getContent(slotProps.item) }}</p>
                                </td>
                            </tr>
                        </template>


                        <template #bottom>
                        </template>
                    </VDataTableServer>
                </VCard>
            </VCol>
            <VCol class="pt-10 d-flex align-center justify-center">
                <VPagination class="w-50" v-model="page" :length="totalPages" />
            </VCol>
        </VRow>
    </section>
</template>

<style scoped>
:deep(.v-data-table__thead) {
    display: none;
}
</style>
<style lang="scss">
.text-capitalize {
    text-transform: capitalize;
}

.notification-list-name:not(:hover) {
    color: rgba(var(--v-theme-on-background), var(--v-medium-emphasis-opacity));
}
</style>