<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import authV1BottomShape from '@images/svg/auth-v1-bottom-shape.svg?raw';
import authV1TopShape from '@images/svg/auth-v1-top-shape.svg?raw';
import { VNodeRenderer } from '@layouts/components/VNodeRenderer';
import { useRegister } from "@/composables/Auth/useRegister";
import { themeConfig } from '@themeConfig'
import Activation from "@/pages/register/components/doctor/Activation.vue";
import Profile from "@/pages/register/components/doctor/Profile.vue";
import Details from "@/pages/register/components/doctor/Details.vue";
import { useI18n } from 'vue-i18n';
import MobileProgressIndicator from "./components/MobileProgressIndicator.vue";

definePage({
    meta: {
        isRoute: true,
        layout: "blank",
        unauthenticatedOnly: true,
    },
});

const { t } = useI18n();

const steps = [
    {
        title: t('Activation'),
        icon: 'tabler-circle-key',
    },
    {
        title: t('Profile'),
        icon: 'tabler-user-circle',
    },
    {
        title: t('Details'),
        icon: 'tabler-medical-cross-circle',
    },
]

const { registerForm, activate, validateRegister, registerLoading, registerErrors, register, snackBar } = useRegister();
const router = useRouter();
const currentStep = ref(0);
const isCurrentStepValid = ref(true);

const onBack = () => {
    if (currentStep.value > 0) {
        currentStep.value--;
    }
}

const onSubmiteActivate = async () => {
    registerForm.type = 'doctor';
    await activate().then((success) => {
        if (success) {
            currentStep.value++;
            isCurrentStepValid.value = true;
        } else {
            isCurrentStepValid.value = false;
        }
    });
}

const onSubmitGeneral = async () => {
    await validateRegister().then((success) => {
        if (success) {
            currentStep.value++;
            isCurrentStepValid.value = true;
        } else {
            isCurrentStepValid.value = false;
        }
    });
}

const onSubmitDoctor = async () => {
    await register().then((success) => {
        if (success) {
            setTimeout(() => {
                router.push({ name: "login" });
            }, 1000);
        }
    });
}
</script>

<template>
    <VAppBar>
        <template v-slot:prepend>
            <router-link :to="'/'" class="d-flex align-center">
                <VNodeRenderer :nodes="themeConfig.app.logo" />
                <VToolbarTitle class="text-h4 ml-2">
                    <span class="text-capitalize font-weight-bold">{{ themeConfig.app.title }}</span>
                </VToolbarTitle>
            </router-link>
        </template>
    </VAppBar>

    <div class="auth-wrapper d-flex align-center justify-center">
        <VCard width="550" max-width="100%" class="auth-card">
            <VCardTitle class="text-center py-4">
                {{ $t('Register') }}
            </VCardTitle>
            <VDivider />
            <MobileProgressIndicator :steps="steps" :current-step="currentStep" />
            <VCardText>
                <VWindow v-model="currentStep" class="disable-tab-transition">
                    <VWindowItem>
                        <Activation :form="registerForm" :errors="registerErrors" @submit="onSubmiteActivate"
                            :loading=registerLoading @back="onBack" />
                    </VWindowItem>

                    <VWindowItem>
                        <Profile :form="registerForm" :errors="registerErrors" @submit="onSubmitGeneral"
                            :loading="registerLoading" @back="onBack" />
                    </VWindowItem>

                    <VWindowItem>
                        <Details :form="registerForm" :errors="registerErrors" @submit="onSubmitDoctor"
                            :loading="registerLoading" @back="onBack" />
                    </VWindowItem>
                </VWindow>
            </VCardText>
        </VCard>
    </div>
    <VSnackbar v-model="snackBar.visible" :color="snackBar.color">
        <div class="text-center">{{ $t(snackBar.message) }}.</div>
    </VSnackbar>
</template>

<style lang="scss">
@use "@core-scss/template/pages/page-auth.scss";

.auth-wrapper {
    flex-grow: 1;
    padding: 1rem;
}

.auth-card {
    width: 450px;
    margin-top:100px;
}

.disable-tab-transition {
    .v-window__container {
        transition: none !important;
    }
}

.v-window-item {
    height: 100%;
}

.v-window-item:not(.v-window-item--active) {
    display: none;
}

@media (max-width: 599.98px) {
    .auth-card {
        width: 100% !important;
    }

    .v-col {
        padding: 6px 12px !important;
    }
}
</style>