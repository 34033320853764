<script setup>
import { useDoctor } from "@/composables/Tenant/useDoctor";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import { useRouter } from "vue-router";
import { useAccessControl } from '@/composables/useAccessControl';
import { ref, watchEffect,computed } from "vue";

definePage({
    meta: {
        isRoute: true,
        permission: 'read_doctor',
    },
});

const vuetifyDisplays = useDisplay();
const { canAccess } = useAccessControl();
const { t } = useI18n();
const router = useRouter();
const doctors = ref([]);
const totalDoctors = ref(0);
const searchQuery = ref("");
const itemsPerPage = ref(10);
const page = ref(1);
const sortBy = ref('id');
const orderBy = ref('desc');
const alert = ref();
const isLimitReached = ref(false);

const updateOptions = (options) => {
    fetchDoctors.value = true;
    page.value = options.page;
    sortBy.value = options.sortBy[0]?.key;
    orderBy.value = options.sortBy[0]?.order;
};

const { fetchDoctors, fetchingDoctors, softDestroyDoctor } = useDoctor();

const refreshDoctors = () => {
    fetchDoctors({
        query: {
            q: searchQuery.value,
            itemsPerPage: itemsPerPage.value,
            page: page.value,
            sortBy: sortBy.value,
            orderBy: orderBy.value,
            isDeleted: false,
        },
    })
        .then((response) => {
            fetchingDoctors.value = false;
            doctors.value = response.data.doctors;
            totalDoctors.value = response.data.totalDoctors;
            alert.value = response.data.alert;
            isLimitReached.value = response.data.isLimitReached;
        })
        .catch((error) => {
            console.error(error);
        });
};

watchEffect(refreshDoctors);

const headers = computed(() => {
    let baseHeaders = [{ title: t("Full Name"), value: "full_name", key: "full_name", order: 1 }];

    const mdLgHeaders = [
        { title: t("Email"), value: "email", order: 3 },
        { title: t("Designation"), value: "designation", order: 4 },
        { title: t("Department"), value: "department", order: 5 },
    ];

    const smAndUpHeaders = [
        { title: t("Phone Number"), value: "phone_number", order: 2 },
    ];

    if (vuetifyDisplays.smAndUp.value) {
        baseHeaders = [...baseHeaders, ...smAndUpHeaders];
    }

    if (vuetifyDisplays.mdAndUp.value) {
        baseHeaders.push(...mdLgHeaders);
    }

    const actionsHeader = { title: t("Actions"), value: "actions", sortable: false, align: "end", order: 6 };

    if (canAccess.byPermission('write_doctor') || canAccess.byPermission('delete_doctor')) {
        baseHeaders.push(actionsHeader);
    }

    baseHeaders.sort((a, b) => a.order - b.order);

    return baseHeaders;
});

const addNewDoctor = () => {
    router.push({ name: "doctors-add" });
};

const deleteDoctor = async (id) => {
    await softDestroyDoctor(id).then(() => {
        refreshDoctors();
        hideDeleteDialog();
    });
};

const editDoctor = (id) => {
    router.push({ name: "doctors-edit-id", params: { id: id } });
};

const isDeleteDialogVisible = ref(false);
const selectedId = ref(null);
const deleteMessage = computed(() => {
    const doctor = doctors.value.find(p => p.id === selectedId.value);
    return doctor ? t('deleteDoctorMessage', { name: doctor.first_name + ' ' + doctor.last_name }) : '';
});

const showDeleteDialog = (id) => {
    selectedId.value = id;
    isDeleteDialogVisible.value = true;
}

const hideDeleteDialog = () => {
    isDeleteDialogVisible.value = false;
}

</script>

<template>
    <section>
        <VRow>
            <VCol cols="12">
                <VAlert v-if="alert" :color="alert.type" variant="tonal" class="mb-2">
                    {{ alert.message }}
                </VAlert>
                <VCard :title="$t('Doctor List')">
                    <VDivider />

                    <VCardText class="d-flex flex-wrap py-4 gap-4">

                        <div class="me-3 d-none d-sm-flex gap-3">
                            <AppSelect :model-value="itemsPerPage" :items="[{ value: 10, title: '10' },
                            { value: 25, title: '25' },
                            { value: 50, title: '50' },
                            { value: 100, title: '100' },
                            { value: -1, title: 'All' },]" style="inline-size: 6.25rem"
                                @update:model-value="itemsPerPage = parseInt($event, 10)" />
                        </div>

                        <VSpacer />

                        <div class="d-flex align-center flex-wrap gap-4">
                            <div style="inline-size: 10rem">
                                <AppTextField v-model="searchQuery" :placeholder="$t('Search')" density="compact" />
                            </div>

                            <VBtn class="d-none d-md-flex" variant="tonal" color="secondary"
                                prepend-icon="tabler-reload" @click="refreshDoctors">
                                {{ $t('Refresh') }}
                            </VBtn>

                            <VBtn :disabled="isLimitReached" v-if="canAccess.byPermission('write_doctor')"
                                @click="addNewDoctor">
                                <VIcon :icon="!isLimitReached ? 'tabler-plus' : 'tabler-lock'" size="22" class="mr-1" />
                                <span class="d-none d-md-flex">{{ $t('Add Doctor') }}</span>
                            </VBtn>

                        </div>
                    </VCardText>

                    <VDivider />

                    <VDataTableServer v-model:items-per-page="itemsPerPage" loading-text="Loading"
                        :loading="fetchingDoctors" v-model:page="page" :sticky="true" :items="doctors"
                        :items-length="totalDoctors" :headers="headers" class="text-no-wrap"
                        @update:options="updateOptions">

                        <template #item.full_name="{ item }">
                            <div class="d-flex align-center">
                                <span>{{ item.first_name + " " + item.last_name }}</span>
                            </div>
                        </template>

                        <template #item.email="{ item }">
                            <div class="d-flex align-center">
                                <span>{{ item.email ?? '-' }}</span>
                            </div>
                        </template>

                        <template #item.phone_number="{ item }">
                            <div class="d-flex align-center">
                                <span>{{ item.phone_number ?? '-' }}</span>
                            </div>
                        </template>

                        <template #item.designation="{ item }">
                            <div class="d-flex align-center">
                                <span>{{ item.designation ?? '-' }}</span>
                            </div>
                        </template>

                        <template #item.department="{ item }">
                            <div class="d-flex align-center">
                                <span>{{ item.department ?? '-' }}</span>
                            </div>
                        </template>

                        <template #item.actions="{ item }">
                            <div class="align-end">
                                <IconBtn v-if="canAccess.byPermission('write_doctor')" @click="editDoctor(item.id)">
                                    <VIcon icon="tabler-edit" />
                                </IconBtn>

                                <IconBtn v-if="canAccess.byPermission('delete_doctor')"
                                    @click="showDeleteDialog(item.id)">
                                    <VIcon icon="tabler-trash" />
                                </IconBtn>
                            </div>
                        </template>
                    </VDataTableServer>
                </VCard>
            </VCol>
        </VRow>
    </section>
    <DeleteConfirmation :is-dialog-visible="isDeleteDialogVisible" :id="selectedId" :message="deleteMessage"
        :soft="true" @delete="deleteDoctor($event)" @cancel="hideDeleteDialog" />
</template>

<style lang="scss">
.text-capitalize {
    text-transform: capitalize;
}

.doctor-list-name:not(:hover) {
    color: rgba(var(--v-theme-on-background), var(--v-medium-emphasis-opacity));
}
</style>