<script setup>
import Form from "@/pages/admin/tutorials/components/Form.vue";

definePage({
    meta: {
        isRoute: true,
        adminOnly: true,
        userTypes: ['admin'],
        navActiveLink: "admin-tutorials",
    },
});
</script>

<template>
    <Form />
</template>