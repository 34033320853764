<script setup lang="ts">
import { computed } from "vue";
import { NotivueItem } from "notivue";
import { formatDistanceToNow, parseISO } from "date-fns";
import { enUS, fr } from "date-fns/locale";

export interface GlobalNotificationProps {
  isGlobalNotifiation: boolean,
  title: string;
  content: string;
  image: string;
  color: string;
  icon: string;
  created_at: string;
}

const { getUrl } = useStatic();
const { item } = defineProps<{
  item: NotivueItem<GlobalNotificationProps>;
}>();

const { locale } = useI18n({ useScope: "global" });

function getLocaleObject(localeCode: string) {
  switch (localeCode) {
    case "fr":
      return fr;
    default:
      return enUS;
  }
}

const timeAgo = computed(() => {
  if (item.props.created_at) {
    const date = parseISO(item.props.created_at);
    return formatDistanceToNow(date, {
      addSuffix: true,
      locale: getLocaleObject(locale.value),
    });
  }
  return "Invalid Date";
});
</script>

<template>
  <div class="headless-notification-wrapper cursor-pointer pt-5">
    <div class="d-flex">
      <VAvatar
        size="40"
        :color="item.props.color && item.props.icon ? item.props.color : undefined"
        :image="getUrl(item.props.image) || undefined"
        :icon="item.props.icon || undefined"
        :variant="item.props.image ? undefined : 'tonal'"
        @contextmenu.prevent
      >
      </VAvatar>
      <div
        class="pl-4 content-wrapper"
        :role="item.ariaRole"
        :aria-live="item.ariaLive"
        aria-atomic="true"
      >
        <div class="title-time-wrapper mb-2">
          <span class="font-weight-medium title">
            {{ item.props.title }}
          </span>
          <span class="time font-weight-medium">
            {{ timeAgo }}
          </span>
        </div>
        <p class="content font-weight-light mb-0 pr-2">
          {{ item.props.content }}
        </p>
      </div>
    </div>

    <div class="d-flex justify-end mt-2">
      <VBtn variant="text" @click.stop="item.clear()" class="ml-12">
        {{ $t("Dismiss") }}
      </VBtn>
    </div>
  </div>
</template>

<style scoped>
.headless-notification-wrapper {
  width: 400px;
  max-width: 100%;
  background-color: rgba(var(--v-theme-surface), 1);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content-wrapper {
  flex-grow: 1;
  min-width: 0;
  padding-right: 15px;
}

.content {
  overflow: hidden;
  color: rgba(var(--v-theme-on-surface), 1);
}

.title-time-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 100px);
  color: rgba(var(--v-theme-on-surface), 1);
}

.time {
  color: gray;
  white-space: nowrap;
  margin-left: 8px;
}

.time::first-letter {
  text-transform: uppercase;
}

.v-avatar {
  pointer-events: none;
}
</style>
